export const BASE_DASHBOARD = '/dashboard';
export const DASHBOARD_HOME = '/dashboard/home';
export const MERCHANT_INFO = '/dashboard/merchant/';
export const POST_ONBOARDING_BANKING_ENTITY = '/dashboard/post-onboarding/banking/entity/';
export const POST_ONBOARDING_TREASURY_ENTITY = '/dashboard/post-onboarding/treasury/entity/';
export const ZAMP_LOGO_PATH = '/images/zamp-logo.png';
export const ROMA_LOGO_PATH = '/images/roma-logo.png';

export const MERCHANTS_ROUTE = '/dashboard/merchants';
export const POST_ONBOARDING_ROUTE = '/dashboard/post-onboarding';
export const ACCOUNTS_ROUTE = '/dashboard/account-management';
export const TECH_SUPPORT_REQUEST = '/dashboard/tech-support-request';

export const KYB_SUBMISSION_ROUTE = '/dashboard/kyb-submission';
export const KYB_SUBMISSION_BANKING_ROUTE = '/dashboard/kyb-submission/banking/entity/';
export const KYB_SUBMISSION_TREASURY_ROUTE = '/dashboard/kyb-submission/treasury/entity/';

export const CORRIDORS_ROUTE = '/dashboard/corridors';
export const TRANSACTIONS_ROUTE = '/dashboard/transactions';
export const PAYMENTS_ROUTE = '/dashboard/payments';
export const APPROVE_TRANSACTIONS_ROUTE = '/dashboard/approve-transactions';

export const LOGIN = '/login';
export const REGISTER = '/register';

export const USERS_ROUTE = '/dashboard/users';
export const POLICIES_ROUTE = '/dashboard/policies';
export const CREATE_POLICY_ROUTE = '/dashboard/policies/create';

export const SIDEBARE_HIDDEN_ROUTES = ['/users', '/policies', '/policies/create'];
