import React, { useContext, useState } from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import { verifyCorporateOnAtomic } from 'api/internaltools';
import PatchCorporate from 'modules/onboarding/components/atomic/PatchCorporate';
import { ATOMIC_VERIFY_CORPORATE_DESCRIPTION } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';

const VerifyCorporate = () => {
  const { kybId, kyb, partnerCode, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);

  const [isVerifyCorporateLoading, setIsVerifyCorporateLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleVerifyCorporate = async () => {
    setIsVerifyCorporateLoading(true);
    const payload = {
      kybId,
      partnerCode,
    };

    try {
      await verifyCorporateOnAtomic(payload);
      showToast('Successfully verified corporate on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error verifying corporate on Atomic.', error);
      setError(error);
    } finally {
      setIsVerifyCorporateLoading(false);
    }
  };

  return (
    <>
      {!error && (
        <>
          <Heading as='h2' size='md'>
            Verify Corporate
          </Heading>
          <Text color='gray' mt={2}>
            {ATOMIC_VERIFY_CORPORATE_DESCRIPTION}
          </Text>
          <Button
            colorScheme='blue'
            mt={4}
            onClick={handleVerifyCorporate}
            isLoading={isVerifyCorporateLoading}
            isDisabled={kyb == null}
          >
            Verify Corporate
          </Button>
        </>
      )}

      {error && <PatchCorporate />}
    </>
  );
};

export default VerifyCorporate;
