import React from 'react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react';
import { UPLOAD_FILE_ALLOWED_FORMATS } from 'constants/constants';
import { BUSINESS_DOCS_DETAILS } from 'modules/onboarding/constants/constants';

function BusinessDocsUpload({ businessDocuments, setBusinessDocuments }) {
  const handleAddDocument = () => {
    setBusinessDocuments((prevDocs) => ({
      ...prevDocs,
      document: [
        ...prevDocs.document,
        {
          fileName: '',
          fileType: '',
          document: null,
        },
      ],
    }));
  };

  const handleDeleteDocument = (index) => {
    setBusinessDocuments((prevDocs) => {
      const updatedDocumentList = [...prevDocs.document];

      updatedDocumentList.splice(index, 1);

      return {
        ...prevDocs,
        document: updatedDocumentList,
      };
    });
  };

  const handleDocumentChange = (index, value) => {
    setBusinessDocuments((prevDocs) => {
      const updatedDocumentList = [...prevDocs.document];

      updatedDocumentList[index]['document'] = value;
      updatedDocumentList[index]['fileName'] = value.name;
      updatedDocumentList[index]['fileType'] = value.type;

      return {
        ...prevDocs,
        document: updatedDocumentList,
      };
    });
  };

  const handleDetailsChange = (field, value) => {
    setBusinessDocuments((prevDocs) => ({
      ...prevDocs,
      [field]: value,
    }));
  };

  return (
    <Box>
      <Heading as='h2' size='lg'>
        Business Documents
      </Heading>
      <Text mb={4} color='gray'>
        Upload the document details for the business.
      </Text>

      <Box width='300px'>
        {BUSINESS_DOCS_DETAILS.map((detail, index) => (
          <Box key={index}>
            <label>{detail}</label>
            <Input
              value={businessDocuments[detail]}
              onChange={(e) => handleDetailsChange(detail, e.target.value)}
              mb={2}
            />
          </Box>
        ))}
      </Box>

      <Accordion allowToggle defaultIndex={[0]} mt={4}>
        {businessDocuments.document.map((_, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                Business Doc {index + 1}
              </Box>
              {index !== 0 && <DeleteIcon color='red' onClick={() => handleDeleteDocument(index)} mr={2} />}
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <input
                type='file'
                accept={UPLOAD_FILE_ALLOWED_FORMATS}
                onChange={(e) => handleDocumentChange(index, e.target.files[0])}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <Button leftIcon={<AddIcon />} onClick={handleAddDocument} mt={4}>
        Add another document
      </Button>
    </Box>
  );
}

export default BusinessDocsUpload;
