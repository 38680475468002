import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import ShowKeyAndValue from 'components/ShowKeyAndValue/ShowKeyAndValue';

export default function EntityOnOffRampConfig({ entity, merchantId }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getEntityOnOffRampConfigs = async () => {
    setIsLoading(true);
    try {
      const response = await internalToolsApi.getEntityOnOffRampConfigurations(merchantId, entity?.entity_id);

      setData(response?.data?.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getEntityOnOffRampConfigs();
  }, []);

  return (
    <LoadingErrorWrapper isLoading={isLoading} error={error} errorTitle='Error fetching On Off Ramp config.'>
      {data?.map((config) => (
        <Box key={config?.id}>
          <Heading as='h1' size='lg' mt={4} mb={3}>
            {config?.type}
          </Heading>
          <Flex direction='column' gap={3}>
            <ShowKeyAndValue keyName='Currencies' value={config?.currencies} />
            <ShowKeyAndValue keyName='Monthly Treade Volumes' value={config?.monthly_trade_volumes} />
          </Flex>
        </Box>
      ))}
    </LoadingErrorWrapper>
  );
}
