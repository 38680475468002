import React from 'react';
import { Box, Divider, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { CURRENCY_LOGO_FALLBACK_SRC } from 'modules/merchantinfo/constants/constants';

export default function SingleAccountDetails({ account_details }) {
  const { currency_logo_url, currency_display_name, vault_name, account_balance, entity_name } = account_details;

  return (
    <>
      <Flex w='100%' justify='space-between'>
        <Box>
          <Text fontSize='lg' color='gray'>
            {currency_display_name} | {vault_name}
          </Text>
          <Heading as='h1' size='lg'>
            {account_balance}
          </Heading>
          <Text color='gray' fontWeight='medium' mt={2}>
            entity: {entity_name}
          </Text>
        </Box>

        <Image
          boxSize='40px'
          borderRadius='full'
          src={currency_logo_url}
          fallbackSrc={CURRENCY_LOGO_FALLBACK_SRC}
          alt='Currency Logo'
        />
      </Flex>
      <Divider mt={5} mb={4} />
    </>
  );
}
