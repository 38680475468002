import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  PinInput,
  PinInputField,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { signIn, validateSignIn } from 'api/authentication';
import { useAuthentication } from 'AuthContext';
import { BASE_DASHBOARD, REGISTER, ROMA_LOGO_PATH } from 'constants/routes';
import { showError } from 'utils/notifications';

function SignIn() {
  const { updateUserAndFetchPermissions } = useAuthentication();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const history = useHistory();

  const handleSignIn = async () => {
    if (!email) {
      showError(toast, 'Email is required', 'Please enter your email.');

      return;
    }

    setIsLoading(true);
    try {
      const response = await signIn(email);

      setAuthToken(response?.data?.data?.token);
    } catch (error) {
      showError(toast, 'Error during token generation', error);
    }
    setIsLoading(false);
  };

  const handleValidateSignIn = async () => {
    if (!otp) {
      showError(toast, 'OTP is required', 'Please enter the OTP.');

      return;
    }

    setIsLoading(true);
    try {
      await validateSignIn(authToken, otp);

      updateUserAndFetchPermissions();

      // Redirect to dashboard
      return history.push(BASE_DASHBOARD);
    } catch (error) {
      showError(toast, 'Error during sign in validation', error);
    }
    setIsLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (authToken) {
        handleValidateSignIn();
      } else {
        handleSignIn();
      }
    }
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100vh' flexDir='column' gap={8}>
      <Image src={ROMA_LOGO_PATH} alt='Zamp logo' w='150px' />
      <Box p={8} minW='500px' maxW='600px'>
        <Heading as='h2' size='lg' mb={6}>
          Login
        </Heading>
        {authToken ? (
          <Stack spacing={6}>
            <FormControl id='otp' isRequired>
              <FormLabel fontWeight='bold'>Enter OTP</FormLabel>
              <HStack>
                <PinInput otp value={otp} onChange={(value) => setOtp(value)} size='lg'>
                  {[...Array(6)].map((_, index) => (
                    <PinInputField key={index} bg='gray.100' onKeyDown={handleKeyDown} />
                  ))}
                </PinInput>
              </HStack>
            </FormControl>
            <Button colorScheme='teal' onClick={handleValidateSignIn} isLoading={isLoading} width='min'>
              Submit
            </Button>
          </Stack>
        ) : (
          <Stack spacing={6}>
            <FormControl id='email'>
              <FormLabel fontWeight='bold'>Business email</FormLabel>
              <Input
                bg='gray.100'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size='lg'
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <Button colorScheme='teal' onClick={handleSignIn} isLoading={isLoading} width='min'>
              Continue
            </Button>
          </Stack>
        )}
        <Box mt={6}>
          <Link to={REGISTER}>
            Don&apos;t have an account?
            <Button variant='link' ml={2} colorScheme='teal'>
              Sign up
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default SignIn;
