import React, { useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Portal, useDisclosure } from '@chakra-ui/react';
import { INTERNAL_DASHBOARD_TITLE } from 'constants/constants';
import { BASE_DASHBOARD, DASHBOARD_HOME, MERCHANTS_ROUTE, ROMA_LOGO_PATH } from 'constants/routes';
import { lumosDashboardRoutes, opsDashboardRoutes } from 'routes.js';
import { isOpsDashboard } from 'utils/utils';
import PrivateRoute from 'components/hoc/PrivateRoute';
import MainPanel from 'components/Layout/MainPanel';
import PanelContainer from 'components/Layout/PanelContainer';
import PanelContent from 'components/Layout/PanelContent';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar';

const sidebarVariant = 'transparent';
const fixed = false;

export default function Dashboard() {
  const [routes, setRoutes] = useState(opsDashboardRoutes);

  useEffect(() => {
    if (!isOpsDashboard()) {
      setRoutes(lumosDashboardRoutes);
    }
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);

        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);

        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }

    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);

        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }

    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/dashboard') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            requiredPermission={prop.base_permission}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { onOpen } = useDisclosure();

  return (
    <>
      <Sidebar routes={routes} sidebarVariant={sidebarVariant} />
      <MainPanel
        w={{
          base: '100%',
          xl: 'calc(100% - 230px)',
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            logo={ROMA_LOGO_PATH}
            logoText={INTERNAL_DASHBOARD_TITLE}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
          />
        </Portal>

        <PanelContent>
          <PanelContainer>
              <Switch>
                {getRoutes(routes)}
                {isOpsDashboard() ? (
                  <Redirect from={BASE_DASHBOARD} to={DASHBOARD_HOME} />
                ) : (
                  <Redirect from={BASE_DASHBOARD} to={MERCHANTS_ROUTE} />
                )}
              </Switch>
          </PanelContainer>
        </PanelContent>
      </MainPanel>
    </>
  );
}
