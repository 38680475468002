import React, { useEffect, useState } from 'react';
import { GridItem, Heading, Text } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import SingleAccountDetails from 'modules/merchantinfo/components/SingleAccountDetails';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Loader from 'components/Loader/Loader';

export default function MerchantAccountsDetails({ merchantId }) {
  const [accountsDetails, setAccountsDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isPayoutsDisabled = !accountsDetails && !isLoading && !error;
  const backgroundColor = isPayoutsDisabled ? '#DCDCDC' : '';

  useEffect(() => {
    const getMerchantAccountsDetails = async () => {
      try {
        setIsLoading(true);
        const response = await internalToolsApi.getMerchantAccountsDetails(merchantId);

        setAccountsDetails(response?.data?.data);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    getMerchantAccountsDetails();
  }, []);

  return (
    <>
      <GridItem w='100%' style={{ display: 'flex', flexDirection: 'column' }}>
        <Heading as='h1' size='md' mb={3}>
          Accounts
        </Heading>
        <Card
          minH='300px'
          maxH='400px'
          overflow='auto'
          style={{ borderWidth: '2px', flex: 1, backgroundColor: `${backgroundColor}` }}
        >
          <CardBody display='flex' flexDirection='column' justifyContent='space-between' style={{ height: '100%' }}>
            {accountsDetails?.accounts?.map((account, idx) => (
              <SingleAccountDetails key={idx} account_details={account?.account_details} />
            ))}

            {isPayoutsDisabled && <Text color='gray'>Payouts are not enabled for this merchant.</Text>}

            {isLoading && <Loader />}
            {error && <AlertMessage errorTitle='' errorMessage='Error fetching accounts details for this merchant.' />}
          </CardBody>
        </Card>
      </GridItem>
    </>
  );
}
