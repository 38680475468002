import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Select, useToast } from '@chakra-ui/react';
import { createEntityConfigurations } from 'api/internaltools';
import {
  PARTNER_CODE_FIREBLOCKS,
  PARTNER_CODE_FIREBLOCKS_UAE,
  PARTNER_CODE_LAYER2,
  PARTNER_CODE_NIUM_SG,
  PARTNER_CODE_NIUM_USA,
  PARTNER_CODE_ZAND,
  PARTNER_TYPE_CRYPTO,
  PARTNER_TYPE_FIAT,
} from 'constants/constants';
import { PostOnboardingContext } from 'modules/postonboarding/BankingPostOnboarding';
import { showError, showToast } from 'utils/notifications';

export default function SelectFiatCryptoPartners() {
  const {
    merchantId,
    entityId,
    fiatPartner,
    cryptoPartner,
    setFiatPartner,
    setCryptoPartner,
    nextStep,
    fiatPartnerCustomerId,
    fiatPartnerWalletId,
    cryptoPartnerCustomerId,
    setFiatPartnerCustomerId,
    setFiatPartnerWalletId,
    setCryptoPartnerCustomerId,
  } = useContext(PostOnboardingContext);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [showFiatFields, showCryptoFields] = [fiatPartner, cryptoPartner].map(
    (partner) =>
      partner === PARTNER_CODE_NIUM_SG || partner === PARTNER_CODE_NIUM_USA || partner === PARTNER_CODE_LAYER2
  );
  const isFiatNium = fiatPartner === PARTNER_CODE_NIUM_SG || fiatPartner === PARTNER_CODE_NIUM_USA;

  const handleFiatPartnerChange = (event) => setFiatPartner(event.target.value);
  const handleCryptoPartnerChange = (event) => setCryptoPartner(event.target.value);

  const handleFiatPartnerCustomerIdChange = (event) => setFiatPartnerCustomerId(event.target.value);
  const handleFiatPartnerNiumWalletIdChange = (event) => setFiatPartnerWalletId(event.target.value);
  const handleCryptoPartnerLayer2CustomerIdChange = (event) => setCryptoPartnerCustomerId(event.target.value);

  const isNextEnabled = () => {
    if (showFiatFields && showCryptoFields) {
      if (fiatPartner === PARTNER_CODE_NIUM_SG || fiatPartner === PARTNER_CODE_NIUM_USA) {
        return fiatPartnerCustomerId && fiatPartnerWalletId && cryptoPartnerCustomerId;
      } else {
        return fiatPartnerCustomerId && cryptoPartnerCustomerId;
      }
    }

    if (showFiatFields) {
      if (fiatPartner === PARTNER_CODE_NIUM_SG || fiatPartner === PARTNER_CODE_NIUM_USA) {
        return fiatPartnerCustomerId && fiatPartnerWalletId;
      } else {
        return fiatPartnerCustomerId;
      }
    }

    if (showCryptoFields) {
      return cryptoPartnerCustomerId;
    }

    if (fiatPartner === PARTNER_CODE_ZAND || cryptoPartner === PARTNER_CODE_FIREBLOCKS || cryptoPartner === PARTNER_CODE_FIREBLOCKS_UAE) {
      return true;
    }

    return false;
  };

  const createConfigsForPartner = async (payload) => {
    setIsLoading(true);
    try {
      await createEntityConfigurations(payload);
      showToast(toast, 'Entity Configurations Created Successfully', '', 'success');
      nextStep();
    } catch (error) {
      showError(toast, 'Error creating Entity Configurations.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClick = async () => {
    var payload = [];

    if (fiatPartner) {
      const fiatPartnerData = {
        merchant_id: merchantId,
        entity_id: entityId,
        partner_code: fiatPartner,
        customer_id: fiatPartnerCustomerId,
        wallet_id: fiatPartnerWalletId,
        currency_type: PARTNER_TYPE_FIAT,
      };

      payload.push(fiatPartnerData);
    }

    if (cryptoPartner) {
      const cryptoPartnerData = {
        merchant_id: merchantId,
        entity_id: entityId,
        partner_code: cryptoPartner,
        customer_id: cryptoPartnerCustomerId,
        currency_type: PARTNER_TYPE_CRYPTO,
      };

      payload.push(cryptoPartnerData);
    }

    await createConfigsForPartner(payload);
  };

  useEffect(() => {
    setFiatPartnerCustomerId('');
    setFiatPartnerWalletId('');
  }, [fiatPartner]);

  useEffect(() => {
    setCryptoPartnerCustomerId('');
  }, [cryptoPartner]);

  return (
    <Box w='400px'>
      <Heading mb={8}>Select Partners</Heading>
      <Box>
        <FormControl>
          <Heading as='h2' size='md' mb={2}>
            Fiat Partner
          </Heading>
          <Select value={fiatPartner} onChange={handleFiatPartnerChange}>
            <option value=''>Not Required</option>
            <option value={PARTNER_CODE_NIUM_SG}>{PARTNER_CODE_NIUM_SG}</option>
            <option value={PARTNER_CODE_NIUM_USA}>{PARTNER_CODE_NIUM_USA}</option>
            <option value={PARTNER_CODE_LAYER2}>{PARTNER_CODE_LAYER2}</option>
            <option value={PARTNER_CODE_ZAND}>{PARTNER_CODE_ZAND}</option>
          </Select>
        </FormControl>
      </Box>
      {showFiatFields && (
        <Flex gap={4} mt={4}>
          <Box>
            <FormControl isRequired>
              <FormLabel>Customer ID</FormLabel>
              <Input size='sm' type='text' value={fiatPartnerCustomerId} onChange={handleFiatPartnerCustomerIdChange} />
            </FormControl>
          </Box>
          {isFiatNium && (
            <Box>
              <FormControl isRequired>
                <FormLabel>Wallet ID</FormLabel>
                <Input
                  size='sm'
                  type='text'
                  value={fiatPartnerWalletId}
                  onChange={handleFiatPartnerNiumWalletIdChange}
                />
              </FormControl>
            </Box>
          )}
        </Flex>
      )}
      <Box>
        <FormControl>
          <Heading as='h2' size='md' mt={4} mb={2}>
            Crypto Partner
          </Heading>
          <Select value={cryptoPartner} onChange={handleCryptoPartnerChange}>
            <option value=''>Not Required</option>
            <option value={PARTNER_CODE_LAYER2}>{PARTNER_CODE_LAYER2}</option>
            <option value={PARTNER_CODE_FIREBLOCKS}>{PARTNER_CODE_FIREBLOCKS}</option>
            <option value={PARTNER_CODE_FIREBLOCKS_UAE}>{PARTNER_CODE_FIREBLOCKS_UAE}</option>
          </Select>
        </FormControl>
      </Box>
      {showCryptoFields && (
        <Box mt={4}>
          <FormControl isRequired>
            <FormLabel>Customer ID</FormLabel>
            <Input
              size='sm'
              type='text'
              value={cryptoPartnerCustomerId}
              onChange={handleCryptoPartnerLayer2CustomerIdChange}
            />
          </FormControl>
        </Box>
      )}

      <Button
        rightIcon={<AiOutlineArrowRight />}
        colorScheme='blue'
        isDisabled={!isNextEnabled()}
        onClick={handleNextClick}
        isLoading={isLoading}
        mt={4}
      >
        Next
      </Button>
    </Box>
  );
}
