import React, { useContext, useEffect, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { createEntityCorridorTemplateMappings, getAllCorridorsTemplateMappings } from 'api/templates';
import { PostOnboardingContext } from 'modules/postonboarding/BankingPostOnboarding';
import { showError, showToast } from 'utils/notifications';
import CorridorTemplateGroup from 'components/CorridorTemplateGroup/CorridorTemplateGroup';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

const SelectCorridorTemplates = () => {
  const { merchantId, entityId, nextStep } = useContext(PostOnboardingContext);

  const [corridorTemplates, setCorridorTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const toast = useToast();

  const getAllCorridorsTemplates = async () => {
    setIsLoading(true);
    try {
      const response = await getAllCorridorsTemplateMappings(merchantId, entityId);
      const sortedCorridors = response?.data?.data?.corridor_payout_template_groups?.sort((a, b) => {
        const aEmpty = Object.keys(a?.payout_template_groups).length === 0;
        const bEmpty = Object.keys(b?.payout_template_groups).length === 0;

        return aEmpty === bEmpty ? 0 : aEmpty ? 1 : -1;
      });

      setCorridorTemplates(sortedCorridors);

      const initialSelectedTemplates = {};

      sortedCorridors?.forEach((corridor) => {
        const templateGroupIds = Object.keys(corridor?.payout_template_groups);

        initialSelectedTemplates[corridor?.corridor_id] = templateGroupIds?.length === 1 ? templateGroupIds[0] : '';
      });
      setSelectedTemplates(initialSelectedTemplates);
    } catch (err) {
      setError(err);
      showError(toast, 'Error fetching existing corridor templates', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCorridorsTemplates();
  }, [merchantId, entityId]);

  const handleTemplateChange = (corridorId, templateGroupId) => {
    setSelectedTemplates((prev) => ({
      ...prev,
      [corridorId]: templateGroupId,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const corridor_and_template_group_id_list = Object.entries(selectedTemplates).map(
      ([corridorId, templateGroupId]) => ({
        corridor_id: corridorId,
        template_group_id: templateGroupId,
      })
    );

    const payload = {
      merchant_id: merchantId,
      entity_id: entityId,
      corridor_and_template_group_id_list,
    };

    try {
      await createEntityCorridorTemplateMappings(payload);
      showToast(toast, 'Successfully created entity templates for existing corridors.', '', 'success');
      setIsLoading(false);
      nextStep();
    } catch (err) {
      showError(toast, 'Error creating entity templates for existing corridors', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box position='relative' pb='60px' minHeight='100vh'>
      <Box mb={8}>
        <Alert status='info' mb={4}>
          <AlertIcon />
          If you have already created templates for these corridors, please proceed to the next step.
          <Button colorScheme='blue' size='sm' ml={2} onClick={nextStep}>
            Add Accounts
          </Button>
        </Alert>
        <Heading>Create Corridors Templates</Heading>
        <Text color='gray' mb={4}>
          Select a template for each existing corridor or choose &quot;No template&quot; to skip.
        </Text>
      </Box>
      <LoadingErrorWrapper isLoading={isLoading} error={error} errorTitle='Error getting corridor templates.'>
        <VStack spacing={6} align='stretch'>
          {corridorTemplates?.length === 0 && <Text color='gray'>No corridors found for this merchant.</Text>}
          {corridorTemplates?.map((corridor) => (
            <Box key={corridor?.corridor_id} borderWidth='2px' p={2} borderRadius='md'>
              <Flex gap={2} align='center' mb={4}>
                <Tag size='sm' colorScheme='green'>
                  {corridor?.source_currency_code}
                </Tag>
                <HiArrowRight />
                <Tag size='sm' colorScheme='blue'>
                  {corridor?.dest_currency_code}
                </Tag>
                <Text ml={2}>({corridor?.type})</Text>
              </Flex>
              <RadioGroup
                onChange={(value) => handleTemplateChange(corridor.corridor_id, value)}
                value={selectedTemplates[corridor.corridor_id]}
              >
                <VStack align='start' spacing={4} width='100%'>
                  {Object.entries(corridor?.payout_template_groups).map(([templateGroupId, templateGroup], index) => (
                    <Box
                      key={templateGroupId}
                      width='100%'
                      onClick={() => handleTemplateChange(corridor.corridor_id, templateGroupId)}
                      cursor='pointer'
                      _hover={{ bg: 'white' }}
                      p={2}
                      borderRadius='md'
                    >
                      <Radio value={templateGroupId} mb={2}>
                        <Text fontWeight='semibold'>Template {index + 1}</Text>
                      </Radio>
                      <Box pl={6}>
                        <CorridorTemplateGroup templateGroup={templateGroup} />
                      </Box>
                    </Box>
                  ))}
                  <Box
                    width='100%'
                    onClick={() => handleTemplateChange(corridor.corridor_id, '')}
                    cursor='pointer'
                    _hover={{ bg: 'white' }}
                    p={2}
                    borderRadius='md'
                  >
                    <Radio value=''>
                      <Text fontWeight='semibold'>No template</Text>
                    </Radio>
                  </Box>
                </VStack>
              </RadioGroup>
            </Box>
          ))}
        </VStack>
      </LoadingErrorWrapper>
      <Flex justify='flex-end' position='fixed' right={6} bottom={6}>
        <Button colorScheme='blue' onClick={handleSubmit} isDisabled={isLoading || error}>
          Submit Selected Templates
        </Button>
      </Flex>
    </Box>
  );
};

export default SelectCorridorTemplates;
