import { useHistory } from 'react-router-dom';
import { Badge, Flex, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import * as ROUTES from 'constants/routes';

function TablesTableRow(props) {
  const {
    merchantId,
    name,
    code,
    countryCode,
    isTreasuryEnabled,
    isPayoutsEnabled,
    isPaymentsEnabled,
    isMerchantClickable,
    isOtcEnabled,
  } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();

  const servicesEnabled = [];

  if (isTreasuryEnabled) servicesEnabled.push('Investments');
  if (isPaymentsEnabled) servicesEnabled.push('Crypto Payments');
  if (isPayoutsEnabled) servicesEnabled.push('Banking');
  if (isOtcEnabled) servicesEnabled.push('OTC');

  const handleClick = () => {
    if (!isMerchantClickable) return;
    history.push(ROUTES.MERCHANT_INFO + merchantId);
  };

  return (
    <Tr
      onClick={handleClick}
      style={isMerchantClickable ? { cursor: 'pointer' } : {}}
      _hover={isMerchantClickable ? { background: 'gray.100' } : {}}
    >
      <Td pl='0px'>
        <Text fontSize='sm' color={textColor}>
          {merchantId}
        </Text>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {name}
        </Text>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {code}
        </Text>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {countryCode ? countryCode : '-'}
        </Text>
      </Td>
      <Td>
        <Flex wrap='wrap' gap={2}>
          {servicesEnabled.length !== 0
            ? servicesEnabled.map((service) => {
                return (
                  <Badge key={service} colorScheme='blue' rounded='0.8em' px={3}>
                    {service.toUpperCase()}
                  </Badge>
                );
              })
            : '-'}
        </Flex>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
