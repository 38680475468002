import React, { useEffect, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { getEntitiesAndCorridorTemplateMappings } from 'api/templates';
import CorridorTemplateGroup from 'components/CorridorTemplateGroup/CorridorTemplateGroup';
import SkeletonErrorWrapper from 'components/SkeletonErrorWrapper/SkeletonErrorWrapper';

const SelectEntitiesCorridorTemplates = ({
  isOpen,
  onClose,
  merchantId,
  corridorId,
  sourceCurrency,
  destinationCurrency,
  onboardedEntityId,
  onSubmit,
}) => {
  const [entitiesAndCorridorTemplates, setEntitiesAndCorridorTemplates] = useState(null);
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const toast = useToast();

  const getActiveEntitiesAndCorridorTemplates = async () => {
    setIsLoading(true);
    try {
      const response = await getEntitiesAndCorridorTemplateMappings(merchantId, corridorId);

      var activeEntities = response?.data?.data?.entities;

      if (onboardedEntityId) {
        activeEntities = [{ id: onboardedEntityId }, ...activeEntities];
      }

      const templateGroups = response?.data?.data?.payout_template_groups || {}
      const templateGroupIds = Object.keys(templateGroups);
      const defaultTemplateId = templateGroupIds.length === 1 ? templateGroupIds[0] : '';

      const initialSelectedTemplates = {};

      activeEntities?.forEach((entity) => {
        initialSelectedTemplates[entity?.id] = defaultTemplateId;
      });

      setEntitiesAndCorridorTemplates(response?.data?.data);
      setEntities(activeEntities);
      setSelectedTemplates(initialSelectedTemplates);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getActiveEntitiesAndCorridorTemplates();
    }
  }, [isOpen, merchantId, corridorId, toast]);

  const handleTemplateChange = (entityId, templateGroupId) => {
    setSelectedTemplates((prev) => ({
      ...prev,
      [entityId]: templateGroupId,
    }));
  };

  const handleSubmit = () => {
    const selectedEntitiesTemplates = Object.entries(selectedTemplates).map(([entityId, templateGroupId]) => ({
      entity_id: entityId,
      template_group_id: templateGroupId,
    }));

    onSubmit(selectedEntitiesTemplates);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='full' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg='transparent' boxShadow='none'>
        <Container maxW='container.lg' bg='white' borderRadius='md' p={6}>
          <ModalHeader>Select corridor templates for each entity</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={6}>
            <SkeletonErrorWrapper
              isLoading={isLoading}
              error={error}
              errorTitle='Error getting active entities and corridor templates.'
            >
              <Flex gap={2} align='center' mb={4}>
                <Tag size='sm' colorScheme='green'>
                  {sourceCurrency}
                </Tag>
                <HiArrowRight />
                <Tag size='sm' colorScheme='blue'>
                  {destinationCurrency}
                </Tag>
              </Flex>
              <VStack spacing={6} align='stretch'>
                {entities?.length === 0 && <Text color='gray'>No active entities found for this corridor.</Text>}
                {entities?.map((entity) => (
                  <Box key={entity?.id} borderWidth='1px' p={4}>
                    {entity?.id === onboardedEntityId ? (
                      <Text fontWeight='bold' fontSize='lg' mb={2}>
                        Entity being onboarded <Badge colorScheme='green'>New</Badge>
                      </Text>
                    ) : (
                      <Text fontWeight='bold' fontSize='lg' mb={2}>
                        {entity?.name} (Geography: {entity?.geography}){' '}
                        <Text color='green' as='span' ml={1}>
                          {entity?.status}
                        </Text>
                      </Text>
                    )}
                    <VStack align='start' spacing={4}>
                      {Object.entries(entitiesAndCorridorTemplates?.payout_template_groups).map(
                        ([templateGroupId, templateGroup]) => (
                          <Box key={templateGroupId} width='100%'>
                            <Checkbox
                              isChecked={selectedTemplates[entity?.id] === templateGroupId}
                              onChange={() => handleTemplateChange(entity?.id, templateGroupId)}
                              mb={2}
                            >
                              <CorridorTemplateGroup templateGroup={templateGroup} />
                            </Checkbox>
                          </Box>
                        )
                      )}
                      <Divider />
                      <Checkbox
                        isChecked={selectedTemplates[entity?.id] === ''}
                        onChange={() => handleTemplateChange(entity?.id, '')}
                      >
                        No template
                      </Checkbox>
                    </VStack>
                  </Box>
                ))}
              </VStack>
            </SkeletonErrorWrapper>
          </ModalBody>
          <ModalFooter>
            <Button variant='ghost' onClick={onClose} size='sm' mr={3}>
              Cancel
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit} isDisabled={isLoading || error} size='sm'>
              Set corridor pricing
            </Button>
          </ModalFooter>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default SelectEntitiesCorridorTemplates;
