import React from 'react';
import Select from 'react-select';
import { Box } from '@chakra-ui/react';

const CorridorFilter = ({ label, options, selectedValues, onChange }) => {
  const selectOptions = options.map((option) => ({
    value: option,
    label: option,
  }));

  return (
    <Box flex='1'>
      <Select
        isMulti
        placeholder={`Filter by ${label}`}
        options={selectOptions}
        value={selectedValues.map((value) => ({ value, label: value }))}
        onChange={(selectedOptions) => onChange(selectedOptions.map((option) => option.value))}
      />
    </Box>
  );
};

export default CorridorFilter;
