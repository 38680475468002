import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { createConsentOnAtomic, createConsentReview } from 'api/internaltools';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import JsonViewer from 'components/JsonViewer/JsonViewer';
import Loader from 'components/Loader/Loader';

const CreateConsent = () => {
  const { merchantId, kybId, partnerCode, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);
  const [createConsentRequests, setCreateConsentRequests] = useState(null);
  const [originalCreateConsentRequests, setOriginalCreateConsentRequests] = useState(null);
  const [isCreateConsentLoading, setIsCreateConsentLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCreateConsentReview();
  }, []);

  const getCreateConsentReview = async () => {
    try {
      setIsLoading(true);
      const payload = {
        merchantId,
        kybId,
        partnerCode,
      };
      const response = await createConsentReview(payload);
      const responseData = response?.data;

      setCreateConsentRequests(responseData?.data);
      setOriginalCreateConsentRequests(responseData?.data);
    } catch (error) {
      showError('Error getting Create Consent Request.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJsonViewerModify = (edit) => {
    setCreateConsentRequests(edit.updated_src);
  };

  const handleResetJson = () => {
    setCreateConsentRequests(originalCreateConsentRequests);
  };

  const handleCreateConsent = async () => {
    setIsCreateConsentLoading(true);
    const payload = {
      kybId,
      partnerCode,
      createConsentOnAtomicRequests: createConsentRequests,
    };

    try {
      await createConsentOnAtomic(payload);

      showToast('Successfully created consent on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error creating consent on Atomic.', error);
    }
    setIsCreateConsentLoading(false);
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Create Consent
      </Heading>
      {isLoading && <Loader />}
      {createConsentRequests && (
        <Box mt={4}>
          <JsonViewer
            jsonContent={createConsentRequests}
            onModify={handleJsonViewerModify}
            onReset={handleResetJson}
            height='50vh'
          />
          <Button colorScheme='blue' mt={4} onClick={handleCreateConsent} isLoading={isCreateConsentLoading}>
            Create Consent
          </Button>
        </Box>
      )}
    </>
  );
};

export default CreateConsent;
