export const INITIATED_ACCOUNTS_TABLE_HEADINGS = [
  'Action',
  'Merchant ID',
  'Entity ID',
  'Nickname',
  'Currency Code',
  'Currency Type',
  'Partner',
  'Initiated At',
];

export const CURRENCY_TYPE_FIAT = 'Fiat';
export const CURRENCY_TYPE_DIGITAL_ASSET = 'Digital Asset';

export const ERROR_FETCHING_INITIATED_ACCOUNTS = 'Error fetching initiated accounts.';

export const ACCOUNT_STATUS_INITIALIZED = 'INITIALIZED';
export const ACCOUNT_STATUS_ACTIVE = 'Active';
