import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { requestTechSupportLogin } from 'api/internaltools';
import { SMB, TMS } from 'constants/constants';
import { PermissionsV2 } from 'constants/permissions_v2';
import { TECH_SUPPORT_SMB_EMAIL, TECH_SUPPORT_TMS_EMAIL } from 'modules/techsupport/constants/constants';
import RequestTechSupport from 'modules/techsupport/RequestTechSupport';
import Merchants from 'pages/merchants';
import { showError, showToast } from 'utils/notifications';
import PermissionWrapper from 'components/hoc/PermissionWrapper';

const TechSupportRequest = () => {
  const toast = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [service, setService] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = (service) => {
    setModalOpen(true);
    setService(service);
  };

  const handleCancel = () => {
    setMerchantId('');
    setModalOpen(false);
  };

  const handleRequestTechSupportClick = async () => {
    setIsLoading(true);
    try {
      const payload = {
        merchant_id: merchantId,
        service: service,
      };

      await requestTechSupportLogin(payload);
      showToast(toast, 'Tech Support Login requested!', '', 'success');
    } catch (error) {
      showError(toast, `Error requesting Tech Support Login.`, error);
    }

    setMerchantId('');
    setIsLoading(false);
    setModalOpen(false);
  };

  return (
    <Box pt='30px'>
      <Flex gap={4}>
        <RequestTechSupport
          onRequestTechSupportBtnClick={handleButtonClick}
          service={SMB}
          email={TECH_SUPPORT_SMB_EMAIL}
        />
        <RequestTechSupport
          onRequestTechSupportBtnClick={handleButtonClick}
          service={TMS}
          email={TECH_SUPPORT_TMS_EMAIL}
        />
      </Flex>

      <PermissionWrapper permission={PermissionsV2.VIEW_MERCHANTS}>
        <Merchants isMerchantClickable={false} />
      </PermissionWrapper>

      <Modal isOpen={isModalOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Merchant ID</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder='Merchant ID' value={merchantId} onChange={(e) => setMerchantId(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleCancel} size='sm'>
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              onClick={handleRequestTechSupportClick}
              size='sm'
              ml={2}
              isDisabled={merchantId === ''}
              isLoading={isLoading}
            >
              Request
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TechSupportRequest;
