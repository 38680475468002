import React, { useEffect, useState } from 'react';
import * as internalToolsApi from 'api/internaltools';
import EntitiesDetails from 'modules/merchantinfo/components/EntitiesDetails';
import MerchantDetails from 'modules/merchantinfo/components/MerchantDetails';

export default function MerchantAndEntityDetails({ merchantId }) {
  const [merchantCompanyDetails, setMerchantCompanyDetails] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const entitiesKybStatus = merchantCompanyDetails?.entity_details?.map((entity) => [
    entity.entity_id,
    entity.kyb_status,
  ]);

  useEffect(() => {
    const getMerchantCompanyDetails = async () => {
      try {
        setIsLoading(true);
        const response = await internalToolsApi.getMerchantCompanyDetails(merchantId);

        setMerchantCompanyDetails(response?.data?.data);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    getMerchantCompanyDetails();
  }, []);

  return (
    <>
      <MerchantDetails
        isLoading={isLoading}
        merchantAndEntitiesDetails={{
          merchant: {
            merchantId: merchantCompanyDetails?.merchant_id,
            merchantName: merchantCompanyDetails?.merchant_name,
          },
          entitiesKybStatus,
        }}
        error={error}
      />
      <EntitiesDetails
        isLoading={isLoading}
        merchantId={merchantCompanyDetails?.merchant_id}
        entitiesDetails={merchantCompanyDetails?.entity_details}
        error={error}
      />
    </>
  );
}
