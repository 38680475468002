import { downloadZip } from 'client-zip';

export const isOpsDashboard = () => {
  return window?.location?.hostname === process.env.REACT_APP_INTERNAL_TOOLS_HOST;
};

export const getMaxElementInArray = (arr) => {
  return Math.max(...arr.filter((index) => index !== null));
};

export const calculateNumOfCompanyOwners = (kybMap) => {
  const companyOwners = Object.keys(kybMap).filter((key) => {
    return key?.includes('company_owners');
  });

  if (companyOwners?.length === 0) {
    return 0;
  }

  const ownerIndices = companyOwners.map((key) => {
    const match = key.match(/\[(\d+)\]/); // to extract the index

    return match ? parseInt(match[1], 10) : null;
  });

  const maxOwnerIndex = getMaxElementInArray(ownerIndices);

  return maxOwnerIndex + 1;
};

export const calculateNumofAdditionalBusinessDocs = (kybMap) => {
  const additionalBusinessDocs = Object.keys(kybMap).filter((key) => {
    return key?.includes('additional_business_document');
  });

  return additionalBusinessDocs?.length;
};

export const formatString = (inputString, delimiter) => {
  let words = inputString?.split(delimiter);

  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1);
  }

  let formattedString = words?.join(' ');

  return formattedString;
};

export const downloadZipFile = async (downloadLinks, zipName) => {
  try {
    if (downloadLinks?.length) {
      const promises = downloadLinks?.map((url) => fetch(url));
      const codes = await Promise.all(promises);

      // get the ZIP stream in a Blob
      const blob = await downloadZip(codes)?.blob();

      // make and click a temporary link to download the Blob
      const link = document.createElement('a');
      const objectURL = URL.createObjectURL(blob);

      link.href = objectURL;
      link.download = `${zipName}.zip`;
      link.click();
      link.remove();
      URL.revokeObjectURL(objectURL);
    }
  } catch (error) {
    console.error('Error downloading ZIP file', error);
  }
};

export const getCookieByName = (name) => {
  const cookie = {};

  document.cookie.split(';').forEach(function (el) {
    const [k, v] = el.split('=');

    cookie[k.trim()] = v;
  });

  return cookie[name];
};
