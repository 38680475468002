import React, { useEffect, useState } from 'react';
import { RiUserLine } from 'react-icons/ri';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getKybById, upsertDocumentToKybDetails } from 'api/internaltools';
import UploadOwnerDocumentInput from 'modules/merchantinfo/components/UploadOwnerDocumentInput';
import {
  ENTITY_OWNER_DOCUMENT_TYPES,
  FILE_EXTENSION_TO_TYPE_MAPPING,
  INPUT_FILE_FORMATS,
} from 'modules/merchantinfo/constants/constants';
import { showError, showToast } from 'utils/notifications';
import { calculateNumOfCompanyOwners } from 'utils/utils';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

function UploadOwnerDocumentModal({ isOpen, onClose, merchantId, entityName, kybId }) {
  const [owners, setOwners] = useState([]);
  const [selectedOwnerIndex, setSelectedOwnerIndex] = useState(-1);
  const [isBusinessDocument, setIsBusinessDocument] = useState(true);
  const [selectedDocumentType, setSelectedDocumentType] = useState(ENTITY_OWNER_DOCUMENT_TYPES[0].value);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploadSignedUrlResponse, setUploadSignedUrlResponse] = useState(null);
  const [isOwnersLoading, setIsOwnersLoading] = useState(false);
  const [isConfirmUploadLoading, setIsConfirmUploadLoading] = useState(false);
  const [error, setError] = useState(null);

  const toast = useToast();

  const constructCompanyOwnerNames = (kybMap, num) => {
    const companyOwnersNames = [];

    for (let i = 0; i < num; ++i) {
      const firstName = kybMap[`first_name_company_owners[${i}]`]?.value;
      const lastName = kybMap[`last_name_company_owners[${i}]`]?.value;

      if (firstName || lastName) {
        companyOwnersNames.push(`${firstName ? firstName + ' ' : ''}${lastName || ''}`);
      }
    }

    return companyOwnersNames;
  };

  const getCompanyOwners = async () => {
    setIsOwnersLoading(true);
    try {
      const response = await getKybById(kybId);
      const kybDetails = response?.data?.data?.details;
      const kybMap = {};

      kybDetails?.forEach((detail) => {
        kybMap[`${detail.key}_${detail.parent}`] = detail;
      });

      const numOfCompanyOwners = calculateNumOfCompanyOwners(kybMap);
      const companyOwnersNames = constructCompanyOwnerNames(kybMap, numOfCompanyOwners);

      setOwners(companyOwnersNames);
    } catch (err) {
      setError(err);
    }
    setIsOwnersLoading(false);
  };

  const addDocumentToKybDetails = async () => {
    setIsConfirmUploadLoading(true);

    try {
      const headers = new Headers();
      const fileExtension = fileToUpload?.name?.split('.')?.pop() ?? '';

      headers.append('Content-Type', fileToUpload?.type || FILE_EXTENSION_TO_TYPE_MAPPING[fileExtension]);

      const requestOptions = {
        method: 'PUT',
        headers,
        body: fileToUpload,
      };

      const uploadResponse = await fetch(uploadSignedUrlResponse?.url, requestOptions);

      if (uploadResponse?.status !== 200) {
        setError(`* Error: ${uploadResponse?.statusText}. Try again.` ?? '* Failed to upload document. Try again.');

        return;
      }

      const payload = {
        kybId,
        documentKey: selectedDocumentType,
        documentName: uploadSignedUrlResponse?.identifier,
        companyOwner: !isBusinessDocument ? `company_owners[${selectedOwnerIndex}]` : '',
        isBusinessDocument,
      };

      await upsertDocumentToKybDetails(payload);
      showToast(toast, 'Upload successful', 'Document successfully added to entity KYB.', 'success');
    } catch (err) {
      showError(toast, 'Error', err);
    }

    setIsConfirmUploadLoading(false);
    onClose();
  };

  const handleBusinessDocumentChange = (e) => {
    if (owners?.length === 0) return;

    setIsBusinessDocument(e.target.checked);

    if (e.target.checked) {
      setSelectedOwnerIndex(-1);
    } else {
      setSelectedOwnerIndex(0);
    }
  };

  const handleOwnerChange = (idx) => {
    setSelectedOwnerIndex(idx);

    if (isBusinessDocument) setIsBusinessDocument(false);
  };

  const handleDocumentTypeChange = (e) => {
    setSelectedDocumentType(e.target.value);
  };

  useEffect(() => {
    getCompanyOwners();
  }, []);

  useEffect(() => {
    if (fileToUpload) {
      setFileToUpload(null);
      setUploadSignedUrlResponse(null);

      showToast(toast, 'Document details changed', 'Please upload the document again.', 'warning');
    }
  }, [isBusinessDocument, selectedOwnerIndex, selectedDocumentType]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction='column' gap={4}>
            <HStack>
              <RiUserLine />
              <Text>{entityName}</Text>
            </HStack>

            <Box>
              <Checkbox isChecked={isBusinessDocument} onChange={handleBusinessDocumentChange} size='lg'>
                Is business document
              </Checkbox>
            </Box>

            <Box>
              <Text fontWeight='bold' fontSize='lg' mb={2}>
                Select owner
              </Text>
              <LoadingErrorWrapper isLoading={isOwnersLoading} errorTitle='Error fetching entity owners.' error={error}>
                <Flex gap={3}>
                  {owners.map((owner, idx) => (
                    <Box
                      key={idx}
                      onClick={() => handleOwnerChange(idx)}
                      style={{
                        padding: '8px 20px',
                        cursor: 'pointer',
                        borderWidth: '1px',
                        borderRadius: '6px',
                        backgroundColor: selectedOwnerIndex === idx ? 'rgba(0,0,0,0.1)' : 'transparent',
                      }}
                    >
                      {owner}
                    </Box>
                  ))}
                  {owners.length === 0 && <Text>No owners found.</Text>}
                </Flex>
              </LoadingErrorWrapper>
            </Box>

            <Box>
              <Text fontWeight='bold' fontSize='lg' mb={2}>
                Select document type
              </Text>
              <Select value={selectedDocumentType} onChange={handleDocumentTypeChange}>
                {ENTITY_OWNER_DOCUMENT_TYPES.map((document) => (
                  <option key={document.value} value={document.value}>
                    {document.label}
                  </option>
                ))}
              </Select>
            </Box>

            <UploadOwnerDocumentInput
              merchantId={merchantId}
              kybId={kybId}
              selectedDocumentType={selectedDocumentType}
              isBusinessDocument={isBusinessDocument}
              selectedOwnerIndex={selectedOwnerIndex}
              fileToUpload={fileToUpload}
              setFileToUpload={setFileToUpload}
              uploadSignedUrlResponse={uploadSignedUrlResponse}
              setUploadSignedUrlResponse={setUploadSignedUrlResponse}
              acceptedFormats={`${INPUT_FILE_FORMATS.PNG}, ${INPUT_FILE_FORMATS.JPEG}, ${INPUT_FILE_FORMATS.JPG}, ${INPUT_FILE_FORMATS.PDF}, ${INPUT_FILE_FORMATS.BMP}`}
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' size='sm' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isConfirmUploadLoading}
            colorScheme='blue'
            size='sm'
            isDisabled={!fileToUpload}
            onClick={addDocumentToKybDetails}
          >
            Confirm upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UploadOwnerDocumentModal;
