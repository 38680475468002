import axios from 'axios';

const stagingInstance = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_TOOLS_STAGING_API_URL,
  withCredentials: true,
});

const productionInstance = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_TOOLS_PROD_API_URL,
  withCredentials: true,
});

export const submitKYBOnNiumSandbox = (payload) => {
  return stagingInstance.post('/kyb/submit/nium', payload);
};

export const submitKYBOnNiumCorporate = (payload) => {
  return productionInstance.post('/kyb/submit/nium', payload);
};
