import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, IconButton, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { KYB, KYB_SERVICE_INTENT_V2 } from 'constants/entityDetails';
import {
  ERROR_FETCHING_INITIATED_KYBS,
  INITIATED_KYBS_TABLE_HEADINGS,
} from 'modules/postonboarding/constants/constants';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';
import BankingInitiatedKybRow from 'components/Tables/BankingInitiatedKybRow';

const PostOnboarding = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [initiatedKybsList, setInitiatedKybsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchInitiatedKybs();
  }, []);

  const fetchInitiatedKybs = async () => {
    try {
      setIsLoading(true);
      const response = await internalToolsApi.getInitiatedKybs(false);

      const responseData = response?.data?.data ?? [];
      const initiatedKybs = responseData.filter((kyb) => KYB_SERVICE_INTENT_V2.includes(kyb?.service));
      const entityIdAndIntentMap = initiatedKybs?.reduce((acc, kyb) => {
        acc[kyb.entity_id] = [...(acc[kyb.entity_id] ?? []), kyb.service];

        return acc;
      }, {});

      const groupedKybs = initiatedKybs?.reduce((acc = [], kyb) => {
        const index = acc.findIndex((item) => item.entity_id === kyb.entity_id);

        if (index === -1) {
          acc.push({ ...kyb, services: entityIdAndIntentMap[kyb.entity_id] });
        }

        return acc;
      }, []);

      setInitiatedKybsList(groupedKybs);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const filteredKybList = useMemo(() => {
    if (!searchTerm) return initiatedKybsList;

    return initiatedKybsList.filter(
      (kyb) =>
        kyb?.merchant_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.entity_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.entity_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.kyb_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.service?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [initiatedKybsList, searchTerm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredKybList]);

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const visibleKybList = filteredKybList.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredKybList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPages = Math.ceil(filteredKybList.length / limit);

  return (
    <Flex direction='column' pt='30px' gap={4}>
      <Search searchType={KYB} setSearchTerm={setSearchTerm} />
      <Card overflowX={{ sm: 'scroll' }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Banking Initiated KYBs{' '}
            <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
              {filteredKybList.length}
            </Badge>
          </Text>
        </CardHeader>
        <CardBody>
          <LoadingErrorWrapper isLoading={isLoading} errorTitle={ERROR_FETCHING_INITIATED_KYBS} error={error}>
            <Table color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  {INITIATED_KYBS_TABLE_HEADINGS.map((caption, idx) => {
                    return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                        {caption}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {visibleKybList.map((row, idx) => {
                  return (
                    <BankingInitiatedKybRow
                      key={idx}
                      merchantId={row?.merchant_id}
                      entityId={row?.entity_id}
                      entityName={row?.entity_name}
                      kybId={row?.kyb_id}
                      services={row?.services}
                      createdAt={row?.created_at}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </LoadingErrorWrapper>
        </CardBody>
      </Card>
      {filteredKybList.length ? (
        <Flex justify='space-between' align='center'>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredKybList.length}
            />
          </Box>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default PostOnboarding;
