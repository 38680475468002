import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

function KybDetails({ headings, headingsValues }) {
  return (
    <Flex flexWrap='wrap' gap={4}>
      {headings?.map((heading, idx) => (
        <Box key={idx} p={2} borderRadius='10px' bg='gray.100' color='gray'>
          <Heading as='h2' size='md'>
            {heading}
          </Heading>
          <Text>{headingsValues?.[idx]}</Text>
        </Box>
      ))}
    </Flex>
  );
}

export default KybDetails;
