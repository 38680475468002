import React, { useEffect,useState } from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { getTransactionDetails } from 'api/transactions';
import SkeletonErrorWrapper from 'components/SkeletonErrorWrapper/SkeletonErrorWrapper';

export default function BeneficiaryDetails({ transactionId, onSuccess }) {
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const isOnSuccessFunction = typeof onSuccess == 'function';

  const isBeneficiaryNotCreatedOnPartner =
    transactionDetails?.beneficiary_details?.beneficiary_name_on_partner === '' &&
    transactionDetails?.beneficiary_details?.beneficiary_account_number_on_partner === '' &&
    transactionDetails?.beneficiary_details?.beneficiary_routing_code_on_partner === '';

  const getTransactionDetailsForApproval = async () => {
    setIsLoading(true);
    try {
      const response = await getTransactionDetails(transactionId);

      setTransactionDetails(response?.data?.data);

      if (isOnSuccessFunction) {
        onSuccess(true);
      }
    } catch (err) {
      setError(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (transactionId) {
      getTransactionDetailsForApproval();
    }

    return () => {
      setTransactionDetails(null);
      setError(null);

      if (isOnSuccessFunction) {
        onSuccess(false);
      }
    };
  }, [transactionId]);

  return (
    <SkeletonErrorWrapper isLoading={isLoading} error={error} errorTitle='Error loading beneficiary details'>
      {transactionDetails && (
        <Box>
          <Box mb={4} borderWidth='1px' borderRadius='lg' p={4}>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Transaction Information
            </Text>
            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
              <GridItem>
                <Text>
                  <strong>Transaction ID:</strong>
                </Text>
              </GridItem>
              <GridItem>
                <Text>{transactionDetails?.transaction?.id}</Text>
              </GridItem>
              <GridItem>
                <Text>
                  <strong>Receiving Amount:</strong>
                </Text>
              </GridItem>
              <GridItem>
                <Text>
                  {transactionDetails?.beneficiary_details?.destination_currency} {transactionDetails?.receiving_amount}
                </Text>
              </GridItem>
            </Grid>
          </Box>

          <Box mb={4} borderWidth='1px' borderRadius='lg' p={4}>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Beneficiary Information on Roma
            </Text>
            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
              <GridItem>
                <Text>
                  <strong>Beneficiary Name:</strong>
                </Text>
              </GridItem>
              <GridItem>
                <Text>{transactionDetails?.beneficiary_details?.beneficiary_name}</Text>
              </GridItem>
              <GridItem>
                <Text>
                  <strong>Account Number:</strong>
                </Text>
              </GridItem>
              <GridItem>
                <Text>{transactionDetails?.beneficiary_details?.beneficiary_account_number}</Text>
              </GridItem>
              <GridItem>
                <Text>
                  <strong>Routing Code Type:</strong>
                </Text>
              </GridItem>
              <GridItem>
                <Text>{transactionDetails?.beneficiary_details?.beneficiary_routing_code_type_1}</Text>
              </GridItem>
              <GridItem>
                <Text>
                  <strong>Routing Code:</strong>
                </Text>
              </GridItem>
              <GridItem>
                <Text>{transactionDetails?.beneficiary_details?.beneficiary_routing_code_value_1}</Text>
              </GridItem>
            </Grid>
          </Box>

          <Box mb={4} borderWidth='1px' borderRadius='lg' p={4}>
            <Text fontSize='lg' fontWeight='bold' mb={2}>
              Beneficiary Information on Partner
            </Text>
            {transactionDetails?.beneficiary_details?.payout_method === 'FIAT' && !isBeneficiaryNotCreatedOnPartner && (
              <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                <GridItem>
                  <Text>
                    <strong>ID:</strong>
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{transactionDetails?.beneficiary_details?.beneficiary_partner_id}</Text>
                </GridItem>
                <GridItem>
                  <Text>
                    <strong>Partner:</strong>
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{transactionDetails?.beneficiary_details?.beneficiary_partner}</Text>
                </GridItem>
                <GridItem>
                  <Text>
                    <strong>Beneficiary Name:</strong>
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{transactionDetails?.beneficiary_details?.beneficiary_name_on_partner}</Text>
                </GridItem>
                <GridItem>
                  <Text>
                    <strong>Account Number:</strong>
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{transactionDetails?.beneficiary_details?.beneficiary_account_number_on_partner}</Text>
                </GridItem>
                <GridItem>
                  <Text>
                    <strong>Routing Code:</strong>
                  </Text>
                </GridItem>
                <GridItem>
                  <Text>{transactionDetails?.beneficiary_details?.beneficiary_routing_code_on_partner}</Text>
                </GridItem>
              </Grid>
            )}
            {transactionDetails?.beneficiary_details?.payout_method === 'FIAT' && isBeneficiaryNotCreatedOnPartner && (
              <Text>
                Beneficiary not yet created on {transactionDetails?.beneficiary_details?.beneficiary_partner}.
              </Text>
            )}
            {transactionDetails?.beneficiary_details?.payout_method === 'CRYPTO' && (
              <Text>No beneficiary partner details exists for crypto beneficiaries.</Text>
            )}
          </Box>
        </Box>
      )}
    </SkeletonErrorWrapper>
  );
}
