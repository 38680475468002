/**
 * Converts a File object to its base64-encoded representation.
 *
 * @param {File} document - The File object to be converted.
 * @returns {Promise<string|null>} A Promise that resolves with the base64-encoded string of the document,
 *                             or null if the input document is falsy.
 * @throws {Error} If there's an error during the conversion process, the Promise will be rejected with an error.
 */
export const convertFileToBase64 = (document) => {
  if (!document) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(',')[1];

      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(document);
  });
};

/**
 * Converts an array of uploaded documents to their base64-encoded representations
 * and prepares them for payload inclusion.
 *
 * @param {Array} payloadDocuments - An array to which the converted documents will be added.
 * @param {Array} uploadedDocuments - An array of uploaded document objects.
 * @returns {Promise<void>} A Promise that resolves when all documents are converted and added to the payload.
 * @throws {Error} If there's an error during the conversion process, the Promise will be rejected with an error.
 */
export const convertFileToBase64Wrapper = async (payloadDocuments, uploadedDocuments) => {
  for (const uploadedDocument of uploadedDocuments) {
    const base64File = await convertFileToBase64(uploadedDocument.document);

    payloadDocuments.push({
      fileName: uploadedDocument.fileName,
      fileType: uploadedDocument.fileType,
      document: base64File?.substring(base64File.indexOf(',') + 1),
    });
  }
};

/**
 * Finds the number of unique stakeholders based on KYB details.
 *
 * @param {Array} kybDetails - An array of KYB details objects.
 * @returns {number} The total number of unique stakeholders found in the KYB details.
 */
export const findNumofStakeholders = (kybDetails) => {
  const totalStakeholders = new Set();

  kybDetails.forEach((kybDetail) => {
    const parentMatch = kybDetail?.parent?.match(/company_owners\[(\d+)\]/);

    if (parentMatch) {
      const stakeholderIdx = parentMatch[1];

      totalStakeholders.add(stakeholderIdx);
    }
  });

  return totalStakeholders.size;
};
