import React, { useEffect, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { MERCHANT_CORRIDOR_TABLE_HEADINGS } from 'modules/merchantinfo/constants/constants';
import { showError, showToast } from 'utils/notifications';
import ActionButton from 'components/ActionButton/ActionButton';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import CorridorPricingDialog from 'components/CreateCorridors/CorridorPricingDialog';
import CreateCorridorModal from 'components/CreateCorridors/CreateCorridorModal';
import Loader from 'components/Loader/Loader';

export default function MerchantCorridorsManagement({ merchantId }) {
  const toast = useToast();
  const [merchantCorridors, setMerchantCorridors] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCorridorPricingDialogOpen, setIsCorridorPricingDialogOpen] = useState(false);
  const [merchantMappingId, setMerchantMappingId] = useState(null);
  const [isCreateCorridorModalOpen, setIsCreateCorridorModalOpen] = useState(false);
  const [sourceCurrency, setSourceCurrency] = useState('');
  const [destinationCurrency, setDestinationCurrency] = useState('');

  const fetchMerchantCorridors = async () => {
    try {
      setIsLoading(true);
      const response = await internalToolsApi.getMerchantCorridors(merchantId);

      setMerchantCorridors(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const toggleMerchantCorridor = async (corridorId, isEnabled) => {
    try {
      const payload = {
        parent_id: merchantId,
        corridor_id: corridorId,
      };

      await internalToolsApi.toggleMerchantCorridor(payload);
      showToast(
        toast,
        'Success',
        `Corridor ${corridorId} successfully ${isEnabled ? 'disabled' : 'enabled'}.`,
        'success'
      );

      // Refresh merchant corridors after toggling
      await fetchMerchantCorridors();
    } catch (error) {
      showError(toast, `Error toggling corridor - ${corridorId}.`, error);
    }
  };

  const openCorridorPricingDialog = (id, sourceCurrency, destinationCurrency) => {
    setMerchantMappingId(id);
    setSourceCurrency(sourceCurrency);
    setDestinationCurrency(destinationCurrency);
    setIsCorridorPricingDialogOpen(true);
  };

  const closeCorridorPricingDialog = () => {
    setIsCorridorPricingDialogOpen(false);
  };

  const handleUpdateCorridorPricing = async (corridorPricingData) => {
    if (corridorPricingData.markup || corridorPricingData.layer2 || corridorPricingData.fedwireFees || corridorPricingData.swiftFees) {
      const merchant_corridor_properties = {};
      let ABS, BPS;

      if (corridorPricingData.markup) {
        ABS = parseFloat(corridorPricingData.markup.ABS);
        BPS = parseFloat(corridorPricingData.markup.BPS);
        merchant_corridor_properties.markup_fees = { ABS, BPS };
      }
      if (corridorPricingData.layer2) {
        ABS = parseFloat(corridorPricingData.layer2.ABS);
        BPS = parseFloat(corridorPricingData.layer2.BPS);
        merchant_corridor_properties.layer2_fees = { ABS, BPS };
      }
      if (corridorPricingData.fedwireFees) {
        merchant_corridor_properties.fedwire_fees = parseFloat(corridorPricingData.fedwireFees);
      }
      if (corridorPricingData.swiftFees) {
        merchant_corridor_properties.swift_fees = parseFloat(corridorPricingData.swiftFees);
      }
      try {
        const payload = {
          parent_id: merchantId,
          merchant_mapping_id: merchantMappingId,
          merchant_corridor_properties,
          is_fedwire_fees_to_be_updated: corridorPricingData.isFedwireFeesToBeCreatedOrUpdated,
          is_swift_fees_to_be_updated: corridorPricingData.isSwiftFeesToBeCreatedOrUpdated,
        };

        await internalToolsApi.updateMerchantCorridorPricing(payload);
        showToast(toast, 'Success', `Pricing successfully updated.`, 'success');
      } catch (error) {
        showError(toast, `Error updating corridor pricing.`, error);
      }
    } else {
      showToast(toast, 'No pricing to update.', '', 'info');
    }
  };

  const openCreateCorridorModal = () => {
    setIsCreateCorridorModalOpen(true);
  };

  const closeCreateCorridorModal = () => {
    setIsCreateCorridorModalOpen(false);
  };

  useEffect(() => {
    // If the modal is closed, fetch merchant corridors
    if (!isCreateCorridorModalOpen) {
      fetchMerchantCorridors();
    }
  }, [isCreateCorridorModalOpen]);

  return (
    <Box>
      <Flex justify='space-between' mb={3}>
        <Heading as='h1' size='md'>
          Merchant Corridors
        </Heading>
        <Button leftIcon={<AddIcon />} onClick={openCreateCorridorModal}>
          New Corridor
        </Button>
      </Flex>
      <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {MERCHANT_CORRIDOR_TABLE_HEADINGS.map((heading, idx) => (
                <Th key={idx}>{heading}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {merchantCorridors?.map((corridor) => (
              <Tr key={corridor?.id} fontSize='sm'>
                <Td color='gray'>{corridor?.id ?? '-'}</Td>
                <Td>{corridor?.source_currency_code ?? '-'}</Td>
                <Td>{corridor?.dest_currency_code ?? '-'}</Td>
                <Td>{corridor?.type ?? '-'}</Td>
                <Td>
                  <Badge colorScheme={corridor?.is_enabled ? 'green' : 'red'} rounded='0.8em' px={3}>
                    {corridor?.is_enabled ? 'Active' : 'Inactive'}
                  </Badge>
                </Td>
                <Td>
                  <Flex gap={2}>
                    <Button
                      size='xs'
                      onClick={() =>
                        openCorridorPricingDialog(
                          corridor?.id,
                          corridor?.source_currency_code,
                          corridor?.dest_currency_code
                        )
                      }
                      isDisabled={!corridor?.is_enabled}
                    >
                      Edit pricing
                    </Button>
                    <ActionButton
                      buttonText={corridor?.is_enabled ? 'Disable' : 'Enable'}
                      colorScheme={corridor?.is_enabled ? 'red' : 'blue'}
                      onClick={() => toggleMerchantCorridor(corridor?.corridor_id, corridor?.is_enabled)}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {!merchantCorridors.length && !isLoading && !error && (
        <Center>
          <Text color='gray' mt={4}>
            No merchant corridors found.
          </Text>
        </Center>
      )}

      {isLoading && <Loader />}
      {error && <AlertMessage errorTitle='Error fetching merchant corridors.' errorMessage={error?.message} />}

      {isCorridorPricingDialogOpen && (
        <CorridorPricingDialog
          modalHeaderText='Edit Corridor Fees'
          modalFooterBtnText='Update Pricing'
          merchantMappingId={merchantMappingId}
          sourceCurrency={sourceCurrency}
          destinationCurrency={destinationCurrency}
          isOpen={isCorridorPricingDialogOpen}
          onClose={closeCorridorPricingDialog}
          onSubmit={handleUpdateCorridorPricing}
          modalInfoText='Modifying the fees for this corridor will affect the pricing across all active entities.'
        />
      )}

      {isCreateCorridorModalOpen && (
        <CreateCorridorModal
          isOpen={isCreateCorridorModalOpen}
          onClose={closeCreateCorridorModal}
          merchantId={merchantId}
        />
      )}
    </Box>
  );
}
