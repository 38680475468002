import { extendTheme } from '@chakra-ui/react';
import { CardComponent } from 'theme/additions/card/Card';
import { CardBodyComponent } from 'theme/additions/card/CardBody';
import { CardHeaderComponent } from 'theme/additions/card/CardHeader';
import { MainPanelComponent } from 'theme/additions/layout/MainPanel';
import { PanelContainerComponent } from 'theme/additions/layout/PanelContainer';
import { PanelContentComponent } from 'theme/additions/layout/PanelContent';
import { MultiStepComponent } from 'theme/additions/multistep/multistep';
import { badgeStyles } from 'theme/components/badge';
import { buttonStyles } from 'theme/components/button';
import { drawerStyles } from 'theme/components/drawer';
import { linkStyles } from 'theme/components/link';
import { breakpoints } from 'theme/foundations/breakpoints';
import { globalStyles } from 'theme/styles';

export default extendTheme(
  { breakpoints }, // Breakpoints
  globalStyles,
  buttonStyles, // Button styles
  badgeStyles, // Badge styles
  linkStyles, // Link styles
  drawerStyles, // Sidebar variant for Chakra's drawer
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent, // Panel Container component
  MultiStepComponent // MultiStep component
);
