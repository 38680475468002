export const ENTITY_STATUS = {
  PROMISED: 'promised',
  INITIATED: 'initiated',
  ACTIVE: 'active',
};

export const KYB = 'KYB';

export const KYB_STATUS = {
  NOT_INITIATED: 'not_initiated',
  INITIATED: 'initiated',
  INFORMATION_REQUESTED: 'informated_requested',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  EXPIRED: 'expired',
};

export const KYB_SERVICE_INTENT = {
  BANKING_ACCOUNT: 'banking_account',
  TREASURY_ACCOUNT: 'treasury_account',
  CARDS: 'cards',
  USD_BANKING: 'usd_banking',
  CRYPTO_PAYMENT_GATEWAY: 'crypto_payment_gateway',
  OTC_DESK: 'otc_desk',
};

export const KYB_SERVICE_INTENT_V2 = [
  KYB_SERVICE_INTENT.USD_BANKING,
  KYB_SERVICE_INTENT.CRYPTO_PAYMENT_GATEWAY,
  KYB_SERVICE_INTENT.OTC_DESK,
  KYB_SERVICE_INTENT.BANKING_ACCOUNT,
];

export const ACCOUNT = 'Account';
