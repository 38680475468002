import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from '@chakra-ui/react';
import { approveTransaction } from 'api/transactions';
import BeneficiaryDetails from 'modules/approve-transactions/BeneficiaryDetails';
import { showError, showToast } from 'utils/notifications';

const TransactionDetailsDrawer = ({ isOpen, onClose, transactionId, onApproveSuccess }) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isBeneficiaryDetailsAvailable, setIsBeneficiaryDetailsAvailable] = useState(false);
  const toast = useToast();

  const handleApproveTransaction = async () => {
    setIsApproving(true);
    try {
      await approveTransaction({ payout_session_id: transactionId });
      showToast(toast, 'Transaction approved.', 'Transaction has been approved successfully.', 'success');
      onApproveSuccess();
    } catch (err) {
      showError(toast, 'Error approving transaction', err);
    }

    setIsApproving(false);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Beneficiary Details</DrawerHeader>
        <DrawerBody>
          <BeneficiaryDetails
            transactionId={transactionId}
            onSuccess={(status) => setIsBeneficiaryDetailsAvailable(status)}
          />
          <DrawerFooter>
            {isBeneficiaryDetailsAvailable && (
              <>
                <Button variant='outline' mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='blue' isLoading={isApproving} onClick={handleApproveTransaction}>
                  Approve
                </Button>
              </>
            )}
          </DrawerFooter>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TransactionDetailsDrawer;
