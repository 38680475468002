import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Heading, useToast } from '@chakra-ui/react';
import { getKybById, getLastExecutedStep } from 'api/internaltools';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { KYB_SUBMISSION_ROUTE } from 'constants/routes';
import ConfirmAccount from 'modules/onboarding/components/atomic/ConfirmAccount';
import CreateAccount from 'modules/onboarding/components/atomic/CreateAccount';
import CreateConsent from 'modules/onboarding/components/atomic/CreateConsent';
// Components for actual Atomic Onboarding
import CreateCorporate from 'modules/onboarding/components/atomic/CreateCorporate';
import CreateParticipant from 'modules/onboarding/components/atomic/CreateParticipant';
import UploadDocument from 'modules/onboarding/components/atomic/UploadDocument';
import VerifyCorporate from 'modules/onboarding/components/atomic/VerifyCorporate';
import VerifyParticipant from 'modules/onboarding/components/atomic/VerifyParticipant';
import {
  ATOMIC,
  ATOMIC_ONBOARDING_STEPS,
  BUSINESS_TYPE_KEY,
  INDUSTRY_TYPE_KEY,
  MERCHANT_ENTITY_KYB_HEADINGS,
  SOURCE_OF_FUNDS_KEY,
} from 'modules/onboarding/constants/constants';
import KybDetails from 'components/KybDetails/KybDetails';
import Loader from 'components/Loader/Loader';
import SuccessPrompt from 'components/SuccessPrompt/SuccessPrompt';

const steps = [
  { label: '', content: <CreateCorporate /> },
  { label: '', content: <UploadDocument /> },
  { label: '', content: <VerifyCorporate /> },
  { label: '', content: <CreateAccount /> },
  { label: '', content: <CreateParticipant /> },
  { label: '', content: <VerifyParticipant /> },
  { label: '', content: <CreateConsent /> },
  { label: '', content: <ConfirmAccount /> },
];

const partnerCode = ATOMIC;

export const AtomicOnboardingContext = createContext();

function TreasuryOnboarding() {
  const location = useLocation();
  const toast = useToast();
  const [kyb, setkyb] = useState(null);
  const [kybKeysValues, setKybKeysValues] = useState({});
  const [sourceOfFunds, setSourceOfFunds] = useState('');
  const [entityType, setEntityType] = useState('');
  const [industry, setIndustry] = useState('');
  const [isInitialStepLoading, setIsInitialStepLoading] = useState(false);
  const [error, setError] = useState(null);
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const { merchantId, entityId, kybId } = location.state || {};
  const headingsValues = [merchantId, entityId, kybId];
  const hasCompletedAllSteps = activeStep === steps.length;

  const showToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      variant: 'left-accent',
      status: status,
      position: 'top',
      duration: 20000,
      isClosable: true,
    });
  };

  const showError = (title, error) => {
    if (error.response) {
      const errorData = error.response.data;

      showToast(title, errorData['error']['message'], 'error');
    } else {
      showToast(title, error.message, 'error');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getKybById(kybId);
        const kyb = response?.data;

        const kybDetails = kyb?.data?.details || [];
        const extractedKeysValues = {};

        kybDetails.forEach((detail) => {
          const { key, value } = detail;

          const validateKeys = [SOURCE_OF_FUNDS_KEY, BUSINESS_TYPE_KEY, INDUSTRY_TYPE_KEY];

          if (validateKeys.includes(key)) {
            extractedKeysValues[key] = value;
          }
        });

        setkyb(kyb);
        setKybKeysValues(extractedKeysValues);
      } catch (error) {
        showError('Error getting KYB by ID.', error);
      }
    })();
  }, []);

  useEffect(() => {
    getLastExecutedAtomicStep();
  }, []);

  const getLastExecutedAtomicStep = async () => {
    setIsInitialStepLoading(true);
    try {
      const response = await getLastExecutedStep(kybId, partnerCode);
      const lastExecutedStep = response?.data?.data?.resource_type;

      setStep(lastExecutedStep ? ATOMIC_ONBOARDING_STEPS[lastExecutedStep] + 1 : 0);
    } catch (error) {
      setError(error);
    }
    setIsInitialStepLoading(false);
  };

  return (
    <Flex mt='30px' direction='column' gap={4}>
      <Heading as='h2'>Onboard on Atomic</Heading>
      <KybDetails headings={MERCHANT_ENTITY_KYB_HEADINGS} headingsValues={headingsValues} />

      {!isInitialStepLoading && !error && (
        <AtomicOnboardingContext.Provider
          value={{
            merchantId,
            entityId,
            kybId,
            kyb,
            kybKeysValues,
            partnerCode,
            sourceOfFunds,
            entityType,
            industry,
            activeStep,
            setSourceOfFunds,
            setEntityType,
            setIndustry,
            nextStep,
            prevStep,
            showToast,
            showError,
          }}
        >
          <Steps activeStep={activeStep} mt={8}>
            {steps.map(({ label, content }, index) => (
              <Step label={label} key={index}>
                <Box mt={8}>{content}</Box>
              </Step>
            ))}
          </Steps>
        </AtomicOnboardingContext.Provider>
      )}

      {isInitialStepLoading && <Loader />}
      {error && <Box>Error fetching correct onboarding step. Please try again later.</Box>}

      {hasCompletedAllSteps && (
        <Box mt={8}>
          <SuccessPrompt
            description='KYB submitted successfully.'
            url={KYB_SUBMISSION_ROUTE}
            buttonText='Submit another KYB'
          />
        </Box>
      )}
    </Flex>
  );
}

export default TreasuryOnboarding;
