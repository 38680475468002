import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { POLICIES_ROUTE } from 'constants/routes';
import { APPROVAL_POLICY_FREQUENCY_CURRENCY, APPROVAL_POLICY_FREQUENCY_OPTIONS } from 'modules/policies/constants/constants';
import { PolicyContext } from 'modules/policies/CreatePolicy';

const AddPolicyDetails = () => {
  const {
    policyName,
    setPolicyName,
    amount,
    setAmount,
    frequency,
    setFrequency,
    goToNextStep,
    currentStep,
  } = useContext(PolicyContext);

  const handleNext = () => {
    goToNextStep();
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    if (value === '' || parseFloat(value) >= 0) {
      setAmount(value);
    }
  };

  const isNextDisabled = !policyName?.trim() || !amount?.trim();

  return (
    <Box maxWidth='600px' margin='auto' p={5}>
      <VStack spacing={6} align='stretch'>
        <Text fontWeight='bold' fontSize='xs' color='gray'>
          STEP {currentStep} / 2
        </Text>
        <Heading size='md'>Create Approval Policy</Heading>

        <VStack spacing={4} align='stretch'>
          <Text fontSize='sm'>Policy name</Text>
          <Input bg='gray.100' value={policyName} onChange={(e) => setPolicyName(e.target.value)} />

          <Text fontSize='sm'>For amount equal or higher than</Text>
          <InputGroup>
            <InputLeftAddon>{APPROVAL_POLICY_FREQUENCY_CURRENCY.USD}</InputLeftAddon>
            <Input type='number' value={amount} min='0' onChange={handleAmountChange} />
          </InputGroup>

          <Select value={frequency} onChange={(e) => setFrequency(e.target.value)}>
            {APPROVAL_POLICY_FREQUENCY_OPTIONS?.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </Select>
        </VStack>

        <HStack spacing={2} justify='flex-end'>
          <Link to={POLICIES_ROUTE}>
            <Button variant='outline'>Cancel</Button>
          </Link>
          <Button colorScheme='blue' onClick={handleNext} isDisabled={isNextDisabled}>
            Select Approvers
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AddPolicyDetails;
