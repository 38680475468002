import { instance, publicApiInstance } from 'api/internaltools';

export const getInternalUser = async () => {
  return instance.get('/internal-user');
};

export const getUserPermissions = async () => {
  return instance.get('/users/permissions');
};

export const getInternalUsers = async () => {
  return instance.get('/internal-users');
};

export const getMerchantRoles = async () => {
  return publicApiInstance.get('/v1/merchants/roles');
};

export const updateInternalUserRole = async (userId, payload) => {
  return instance.patch(`/users/${userId}/roles`, payload);
};
