import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { createParticipantReview, patchParticipantOnAtomic, verifyParticipantOnAtomic } from 'api/internaltools';
import { ATOMIC_PATCH_PARTICIPANT_DESCRIPTION } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import JsonViewer from 'components/JsonViewer/JsonViewer';
import Loader from 'components/Loader/Loader';

const PatchParticipant = () => {
  const { kybId, kyb, partnerCode, industry, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);
  const [patchParticipantRequest, setPatchParticipantRequest] = useState(null);
  const [originalPatchParticipantRequest, setOriginalPatchParticipantRequest] = useState(null);
  const [isPatchParticipantLoading, setIsPatchParticipantLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCreateParticipantReview();
  }, []);

  const handleJsonViewerModify = (edit) => {
    setPatchParticipantRequest(edit.updated_src);
  };

  const handleResetJson = () => {
    setPatchParticipantRequest(originalPatchParticipantRequest);
  };

  const getCreateParticipantReview = async () => {
    try {
      setIsLoading(true);
      const payload = {
        kybId,
        partnerCode,
        industry,
        kyb,
      };
      const response = await createParticipantReview(payload);
      const responseData = response?.data;

      setPatchParticipantRequest(responseData?.data);
      setOriginalPatchParticipantRequest(responseData?.data);
    } catch (error) {
      showError('Error getting Patch Participant Request.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyParticipant = async () => {
    const payload = {
      kybId,
      partnerCode,
    };

    try {
      await verifyParticipantOnAtomic(payload);
      showToast('Successfully verified participant on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error verifying participant on Atomic.', error);
    }
  };

  const handlePatchParticipant = async () => {
    setIsPatchParticipantLoading(true);
    const payload = {
      kybId,
      partnerCode,
      createAtomicParticipantRequest: patchParticipantRequest,
    };

    try {
      await patchParticipantOnAtomic(payload);
      showToast('Successfully patched participant on Atomic.', '', 'success');
      await handleVerifyParticipant();
    } catch (error) {
      showError('Error patching participant on Atomic.', error);
    }
    setIsPatchParticipantLoading(false);
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Patch Participant
      </Heading>
      <Text color='gray' mt={2} fontWeight='bold'>
        {ATOMIC_PATCH_PARTICIPANT_DESCRIPTION}
      </Text>
      {isLoading && <Loader />}
      {patchParticipantRequest && (
        <Box mt={4}>
          <JsonViewer
            jsonContent={patchParticipantRequest}
            onModify={handleJsonViewerModify}
            onReset={handleResetJson}
            height='50vh'
          />
          <Button colorScheme='blue' mt={4} onClick={handlePatchParticipant} isLoading={isPatchParticipantLoading}>
            Patch Participant
          </Button>
        </Box>
      )}
    </>
  );
};

export default PatchParticipant;
