import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';

export default function ActionButton({ buttonText, colorScheme, onClick }) {
  const [isLoading, setIsLoading] = useState(false);

  const onClickWrapper = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button size='xs' colorScheme={colorScheme} onClick={onClickWrapper} isLoading={isLoading}>
      {buttonText}
    </Button>
  );
}
