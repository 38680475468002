import React, { useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
} from '@chakra-ui/react';

export default function AddAccountInNewVaultModal({ currencyCode, onClose, onClick }) {
  const [isAccountInNewVaultLoading, setIsAccountInNewVaultLoading] = useState(false);
  const [isAccountInExistingVaultLoading, setIsAccountInExistingVaultLoading] = useState(false);

  const handleAddAccountClick = async (inNewVault) => {
    if (inNewVault) {
      setIsAccountInNewVaultLoading(true);
      await onClick(currencyCode, null, inNewVault);
      setIsAccountInNewVaultLoading(false);
      onClose();

      return;
    }

    setIsAccountInExistingVaultLoading(true);
    await onClick(currencyCode, null, inNewVault);
    setIsAccountInExistingVaultLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='center' gap={2}>
            Create <Tag colorScheme='blue'>{currencyCode}</Tag> Account
          </Flex>{' '}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Do you want to create the {currencyCode} account in a new Fireblock&apos;s vault?</p>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button isLoading={isAccountInExistingVaultLoading} onClick={() => handleAddAccountClick(false)} size='sm'>
              Create in existing vault
            </Button>
            <Button
              isLoading={isAccountInNewVaultLoading}
              colorScheme='blue'
              mr={3}
              onClick={() => handleAddAccountClick(true)}
              size='sm'
            >
              Create in new vault
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
