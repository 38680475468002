import React, { useEffect, useState } from 'react';
import { Box, GridItem, Heading, Text } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Loader from 'components/Loader/Loader';

export default function MerchantBankingDetails({ merchantId }) {
  const [bankingDetails, setBankingDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isPayoutsDisabled = !bankingDetails && !isLoading && !error;
  const backgroundColor = isPayoutsDisabled ? '#DCDCDC' : '';

  useEffect(() => {
    const getMerchantBankingDetails = async () => {
      try {
        setIsLoading(true);
        const response = await internalToolsApi.getMerchantBankingDetails(merchantId);

        setBankingDetails(response?.data?.data);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    getMerchantBankingDetails();
  }, []);

  return (
    <>
      <GridItem w='100%' style={{ display: 'flex', flexDirection: 'column' }}>
        <Heading as='h1' size='md' mb={3}>
          Banking
        </Heading>
        <Card
          minH='300px'
          maxH='400px'
          overflow='auto'
          style={{ borderWidth: '2px', flex: 1, backgroundColor: `${backgroundColor}` }}
        >
          <CardBody display='flex' flexDirection='column' justifyContent='space-between' style={{ height: '100%' }}>
            {bankingDetails && (
              <Box>
                <Text color='gray.500'>{bankingDetails?.banking?.total_balance_header_text}</Text>
                <Heading as='h1' size='lg'>
                  {bankingDetails?.banking?.total_balance}
                </Heading>
                <Text mt={4}>{bankingDetails?.banking?.balance_across_accounts_text}</Text>
              </Box>
            )}

            {isPayoutsDisabled && <Text color='gray'>Banking is not enabled for this merchant.</Text>}

            {isLoading && <Loader />}
            {error && <AlertMessage errorTitle='' errorMessage='Error fetching banking details for this merchant.' />}
          </CardBody>
        </Card>
      </GridItem>
    </>
  );
}
