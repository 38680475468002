import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { createAccountOnAtomic, createAccountReview } from 'api/internaltools';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import JsonViewer from 'components/JsonViewer/JsonViewer';
import Loader from 'components/Loader/Loader';

const CreateAccount = () => {
  const { kybId, partnerCode, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);
  const [createAccountRequest, setCreateAccountRequest] = useState(null);
  const [originalCreateAccountRequest, setOriginalCreateAccountRequest] = useState(null);
  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCreateAccountReview();
  }, []);

  const getCreateAccountReview = async () => {
    try {
      setIsLoading(true);
      const payload = {
        kybId,
        partnerCode,
      };
      const response = await createAccountReview(payload);
      const responseData = response?.data;

      setCreateAccountRequest(responseData?.data);
      setOriginalCreateAccountRequest(responseData?.data);
    } catch (error) {
      showError('Error getting Create Account Request.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJsonViewerModify = (edit) => {
    setCreateAccountRequest(edit.updated_src);
  };

  const handleResetJson = () => {
    setCreateAccountRequest(originalCreateAccountRequest);
  };

  const handleCreateAccount = async () => {
    setIsCreateAccountLoading(true);
    const payload = {
      kybId,
      partnerCode,
      createAtomicCorporateAccountRequest: createAccountRequest,
    };

    try {
      await createAccountOnAtomic(payload);
      showToast('Successfully created account on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error creating account on Atomic.', error);
    }
    setIsCreateAccountLoading(false);
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Create Account
      </Heading>
      {isLoading && <Loader />}
      {createAccountRequest && (
        <Box mt={4}>
          <JsonViewer
            jsonContent={createAccountRequest}
            onModify={handleJsonViewerModify}
            onReset={handleResetJson}
            height='20vh'
          />
          <Button colorScheme='blue' mt={4} onClick={handleCreateAccount} isLoading={isCreateAccountLoading}>
            Create Account
          </Button>
        </Box>
      )}
    </>
  );
};

export default CreateAccount;
