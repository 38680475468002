import React, { useContext, useState } from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import { confirmAccountOnAtomic } from 'api/internaltools';
import { ATOMIC_CONFIRM_ACCOUNT_DESCRIPTION } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';

const ConfirmAccount = () => {
  const { kybId, kyb, partnerCode, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);

  const [isConfirmAccountLoading, setIsConfirmAccountLoading] = useState(false);

  const handleConfirmAccount = async () => {
    setIsConfirmAccountLoading(true);
    const payload = {
      kybId,
      partnerCode,
    };

    try {
      await confirmAccountOnAtomic(payload);
      showToast('Successfully confirmed on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error confirming account on Atomic.', error);
    } finally {
      setIsConfirmAccountLoading(false);
    }
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Confirm Account
      </Heading>
      <Text color='gray' mt={2}>
        {ATOMIC_CONFIRM_ACCOUNT_DESCRIPTION}
      </Text>
      <Button
        colorScheme='blue'
        mt={4}
        onClick={handleConfirmAccount}
        isLoading={isConfirmAccountLoading}
        isDisabled={kyb == null}
      >
        Confirm Account
      </Button>
    </>
  );
};

export default ConfirmAccount;
