import React from 'react';
import { Skeleton,Stack } from '@chakra-ui/react';
import AlertMessage from 'components/AlertMessage/AlertMessage';

const SkeletonErrorWrapper = ({ isLoading, errorTitle, error, children }) => {
  if (isLoading) {
    return (
      <Stack>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    );
  } else if (error) {
    return (
      <AlertMessage errorTitle={errorTitle} errorMessage={error?.response?.data?.error?.message ?? error?.message} />
    );
  }

  return children;
};

export default SkeletonErrorWrapper;
