import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button, Flex, Text } from '@chakra-ui/react';

export default function AddAccountButton({ currencyCode, onClick }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleAddAccountClick = async () => {
    setIsLoading(true);
    await onClick(currencyCode);
    setIsLoading(false);
  };

  return (
    <Flex key={currencyCode} align='center' justify='space-between' mt={2}>
      <Text>{currencyCode}</Text>
      <Button
        colorScheme='gray'
        size='sm'
        variant='outline'
        leftIcon={<AiOutlinePlus />}
        isLoading={isLoading}
        onClick={handleAddAccountClick}
      >
        Add account
      </Button>
    </Flex>
  );
}
