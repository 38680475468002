import React, { useState } from 'react';
import { GoGraph } from 'react-icons/go';
import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { SMB_BANKING_DAILY_METRICS_LINK, SMB_BANKING_WEEKLY_METRICS_LINK } from 'components/MetricsButton/constants';

export default function MetricsButton() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Box position='fixed' bottom='30px' right='30px' zIndex='999'>
      <Menu>
        <MenuButton
          as={Button}
          aria-label='Metrics Options'
          leftIcon={<GoGraph />}
          backgroundColor='black'
          color='white'
          _hover={{ bg: 'gray.700' }}
          onClick={handleToggle}
        >
          SMB Banking Metrics
        </MenuButton>

        <MenuList>
          <MenuItem onClick={() => handleItemClick(SMB_BANKING_DAILY_METRICS_LINK)}>SMB Banking Daily</MenuItem>
          <MenuItem onClick={() => handleItemClick(SMB_BANKING_WEEKLY_METRICS_LINK)}>SMB Banking Weekly</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
