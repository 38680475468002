import React from 'react';
import { Box, Button, Heading, Link } from '@chakra-ui/react';

export default function SuccessPrompt({ description, url, buttonText }) {
  return (
    <Box textAlign={'center'}>
      <Heading>{description}</Heading>
      <Link href={url}>
        <Button mt={4}>{buttonText}</Button>
      </Link>
    </Box>
  );
}
