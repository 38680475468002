import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { WarningIcon } from '@chakra-ui/icons'; // Import the icon you want to use
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useAuthentication } from 'AuthContext';
import { LOGIN } from 'constants/routes';

function PrivateRoute({ component: Component, requiredPermission, ...rest }) {
  const { isAuthenticated, userPermissions, isUserAuthStatusLoading } = useAuthentication();
  const hasPermission = isAuthenticated && (requiredPermission == '' || userPermissions?.has(requiredPermission));

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthStatusLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner size='xl' thickness='5px' speed='0.8s' />
          </div>
        ) : !isAuthenticated ? (
          <Redirect to={LOGIN} />
        ) : hasPermission ? (
          <Component {...props} />
        ) : (
          <Box textAlign='center' py='2rem'>
            <WarningIcon boxSize='10' mb='1rem' color='red.500' />
            <Text fontSize='lg'>You don&apos;t have the necessary permissions to access this page.</Text>
          </Box>
        )
      }
    />
  );
}

export default PrivateRoute;
