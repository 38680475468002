import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { INTERNAL_DASHBOARD_TITLE } from 'constants/constants';
import { ROMA_LOGO_PATH } from 'constants/routes';
import SidebarContent from 'components/Sidebar/SidebarContent';

function Sidebar(props) {
  const mainPanel = React.useRef();
  let variantChange = '0.2s linear';

  const { routes, sidebarVariant } = props;

  let sidebarBg = 'none';
  let sidebarRadius = '0px';
  let sidebarMargins = '0px';

  if (sidebarVariant === 'opaque') {
    sidebarBg = useColorModeValue('white', 'gray.700');
    sidebarRadius = '16px';
    sidebarMargins = '16px 0px 16px 16px';
  }

  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: 'none', xl: 'block' }} position='fixed'>
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w='260px'
          maxW='260px'
          h='calc(100vh)'
          ps='10px'
          pe='10px'
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <SidebarContent
            routes={routes}
            logo={ROMA_LOGO_PATH}
            logoText={INTERNAL_DASHBOARD_TITLE}
            display='none'
            sidebarVariant={sidebarVariant}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
