import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getEntitiesAndCurrencies } from 'api/internaltools';
import * as internalToolsApi from 'api/internaltools';
import { CURRENCY_TYPE_DIGITAL_ASSET, CURRENCY_TYPE_FIAT } from 'modules/accounts/constants/constants';
import { showError, showToast } from 'utils/notifications';

const CreateNewAccountModal = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [merchantId, setMerchantId] = useState('');
  const [entitiesAndCurrencies, setEntitiesAndCurrencies] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Stores the account related details
  const [accountNickname, setAccountNickname] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [currencyType, setCurrencyType] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const isAllFieldsFilled =
    accountNickname.trim() !== '' && selectedCurrency !== '' && selectedEntity !== null && currencyType !== '';

  const handleCreateNewAccount = async () => {
    setIsLoading(true);

    try {
      const payload = {
        merchant_id: merchantId,
        entity_id: selectedEntity?.entity_id,
        currency_code: selectedCurrency,
        nickname: accountNickname,
      };

      await internalToolsApi.createNewAccount(payload);
      showToast(
        toast,
        'Success',
        `Successfully initiated ${selectedCurrency} account for entity ${selectedEntity?.entity_id}`,
        'success'
      );
    } catch (error) {
      showError(toast, `Error creating ${selectedCurrency} account for entity ${selectedEntity?.entity_id}.`, error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const fetchEntitiesAndCurrencies = async () => {
    try {
      setIsLoading(true);
      const response = await getEntitiesAndCurrencies(merchantId.trim());

      setEntitiesAndCurrencies(response?.data?.data ?? []);

      const defaultEntity = response?.data?.data?.entities?.[0] || null;

      if (defaultEntity) {
        setSelectedEntity(defaultEntity);
        if (defaultEntity.fiat_currencies) {
          setCurrencyType(CURRENCY_TYPE_FIAT);
          setSelectedCurrency(defaultEntity.fiat_currencies?.[0]?.id || '');
        } else if (defaultEntity.digital_assets) {
          setCurrencyType(CURRENCY_TYPE_DIGITAL_ASSET);
          setSelectedCurrency(defaultEntity.digital_assets?.[0]?.id || '');
        }
      }
    } catch (error) {
      showToast(toast, 'Unable to fetch merchant entities and currencies.', '', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClick = () => {
    fetchEntitiesAndCurrencies();
  };

  useEffect(() => {
    if (currencyType === CURRENCY_TYPE_FIAT) {
      setSelectedCurrency(selectedEntity?.fiat_currencies?.[0]?.id || '');
    } else if (currencyType === CURRENCY_TYPE_DIGITAL_ASSET) {
      setSelectedCurrency(selectedEntity?.digital_assets?.[0]?.id || '');
    }
  }, [currencyType, selectedEntity]);

  const handleMerchantIdChange = (e) => {
    setMerchantId(e.target.value);
  };

  const handleAccountNicknameChange = (e) => {
    setAccountNickname(e.target.value);
  };

  const handleEntityChange = (e) => {
    setSelectedEntity(entitiesAndCurrencies.entities.find((entity) => entity.entity_id === e.target.value));
  };

  const handleCurrencyTypeChange = (value) => {
    setCurrencyType(value);
  };

  const handleSelectedCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg' closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Account</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!entitiesAndCurrencies && (
            <FormControl isRequired>
              <FormLabel>Merchant ID</FormLabel>
              <Input type='text' placeholder='Enter Merchant ID' value={merchantId} onChange={handleMerchantIdChange} />
            </FormControl>
          )}
          {entitiesAndCurrencies && !entitiesAndCurrencies?.entities && (
            <Text>No entities/currencies found for this merchant.</Text>
          )}
          {entitiesAndCurrencies && entitiesAndCurrencies?.entities && (
            <Flex direction='column' gap={10}>
              <FormControl isRequired>
                <FormLabel>Account Nickname</FormLabel>
                <Input
                  type='text'
                  placeholder='eg: Credit Account'
                  value={accountNickname}
                  onChange={handleAccountNicknameChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Select Entity</FormLabel>
                <Select
                  placeholder='Select Entity'
                  value={selectedEntity?.entity_id || ''}
                  onChange={handleEntityChange}
                >
                  {entitiesAndCurrencies?.entities?.map((entity) => (
                    <option key={entity?.entity_id} value={entity?.entity_id}>
                      {entity?.entity_name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Currency Type</FormLabel>
                <RadioGroup value={currencyType} onChange={handleCurrencyTypeChange}>
                  <Stack direction='row' spacing={4}>
                    <Radio value={CURRENCY_TYPE_FIAT} isDisabled={!selectedEntity?.fiat_currencies}>
                      {CURRENCY_TYPE_FIAT}
                    </Radio>
                    <Radio value={CURRENCY_TYPE_DIGITAL_ASSET} isDisabled={!selectedEntity?.digital_assets}>
                      {CURRENCY_TYPE_DIGITAL_ASSET}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Select Currency</FormLabel>
                <Select placeholder='Select Currency' value={selectedCurrency} onChange={handleSelectedCurrencyChange}>
                  {currencyType === CURRENCY_TYPE_FIAT &&
                    selectedEntity?.fiat_currencies?.map((currency) => (
                      <option key={currency?.id} value={currency?.id}>
                        {currency?.value}
                      </option>
                    ))}

                  {currencyType === CURRENCY_TYPE_DIGITAL_ASSET &&
                    selectedEntity?.digital_assets?.map((currency) => (
                      <option key={currency?.id} value={currency?.id}>
                        {currency?.value}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          {!entitiesAndCurrencies ? (
            <Button colorScheme='blue' onClick={handleNextClick} isDisabled={!merchantId.trim()} isLoading={isLoading}>
              Continue
            </Button>
          ) : (
            <Button
              colorScheme='blue'
              onClick={handleCreateNewAccount}
              isDisabled={!isAllFieldsFilled}
              isLoading={isLoading}
            >
              Create Account
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateNewAccountModal;
