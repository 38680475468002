import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, IconButton, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { CORRIDORS } from 'constants/merchantDetails';
import {
  ACTIVE_CORRIDORS_TABLE_HEADINGS,
  ERROR_FETCHING_ACTIVE_CORRIDORS,
} from 'modules/corridors/constants/constants';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';
import CorridorRow from 'components/Tables/ActiveCorridorRow';

const Corridors = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [corridorsList, setCorridorsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const fetchCorridors = async () => {
    try {
      setIsLoading(true);
      const response = await internalToolsApi.getCorridors();

      setCorridorsList(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const filteredCorridorsList = useMemo(() => {
    if (!searchTerm) return corridorsList;

    return corridorsList.filter(
      (corridor) =>
        corridor?.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        corridor?.source_currency_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        corridor?.dest_currency_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        corridor?.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        corridor?.fees?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        corridor?.source_currency_display_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        corridor?.dest_currency_display_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [corridorsList, searchTerm]);

  useEffect(() => {
    fetchCorridors();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredCorridorsList]);

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const visibleCorridorsList = filteredCorridorsList.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredCorridorsList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPages = Math.ceil(filteredCorridorsList.length / limit);

  return (
    <Flex direction='column' pt='30px' gap={4}>
      <Search searchType={CORRIDORS} setSearchTerm={setSearchTerm} />
      <Card overflowX={{ sm: 'scroll' }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex align='center' justify='space-between'>
            <Text fontSize='xl' color={textColor} fontWeight='bold'>
              Active Corridors{' '}
              <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
                {filteredCorridorsList.length}
              </Badge>
            </Text>
          </Flex>
        </CardHeader>
        <LoadingErrorWrapper isLoading={isLoading} errorTitle={ERROR_FETCHING_ACTIVE_CORRIDORS} error={error}>
          <CardBody>
            <Table color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  {ACTIVE_CORRIDORS_TABLE_HEADINGS.map((heading, idx) => {
                    return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                        {heading}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {visibleCorridorsList.map((row) => {
                  return (
                    <CorridorRow
                      key={row?.id}
                      corridorId={row?.id}
                      sourceCurrency={row?.source_currency_code}
                      destCurrency={row?.dest_currency_code}
                      corridorType={row?.type}
                      corridorFees={row?.fees}
                      fetchCorridors={fetchCorridors}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </LoadingErrorWrapper>
      </Card>
      {filteredCorridorsList.length ? (
        <Flex justify='space-between' align='center'>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredCorridorsList.length}
            />
          </Box>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Corridors;
