import React, { useEffect, useState } from 'react';
import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import EntityOnOffRampConfig from 'modules/merchantinfo/components/EntityOnOffRampConfig';
import ShowKybDetails from 'modules/merchantinfo/components/ShowKybDetails';
import { KYB_STATUS } from 'modules/merchantinfo/constants/constants';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function MerchantEntitiesKYBDetails({ merchantId }) {
  const [merchantEntitiesDetails, setMerchantEntitiesDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const entitiesDetails = merchantEntitiesDetails?.entity_details?.filter(
    (entity) => entity?.kyb_status === KYB_STATUS.INITIATED || entity?.kyb_status === KYB_STATUS.SUCCEEDED
  );

  const getMerchantEntitiesDetails = async () => {
    setIsLoading(true);
    try {
      const response = await internalToolsApi.getMerchantCompanyDetails(merchantId);

      setMerchantEntitiesDetails(response?.data?.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMerchantEntitiesDetails();
  }, []);

  return (
    <LoadingErrorWrapper isLoading={isLoading} error={error} errorTitle='Error fetching entities details.'>
      <Box mt={6}>
        {merchantEntitiesDetails && (
          <>
            <Heading as='h1' size='md' mt={4} mb={3}>
              Merchant
            </Heading>

            <Card overflow='auto' style={{ borderWidth: '1px' }}>
              <CardBody>{merchantEntitiesDetails?.merchant_name}</CardBody>
            </Card>

            <Heading as='h1' size='md' mt={8} mb={3}>
              Entities
            </Heading>

            <Tabs isFitted isLazy variant='enclosed-colored' size='lg'>
              <TabList>
                {entitiesDetails?.map((entity) => (
                  <Tab key={entity?.entity_id} fontWeight='semibold'>
                    {entity?.entity_name}
                  </Tab>
                ))}
              </TabList>

              <TabPanels>
                {entitiesDetails?.map((entity) => (
                  <TabPanel key={entity?.entity_id} p={4}>
                    <EntityOnOffRampConfig entity={entity} merchantId={merchantId} />
                    <ShowKybDetails entity={entity} merchantId={merchantId} />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>
    </LoadingErrorWrapper>
  );
}
