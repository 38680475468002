import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Heading, Input } from '@chakra-ui/react';
import { uploadDocumentOnAtomic } from 'api/internaltools';
import { DOCUMENT_TYPE_ARTICLE_OF_INCORPORATION } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import { convertFileToBase64 } from 'modules/onboarding/utils/utils';

const documentType = DOCUMENT_TYPE_ARTICLE_OF_INCORPORATION;

const UploadDocument = () => {
  const { kybId, kyb, partnerCode, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);
  const [issuingCountry, setIssuingCountry] = useState('');
  const [document, setDocument] = useState(null);
  const [isUploadDocumentLoading, setIsUploadDocumentLoading] = useState(false);
  const [isDocumentSelected, setIsDocumentSelected] = useState(false);

  const handleDocumentChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setDocument(selectedFile);
      setIsDocumentSelected(true);
    }
  };

  const handleUploadDocument = async () => {
    setIsUploadDocumentLoading(true);

    const base64Document = await convertFileToBase64(document);

    const payload = {
      kybId: kybId,
      partnerCode: partnerCode,
      documentName: document?.name,
      uploadDocumentOnAtomicRequest: {
        document: base64Document?.substring(base64Document.indexOf(',') + 1),
        metadata: {
          type: documentType,
          issuing_country: issuingCountry,
        },
      },
    };

    try {
      await uploadDocumentOnAtomic(payload);
      showToast('Successfully uploaded Article of Incorporation on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error uploading Article of Incorporation on Atomic.', error);
    } finally {
      setIsUploadDocumentLoading(false);
    }
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Upload Article of Incorporation
      </Heading>
      <Flex mt={4} direction='column' flexWrap='wrap' gap={4}>
        <Box>
          <input type='file' accept='application/pdf' onChange={handleDocumentChange} />
        </Box>

        <Box>
          <label>Issuing Country (2-digit code)</label>
          <Input value={issuingCountry} onChange={(e) => setIssuingCountry(e.target.value)} />
        </Box>

        <Box>
          <Button
            mt={4}
            colorScheme='blue'
            onClick={handleUploadDocument}
            isLoading={isUploadDocumentLoading}
            isDisabled={kyb == null || !isDocumentSelected}
          >
            Upload document to Atomic
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default UploadDocument;
