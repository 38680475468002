import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { getTransactionsRequests } from 'api/transactions';
import { TRANSACTION } from 'constants/transactionDetails';
import {
  SMB_TRANSACTION_STATUS_PENDING,
  SMB_TRANSACTION_TYPE_SINGLE_PAYOUT_OPS,
} from 'modules/approve-transactions-v2/constants/constants';
import TransactionRequestDetailsDrawer from 'modules/approve-transactions-v2/TransactionRequestDetailsDrawer';
import { TRANSACTION_FOR_APPROVAL_TABLE_HEADINGS, TRANSACTION_LIMIT } from 'modules/merchantinfo/constants/constants';
import { formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Loader from 'components/Loader/Loader';
import Search from 'components/SearchBox/Search';

export default function ApproveTransactionsV2() {
  const [pendingSmbTransactionRequests, setPendingSmbTransactionRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSmbTransactionRequestId, setSelectedSmbTransactionRequestId] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const filteredSmbTransactionRequestList = useMemo(() => {
    if (!searchTerm) return pendingSmbTransactionRequests;

    return pendingSmbTransactionRequests.filter(
      (smbTransaction) =>
        smbTransaction?.TransactionId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        smbTransaction?.SourceAmount?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        smbTransaction?.SourceCurrencyCode?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [pendingSmbTransactionRequests, searchTerm]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredSmbTransactionRequestList?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * TRANSACTION_LIMIT;
  const endIndex = startIndex + TRANSACTION_LIMIT;
  const visibleSmbTransactionRequestList = filteredSmbTransactionRequestList?.slice(startIndex, endIndex);

  const fetchPendingTransactionRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getTransactionsRequests(
        SMB_TRANSACTION_STATUS_PENDING,
        SMB_TRANSACTION_TYPE_SINGLE_PAYOUT_OPS
      );

      setPendingSmbTransactionRequests(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredSmbTransactionRequestList]);

  useEffect(() => {
    fetchPendingTransactionRequests();
  }, []);

  const totalPages = Math.ceil(filteredSmbTransactionRequestList.length / TRANSACTION_LIMIT);

  const handleTxnClick = (transactionId) => {
    setSelectedSmbTransactionRequestId(transactionId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedSmbTransactionRequestId('');
  };

  return (
    <Box mt={6} overflowX='auto' maxWidth='100%'>
      <Heading as='h1' size='md' mb={3}>
        Pending Payments
        <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
          {filteredSmbTransactionRequestList?.length}
        </Badge>
      </Heading>

      <Search searchType={TRANSACTION} setSearchTerm={setSearchTerm} />

      <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }} mt={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {TRANSACTION_FOR_APPROVAL_TABLE_HEADINGS.map((heading, idx) => {
                if (heading === 'Description') {
                  return null;
                }

                return <Th key={idx}>{heading}</Th>;
              })}
            </Tr>
          </Thead>
          <Tbody>
            {visibleSmbTransactionRequestList?.map((smbTransaction) => (
              <Tr
                key={smbTransaction?.Id}
                fontSize='sm'
                onClick={() => handleTxnClick(smbTransaction?.Id)}
                style={{ cursor: 'pointer' }}
                _hover={{ background: 'gray.100' }}
              >
                <Td>{formatTransactionTime(smbTransaction?.CreatedAt)}</Td>
                <Td color='gray'>{smbTransaction?.TransactionId ?? '-'}</Td>
                <Td fontWeight='bold'>{smbTransaction?.SourceAmount}</Td>
                <Td color='gray'>{smbTransaction?.SourceCurrencyCode ?? '-'}</Td>
                <Td fontWeight='bold'>debit</Td>
                <Td color='green' fontWeight='bold'>
                  Approve <ArrowForwardIcon />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {!pendingSmbTransactionRequests.length && !isLoading && !error && (
        <Center>
          <Text color='gray' mt={4}>
            No pending transaction requests found.
          </Text>
        </Center>
      )}

      {isLoading && <Loader />}
      {error && (
        <AlertMessage errorTitle='Error fetching pending transaction requests.' errorMessage={error?.message} />
      )}

      {filteredSmbTransactionRequestList?.length ? (
        <Flex justify='space-between' align='center' mt={4}>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredSmbTransactionRequestList?.length}
            />
          </Box>
        </Flex>
      ) : null}

      {isDrawerOpen && (
        <TransactionRequestDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={handleDrawerClose}
          smbTransactionRequestId={selectedSmbTransactionRequestId}
          refetchPendingTransactions={fetchPendingTransactionRequests}
        />
      )}
    </Box>
  );
}
