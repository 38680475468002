import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AddIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { deleteInternalMerchantPolicy, getInternalMerchantPolicies } from 'api/policy';
import { CREATE_POLICY_ROUTE } from 'constants/routes';
import PolicyDetailsDrawer from 'modules/policies/PolicyDetailsDrawer';
import { showError } from 'utils/notifications';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function Policies() {
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    fetchPolicies();
  }, []);

  const fetchPolicies = async () => {
    setIsLoading(true);
    try {
      const response = await getInternalMerchantPolicies();

      setPolicies(response?.data?.data);
    } catch (error) {
      setError(error);
      showError(toast, 'Error fetching policies', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePolicy = async (policyId) => {
    try {
      await deleteInternalMerchantPolicy(policyId);
      fetchPolicies();
    } catch (error) {
      showError(toast, 'Error deleting policy', error);
    }
  };

  const handlePolicyClick = (policy) => {
    setSelectedPolicy(policy);
    onOpen();
  };

  const getTransactionAmount = (policy) => {
    const { frequency, limits } = policy.policy_configurations;
    const frequencyType =
      frequency?.frequency_type === 'daily'
        ? ' / day'
        : frequency?.frequency_type === 'quad_hourly'
        ? ' / 4 hours'
        : ' / transaction';

    return `$${Number(limits?.lower_limit).toLocaleString()}${frequencyType}`;
  };

  return (
    <Box p={5}>
      <Flex justifyContent='space-between' alignItems='center' mb={10}>
        <Heading size='md'>Payment Policies</Heading>
        <Button
          leftIcon={<AddIcon />}
          colorScheme='blue'
          variant='outline'
          onClick={() => history.push(CREATE_POLICY_ROUTE)}
        >
          Create custom policy
        </Button>
      </Flex>

      <LoadingErrorWrapper isLoading={isLoading} error={error} errorTitle='Error fetching policies.'>
        {policies?.length === 0 && <Box>No policies found.</Box>}
        {policies?.length !== 0 && (
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>POLICY NAME</Th>
                <Th>TRANSACTION AMOUNT</Th>
                <Th>APPROVAL STEPS</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {policies?.map((policy, policyIdx) => (
                <Tr key={policy?.id} onClick={() => handlePolicyClick(policy)} cursor='pointer'>
                  <Td fontWeight='bold'>{policyIdx + 1}</Td>
                  <Td>{policy?.name}</Td>
                  <Td>{getTransactionAmount(policy)}</Td>
                  <Td>{policy?.policy_configurations?.approval_flow?.levels?.length}-step Approval</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<SettingsIcon />}
                        variant='ghost'
                        onClick={(e) => e.stopPropagation()}
                      />
                      <MenuList>
                        <MenuItem
                          color='red'
                          icon={<DeleteIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeletePolicy(policy?.id);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </LoadingErrorWrapper>

      {isOpen && <PolicyDetailsDrawer isOpen={isOpen} onClose={onClose} selectedPolicy={selectedPolicy} />}
    </Box>
  );
}
