import { useEffect, useState } from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getTransactionJourneyConfig, updateTransactionJourneyStage } from 'api/internaltools';
import { showError, showToast } from 'utils/notifications';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function ModifyMerchantTransactionStageModal({ isOpen, onClose, transaction }) {
  const [selectedStage, setSelectedStage] = useState('');
  const [description, setDescription] = useState('');
  const [isMarkCompleted, setIsMarkCompleted] = useState(false);
  const [isModifyBtnDisabled, setIsModifyBtnDisabled] = useState(true);
  const [isModifyTransactionLoading, setIsModifyTransactionLoading] = useState(false);
  const [isTransactionStagesLoading, setIsTransactionStagesLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactionStages, setTransactionStages] = useState([]);
  const [completedStages, setCompletedStages] = useState([]);
  const [currentTransactionDesc, setCurrentTransactionDesc] = useState('');

  const toast = useToast();

  const fetchTransactionStages = async () => {
    setIsTransactionStagesLoading(true);
    try {
      const response = await getTransactionJourneyConfig(transaction?.id);
      const currentStage = response?.data?.data?.completed_stages;
      const stagesArr = response?.data?.data?.stages;
      const currentDesc = response?.data?.data?.description;

      setCompletedStages(currentStage);
      setTransactionStages(stagesArr);
      setCurrentTransactionDesc(currentDesc);

      if (stagesArr?.length) {
        setSelectedStage(stagesArr[0]);
      }
    } catch (error) {
      setError(error);
    }
    setIsTransactionStagesLoading(false);
  };

  useEffect(() => {
    fetchTransactionStages();
  }, []);

  useEffect(() => {
    setIsModifyBtnDisabled(!(selectedStage && (description || isMarkCompleted)));
  }, [selectedStage, description, isMarkCompleted]);

  const handleStageSelect = (stage) => {
    setSelectedStage(stage);
  };

  const handleTransactionStageModify = async () => {
    setIsModifyTransactionLoading(true);

    try {
      const payload = {};

      if (selectedStage) {
        payload.stage = selectedStage;
      }
      if (description) {
        payload.description = description;
      }
      if (isMarkCompleted) {
        payload.mark_completed = isMarkCompleted;
      }

      if (!Object.keys(payload).length) {
        onClose();

        return;
      }

      payload.payout_session_id = transaction?.id;
      payload.parent_id = transaction?.parent_id;

      await updateTransactionJourneyStage(payload);

      showToast(toast, 'Transaction stage modified successfully.');
    } catch (error) {
      showError(toast, 'Error modifying transaction stage.', error);
    }

    setIsModifyTransactionLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modify Transaction Stage</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} bg='gray.100' borderRadius='8px' p={3}>
            <Text mb={2} fontSize='sm'>
              Transaction ID: {transaction?.id}
            </Text>
            <Text fontWeight='bold' mb={2}>
              {transaction?.title} | Amt: {transaction?.amount} {transaction?.currency_code}
            </Text>
            <Text>
              Status:{' '}
              <Tag variant='solid' colorScheme='gray'>
                {transaction?.status}
              </Tag>
            </Text>
          </Box>
          <LoadingErrorWrapper
            isLoading={isTransactionStagesLoading}
            errorTitle='Error fetching transaction stages'
            error={error}
          >
            <Box mb={4}>
              <Box mb={2}>
                Current description:{' '}
                <Text fontWeight='bold' display='inline'>
                  {currentTransactionDesc}
                </Text>{' '}
              </Box>
              <Box mb={4}>
                {completedStages?.map((stage, idx) => (
                  <Box mb={1} key={idx}>
                    <CheckCircleIcon mr={2} color='green.500' />
                    {stage}
                  </Box>
                ))}
              </Box>
            </Box>
            {transactionStages?.length > 0 && (
              <Box mb={4}>
                <Box display='flex' flexDirection='column' height='30px'>
                  <Progress
                    flex='1'
                    colorScheme='green'
                    value={((transactionStages?.indexOf(selectedStage) + 1) / transactionStages?.length) * 100}
                  />
                  <Text fontSize='sm' color='gray'>
                    {selectedStage}
                  </Text>
                </Box>

                <Flex wrap='wrap' mt={3} gap={2} direction='column'>
                  <Text>Select stage to move the transaction to</Text>
                  {transactionStages?.map((stage, index) => (
                    <Button
                      size='sm'
                      key={index}
                      variant='outline'
                      colorScheme={selectedStage === stage ? 'blue' : 'gray'}
                      onClick={() => handleStageSelect(stage)}
                    >
                      {stage}
                    </Button>
                  ))}
                </Flex>
              </Box>
            )}
          </LoadingErrorWrapper>

          <Box mb={4}>
            <FormLabel htmlFor='description'>Description</FormLabel>
            <Input
              id='description'
              placeholder='Description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
          <Checkbox isChecked={isMarkCompleted} onChange={(e) => setIsMarkCompleted(e.target.checked)}>
            Mark as Completed
          </Checkbox>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleTransactionStageModify}
            isDisabled={isModifyBtnDisabled}
            isLoading={isModifyTransactionLoading}
          >
            Modify
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
