import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from '@chakra-ui/react';

export default function AddAccountWithPayinModal({ currencyCode, onClose, onClick }) {
  const [payinMarkup, setPayinMarkup] = useState({
    ACH: { ABS: '', BPS: '' },
    WIRE: { ABS: '', BPS: '' },
    SWIFT: { ABS: '', BPS: '' },
  });
  const [isAccountWithoutMarkupLoading, setIsAccountWithoutMarkupLoading] = useState(false);
  const [isAccountWithMarkupLoading, setIsAccountWithMarkupLoading] = useState(false);
  const [isCreateWithoutDepositInstructions, setIsCreateWithoutDepositInstructions] = useState(false);
  const [formError, setFormError] = useState('');

  const handleInputChange = (type, field, value) => {
    setPayinMarkup((prevFees) => ({
      ...prevFees,
      [type]: {
        ...prevFees[type],
        [field]: value,
      },
    }));
  };

  const validateForm = () => {
    for (const type in payinMarkup) {
      for (const field in payinMarkup[type]) {
        if (!payinMarkup[type][field]) {
          return false;
        }
      }
    }

    return true;
  };

  const convertPayinMarkupToNumbers = () => {
    const payinMarkupsAsNumbers = {
      ACH: {
        ABS: parseFloat(payinMarkup.ACH.ABS),
        BPS: parseFloat(payinMarkup.ACH.BPS),
      },
      WIRE: {
        ABS: parseFloat(payinMarkup.WIRE.ABS),
        BPS: parseFloat(payinMarkup.WIRE.BPS),
      },
      SWIFT: {
        ABS: parseFloat(payinMarkup.SWIFT.ABS),
        BPS: parseFloat(payinMarkup.SWIFT.BPS),
      },
    };

    return payinMarkupsAsNumbers;
  };

  const handleAddAccountClick = async (isPayinMarkup) => {
    if (!isPayinMarkup) {
      setIsAccountWithoutMarkupLoading(true);
      await onClick(currencyCode, null, isCreateWithoutDepositInstructions);
      setIsAccountWithoutMarkupLoading(false);
      onClose();

      return;
    }

    if (!validateForm()) {
      setFormError('* Please fill in all fields.');

      return;
    }

    const payinMarkupAsNumbers = convertPayinMarkupToNumbers();

    setIsAccountWithMarkupLoading(true);
    await onClick(currencyCode, payinMarkupAsNumbers, isCreateWithoutDepositInstructions);
    setIsAccountWithMarkupLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='center' gap={2}>
            Add Payin Markups for <Tag colorScheme='blue'>{currencyCode}</Tag>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='gray' fontSize='sm' mb={4}>
            Please enter the markup for <b>LAYER2 Fiat</b> currencies only.
          </Text>
          {formError && (
            <Text color='red' fontSize='sm' mb={4}>
              {formError}
            </Text>
          )}
          {Object.keys(payinMarkup).map((type, idx) => (
            <Box mb={8} key={idx}>
              <Heading size='sm' mb={2}>
                <Tag>{type}</Tag>
              </Heading>
              <HStack key={type} spacing={3}>
                <FormControl>
                  <FormLabel>ABS</FormLabel>
                  <Input
                    type='number'
                    value={payinMarkup[type].ABS}
                    onChange={(e) => handleInputChange(type, 'ABS', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>BPS</FormLabel>
                  <Input
                    type='number'
                    value={payinMarkup[type].BPS}
                    onChange={(e) => handleInputChange(type, 'BPS', e.target.value)}
                  />
                </FormControl>
              </HStack>
            </Box>
          ))}

          {currencyCode === 'USD' && (
            <Checkbox
              isChecked={isCreateWithoutDepositInstructions}
              onChange={(e) => setIsCreateWithoutDepositInstructions(e.target.checked)}
              mb={4}
              fontWeight='bold'
            >
              Deposit instructions will be pending
            </Checkbox>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button size='sm' onClick={() => handleAddAccountClick(false)} isLoading={isAccountWithoutMarkupLoading}>
              Add account w/o markups
            </Button>
            <Button
              size='sm'
              colorScheme='blue'
              onClick={() => handleAddAccountClick(true)}
              isLoading={isAccountWithMarkupLoading}
            >
              Add account with markups
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
