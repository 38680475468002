import React, { useEffect, useState } from 'react';
import { HiArrowRight, HiTrash } from 'react-icons/hi';
import { IoIosArrowRoundForward } from 'react-icons/io';
import Select from 'react-select';
import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Input,
  Tag,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { createCorridorTemplateMappings, getAvailablePayoutTemplates } from 'api/templates';
import { showError, showToast } from 'utils/notifications';

const CreatePayoutTemplateDrawer = ({
  isOpen,
  onClose,
  corridorId,
  sourceCurrency,
  destinationCurrency,
  openCorridorPricingDialog,
  setBypassCorridorTemplateMapping,
}) => {
  const toast = useToast();
  const [payoutTemplates, setPayoutTemplates] = useState([
    { payoutTemplate: '', seqNo: '', hasTerminalStep: true, isExisting: false, existingTemplateId: '' },
  ]);
  const [existingTemplates, setExistingTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const fetchAvailableCorridors = async () => {
    try {
      const response = await getAvailablePayoutTemplates();

      setExistingTemplates(response?.data?.data);
    } catch (error) {
      showError(toast, 'Error fetching existing payout templates.', error);
    }
  };

  useEffect(() => {
    fetchAvailableCorridors();
  }, []);

  useEffect(() => {
    const hasFilledBlock = payoutTemplates?.every(
      (template) => template?.payoutTemplate?.trim() !== '' && template?.seqNo?.trim() !== ''
    );
    const hasTerminalStepChecked = payoutTemplates?.some((template) => template?.hasTerminalStep);

    setIsSubmitDisabled(!(hasFilledBlock && hasTerminalStepChecked));
  }, [payoutTemplates]);

  const handleAddPayoutTemplate = () => {
    setPayoutTemplates([
      ...payoutTemplates,
      { payoutTemplate: '', seqNo: '', hasTerminalStep: false, isExisting: false, existingTemplateId: '' },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newPayoutTemplates = [...payoutTemplates];

    newPayoutTemplates[index][field] = value;
    setPayoutTemplates(newPayoutTemplates);
  };

  const handleSeqNoChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      handleInputChange(index, 'seqNo', value);
    }
  };

  const handleCheckboxChange = (index) => {
    const newPayoutTemplates = payoutTemplates?.map((template, i) => ({
      ...template,
      hasTerminalStep: i === index,
    }));

    setPayoutTemplates(newPayoutTemplates);
  };

  const handleDeletePayoutTemplate = (index) => {
    const newPayoutTemplates = payoutTemplates?.filter((_, i) => i !== index);

    setPayoutTemplates(newPayoutTemplates);
  };

  const handleTemplateSelect = (index, selectedOption) => {
    const newPayoutTemplates = [...payoutTemplates];

    newPayoutTemplates[index].isExisting = !!selectedOption;
    newPayoutTemplates[index].existingTemplateId = selectedOption ? selectedOption.value : '';
    newPayoutTemplates[index].payoutTemplate = selectedOption
      ? JSON.stringify(existingTemplates?.find((template) => template?.id === selectedOption?.value)?.template)
      : '';
    setPayoutTemplates(newPayoutTemplates);
  };

  const formatTemplateSteps = (template) => {
    return template
      .map(
        (step) =>
          `${step?.action} ${step?.from_asset} (${step?.from_partner}) to ${step?.to_asset} (${step?.to_partner})`
      )
      .join(' ➡️ ');
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const payload = {
        corridor_id: corridorId,
        payout_templates: payoutTemplates?.map((template) => ({
          payout_template: template?.payoutTemplate,
          seq_no: parseInt(template?.seqNo, 10),
          terminal_step: template?.hasTerminalStep ? JSON.parse(template?.payoutTemplate)?.length - 1 : -1,
          is_payout_template_exists: template?.isExisting,
          payout_template_id: template?.isExisting ? template?.existingTemplateId : '',
        })),
      };

      await createCorridorTemplateMappings(payload);

      showToast(toast, 'Success', 'Payout templates created successfully. You can now enable the corridor.', 'success');
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      showError(toast, 'Error creating payout templates.', error);
    }
  };

  const handleSkipCorridorTemplateMappingCreation = () => {
    openCorridorPricingDialog(corridorId, sourceCurrency, destinationCurrency);
    setBypassCorridorTemplateMapping(true);
    onClose();
  };

  const existingTemplateOptions = existingTemplates.map((template) => ({
    value: template.id,
    label: formatTemplateSteps(template.template),
  }));

  return (
    <>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='lg'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create Payout Templates</DrawerHeader>
          <Text px={6} mb={4} color='gray.600'>
            Please create payout template(s) for the selected corridor. Add multiple payout templates ONLY if parallel
            execution templates are required.
          </Text>
          <DrawerBody>
            <Flex gap={2} align='center' justify='center' mb={4}>
              <Tag size='sm' colorScheme='green'>
                {sourceCurrency}
              </Tag>
              <HiArrowRight />
              <Tag size='sm' colorScheme='blue'>
                {destinationCurrency}
              </Tag>
            </Flex>
            <VStack spacing={8}>
              {payoutTemplates.map((template, index) => (
                <VStack key={index} spacing={2} w='100%'>
                  <Select
                    placeholder='Select existing template'
                    value={existingTemplateOptions.find((option) => option?.value === template?.existingTemplateId)}
                    onChange={(selectedOption) => handleTemplateSelect(index, selectedOption)}
                    options={existingTemplateOptions}
                    styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
                    isClearable={true}
                  />
                  <HStack spacing={2} w='100%'>
                    <Textarea
                      placeholder='Payout Template'
                      value={template?.payoutTemplate}
                      onChange={(e) => handleInputChange(index, 'payoutTemplate', e.target.value)}
                      isDisabled={template?.isExisting}
                    />
                    {index > 0 && (
                      <IconButton
                        aria-label='Delete payout template'
                        icon={<HiTrash />}
                        color='red'
                        onClick={() => handleDeletePayoutTemplate(index)}
                        variant='ghost'
                      />
                    )}
                  </HStack>
                  <HStack spacing={2} w='100%'>
                    <Input
                      w='200px'
                      placeholder='Seq No'
                      value={template.seqNo}
                      onChange={(e) => handleSeqNoChange(index, e.target.value)}
                    />
                    <Checkbox isChecked={template?.hasTerminalStep} onChange={() => handleCheckboxChange(index)}>
                      Has Terminal Step
                    </Checkbox>
                  </HStack>
                </VStack>
              ))}
            </VStack>
            <Button
              onClick={handleSkipCorridorTemplateMappingCreation}
              mt={4}
              colorScheme='red'
              variant='link'
              size='lg'
              rightIcon={<IoIosArrowRoundForward />}
            >
              Bypass corridor template creation
            </Button>
          </DrawerBody>
          <DrawerFooter>
            <Button variant='ghost' onClick={handleAddPayoutTemplate} mr={2}>
              Add Another Payout Template
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit} isLoading={isLoading} isDisabled={isSubmitDisabled}>
              Create Payout Template(s)
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreatePayoutTemplateDrawer;
