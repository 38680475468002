import React, { useEffect, useState } from 'react';
import { GiJourney } from 'react-icons/gi';
import { GrUpdate } from 'react-icons/gr';
import { HiOutlineClock } from 'react-icons/hi2';
import { IoWarningOutline } from 'react-icons/io5';
import { MdOutlineFileDownload } from 'react-icons/md';
import { PiExcludeLight, PiIntersectLight } from 'react-icons/pi';
import { TbStairsUp } from 'react-icons/tb';
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { getMerchantTransactions, getTransactions } from 'api/transactions';
import { TRANSACTIONS } from 'constants/merchantDetails';
import CheckTxnStepLevelProgress from 'modules/merchantinfo/components/CheckTxnStepLevelProgress';
import ModifyMerchantTransactionStageModal from 'modules/merchantinfo/components/ModifyMerchantTransactionStageModal';
import ModifyMerchantTransactionStatusModal from 'modules/merchantinfo/components/ModifyMerchantTransactionStatusModal';
import {
  TRANSACTION_LIMIT,
  TRANSACTION_STATUS,
  TRANSACTION_TABLE_HEADINGS,
} from 'modules/merchantinfo/constants/constants';
import { formatTransactionStatus, formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import { downloadZipFile } from 'utils/utils';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Loader from 'components/Loader/Loader';
import Search from 'components/SearchBox/Search';

export default function MerchantTransactionsList({
  merchantId = '',
  title = '',
  fetchInitiatedTransactions = false,
  isFetchStuckTransactions = false,
  fetchSucceededTransactions = false,
  showModifyStatusCTA = false,
  showModifyJourneyCTA = false,
  showStepLevelProgressCTA = false,
}) {
  const [transactions, setTransactions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isModifyTxnStatusModalOpen, setIsModifyTxnStatusModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isMofidyTxnStageModalOpen, setIsModifyTxnStageModalOpen] = useState(false);
  const [isTxnCheckStepLevelProgressSidebarOpen, setIsTxnCheckStepLevelProgressSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [excludeTestTransactions, setExcludeTestTransactions] = useState(true);

  const showCTAs = showModifyStatusCTA || showModifyJourneyCTA || showStepLevelProgressCTA;

  const handleOpenModifyTxnStatusModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModifyTxnStatusModalOpen(true);
  };

  const handleModifyTxnStageModal = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModifyTxnStageModalOpen(true);
  };

  const handleDownloadAttachments = async (transaction) => {
    setIsDownloadLoading(true);
    await downloadZipFile(transaction?.documents_link, `attachments_${transaction?.id}`);
    setIsDownloadLoading(false);
  };

  const handleTxnCheckStepLevelProgressSidebar = (transaction) => {
    setSelectedTransaction(transaction);
    setIsTxnCheckStepLevelProgressSidebarOpen(true);
  };

  const updateTransactionInList = (updatedTransaction) => {
    setTransactions((currentTransactions) =>
      currentTransactions.map((transaction) =>
        transaction?.id === updatedTransaction?.id
          ? { ...transaction, status: updatedTransaction?.status }
          : transaction
      )
    );
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setIsLoading(true);
        let statuses = '';
        let response;

        if (fetchInitiatedTransactions) {
          statuses = 'initiated%2Cdelayed';
        }

        if (fetchSucceededTransactions) {
          statuses += statuses ? '%2Csucceeded' : 'succeeded';
        }

        if (merchantId) {
          response = await getMerchantTransactions(merchantId, TRANSACTION_LIMIT, page);
        } else {
          response = await getTransactions(TRANSACTION_LIMIT, page, statuses, searchTerm, excludeTestTransactions, isFetchStuckTransactions);
        }

        setTransactions(response?.data?.data?.transactions ?? []);
        setTotalCount(response?.data?.data?.meta_data?.total_count ?? 0);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchTransactions();
  }, [page, TRANSACTION_LIMIT, merchantId, searchTerm, excludeTestTransactions]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const toggleExcludeTestTransactions = () => {
    setExcludeTestTransactions((prev) => !prev);
    setPage(1);
  };

  const totalPages = Math.ceil(totalCount / TRANSACTION_LIMIT);
  const isPreviousDisabled = page === 1;
  const isNextDisabled = page === totalPages;

  return (
    <Box overflowX='auto' maxWidth='100%'>
      {!merchantId && (
        <Flex justify='space-between'>
          <Search searchType={TRANSACTIONS} setSearchTerm={handleSearch} searchOnChangeEvent={false} width='30%' />
          <Button
            leftIcon={excludeTestTransactions ? <PiIntersectLight /> : <PiExcludeLight />}
            variant={excludeTestTransactions ? 'ghost' : 'solid'}
            colorScheme='gray'
            onClick={toggleExcludeTestTransactions}
          >
            {excludeTestTransactions ? 'Include Test Transactions' : 'Exclude Test Transactions'}
          </Button>
        </Flex>
      )}
      <Heading as='h1' size='md' mb={3} mt={8}>
        {title}
      </Heading>

      {isLoading && <Loader />}

      <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }} mt={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {showCTAs && <Th>Actions</Th>}
              {TRANSACTION_TABLE_HEADINGS.map((heading, idx) => (
                <Th key={idx}>{heading}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((transaction) => (
              <Tr key={transaction?.id} fontSize='sm'>
                {showCTAs && (
                  <Td display='flex' flexDirection='column' gap={3}>
                    {showModifyStatusCTA && (
                      <Button
                        size='sm'
                        variant='link'
                        width='fit-content'
                        colorScheme='blue'
                        onClick={() => handleOpenModifyTxnStatusModal(transaction)}
                        leftIcon={<GrUpdate />}
                      >
                        Modify Status
                      </Button>
                    )}
                    {showModifyJourneyCTA && (
                      <Button
                        size='sm'
                        variant='link'
                        width='fit-content'
                        onClick={() => handleModifyTxnStageModal(transaction)}
                        leftIcon={<GiJourney size='15px' />}
                      >
                        Modify Journey
                      </Button>
                    )}
                    {showStepLevelProgressCTA && (
                      <Button
                        size='sm'
                        variant='link'
                        width='fit-content'
                        colorScheme='yellow'
                        onClick={() => handleTxnCheckStepLevelProgressSidebar(transaction)}
                        leftIcon={<TbStairsUp size='15px' />}
                      >
                        Check Step Level Progress
                      </Button>
                    )}
                    {transaction?.documents_link?.length > 0 && (
                      <Button
                        size='sm'
                        variant='outline'
                        width='fit-content'
                        onClick={() => handleDownloadAttachments(transaction)}
                        leftIcon={<MdOutlineFileDownload />}
                        isLoading={isDownloadLoading}
                      >
                        Download attachment(s)
                      </Button>
                    )}
                  </Td>
                )}
                <Td>{formatTransactionTime(transaction?.created_at)}</Td>
                <Td color='gray'>
                  {transaction?.id ?? '-'}
                  <Flex gap={1}>
                    {transaction?.ops_action_needed && (
                      <Tooltip label='Ops action needed'>
                        <span>
                          <IoWarningOutline color='red' size={20} />
                        </span>
                      </Tooltip>
                    )}
                    {transaction?.is_eta_breached && (
                      <Tooltip label='ETA breached'>
                        <span>
                          <HiOutlineClock color='orange' size={20} />
                        </span>
                      </Tooltip>
                    )}
                  </Flex>
                </Td>
                <Td>{transaction?.title ?? '-'}</Td>
                <Td fontWeight='bold'>
                  {transaction?.transfer_type === 'debit' ? '-' + transaction?.amount : transaction?.amount}
                </Td>
                <Td color='gray'>{transaction?.currency_code ?? '-'}</Td>
                <Td color='gray'>{transaction?.transaction_method ?? '-'}</Td>
                <Td fontWeight='bold'>{transaction?.transfer_type ?? '-'}</Td>
                <Td>
                  <Badge
                    colorScheme={transaction?.status === TRANSACTION_STATUS.SUCCEEDED ? 'green' : 'gray'}
                    rounded='0.8em'
                    px={3}
                  >
                    {formatTransactionStatus(transaction?.status)}
                  </Badge>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {isModifyTxnStatusModalOpen && selectedTransaction && (
        <ModifyMerchantTransactionStatusModal
          isOpen={isModifyTxnStatusModalOpen}
          onClose={() => setIsModifyTxnStatusModalOpen(false)}
          transaction={selectedTransaction}
          onUpdateTransaction={updateTransactionInList}
        />
      )}

      {isMofidyTxnStageModalOpen && selectedTransaction && (
        <ModifyMerchantTransactionStageModal
          isOpen={isMofidyTxnStageModalOpen}
          onClose={() => setIsModifyTxnStageModalOpen(false)}
          transaction={selectedTransaction}
        />
      )}

      {isTxnCheckStepLevelProgressSidebarOpen && selectedTransaction && (
        <CheckTxnStepLevelProgress
          isOpen={isTxnCheckStepLevelProgressSidebarOpen}
          onClose={() => setIsTxnCheckStepLevelProgressSidebarOpen(false)}
          transaction={selectedTransaction}
        />
      )}

      {!transactions.length && !isLoading && !error && (
        <Center>
          <Text color='gray' mt={4}>
            No transactions found.
          </Text>
        </Center>
      )}

      {error && <AlertMessage errorTitle='Error fetching transactions.' errorMessage={error?.message} />}

      {totalPages > 0 && (
        <Box mt={4} display='flex' justifyContent='space-between' alignItems='center'>
          <Text>
            Showing {Math.min((page - 1) * TRANSACTION_LIMIT + 1, totalCount)} to{' '}
            {Math.min(page * TRANSACTION_LIMIT, totalCount)} of {totalCount}
          </Text>
          <Box>
            <Button size='sm' mr={2} onClick={() => handlePageChange(page - 1)} isDisabled={isPreviousDisabled}>
              Previous
            </Button>
            <Button size='sm' onClick={() => handlePageChange(page + 1)} isDisabled={isNextDisabled}>
              Next
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
