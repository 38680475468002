import { instance } from 'api/internaltools';

export const getIsCorridorTemplateMappingAvailable = (id) => {
  return instance.get(`/corridor/${id}/corridor-template-mapping/validity`);
};

export const getAvailablePayoutTemplates = () => {
  return instance.get(`/payout-templates`);
};

export const createCorridorTemplateMappings = (payload) => {
  return instance.post('/corridor-template-mappings', payload);
}

export const getEntitiesAndCorridorTemplateMappings = (merchantId, corridorId) => {
  return instance.get(`/merchant/${merchantId}/corridor/${corridorId}/corridor-template-mappings`);
}

export const getAllCorridorsTemplateMappings = (merchantId, entityId) => {
  return instance.get(`/merchant/${merchantId}/entity/${entityId}/corridor-template-mappings`);
}

export const createEntityCorridorTemplateMappings = (payload) => {
  return instance.post('/entity-corridor-template-mappings', payload);
}