import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { getEntityConfigurations } from 'api/internaltools';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import {
  ENTITY_CONFIG_PARTNER_TYPE_CRYPTO_PAYOUTS,
  ENTITY_CONFIG_PARTNER_TYPE_FIAT_PAYOUTS,
  KYB_SERVICE_BANKING_ACCOUNT,
} from 'constants/constants';
import { POST_ONBOARDING_ROUTE } from 'constants/routes';
import AddCorridors from 'modules/postonboarding/components/banking/AddCorridors';
import AddFiatCryptoAccounts from 'modules/postonboarding/components/banking/AddFiatCryptoAccounts';
import MarkPostOnboardingSucceeded from 'modules/postonboarding/components/banking/MarkPostOnboardingSucceeded';
import SelectCorridorTemplates from 'modules/postonboarding/components/banking/SelectCorridorTemplates';
import SelectFiatCryptoPartners from 'modules/postonboarding/components/banking/SelectFiatCryptoPartners';
import { MERCHANT_ENTITY_KYB_HEADINGS } from 'modules/postonboarding/constants/constants';
import KybDetails from 'components/KybDetails/KybDetails';
import Loader from 'components/Loader/Loader';
import SuccessPrompt from 'components/SuccessPrompt/SuccessPrompt';

const steps = [
  { label: 'Select Partners', content: <SelectFiatCryptoPartners /> },
  { label: 'Select corridors templates', content: <SelectCorridorTemplates /> },
  { label: 'Add Accounts', content: <AddFiatCryptoAccounts /> },
  { label: 'Add Corridors', content: <AddCorridors /> },
  { label: 'Finish', content: <MarkPostOnboardingSucceeded /> },
];

export const PostOnboardingContext = createContext();

function TreasuryOnboarding() {
  const location = useLocation();

  const [fiatPartner, setFiatPartner] = useState('');
  const [cryptoPartner, setCryptoPartner] = useState('');
  const [fiatPartnerCustomerId, setFiatPartnerCustomerId] = useState('');
  const [fiatPartnerWalletId, setFiatPartnerWalletId] = useState('');
  const [cryptoPartnerCustomerId, setCryptoPartnerCustomerId] = useState('');
  const [isInitialStepLoading, setIsInitialStepLoading] = useState(false);
  const [error, setError] = useState(null);

  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const { merchantId, entityId, entityName, kybId } = location.state || {};
  const headingsValues = [merchantId, entityId];
  const hasCompletedAllSteps = activeStep === steps.length;

  useEffect(() => {
    fetchPostOnboardingStepToBeginWith();
  }, []);

  const fetchPostOnboardingStepToBeginWith = async () => {
    setIsInitialStepLoading(true);
    try {
      const response = await getEntityConfigurations(merchantId, entityId);
      const entityConfigurations = response?.data?.data;

      let fiatPartner = '';
      let newCryptoPartner = '';
      let isBankingStatusSucceeded = false;
      let fiatPartnerCustomerId = '';

      entityConfigurations.forEach((entityConfig) => {
        if (entityConfig?.PartnerType === ENTITY_CONFIG_PARTNER_TYPE_FIAT_PAYOUTS) {
          fiatPartner = entityConfig?.PartnerCode;
          fiatPartnerCustomerId = entityConfig?.CustomerId;
        } else if (entityConfig?.PartnerType === ENTITY_CONFIG_PARTNER_TYPE_CRYPTO_PAYOUTS) {
          newCryptoPartner = entityConfig?.PartnerCode;
        } else if (entityConfig?.KybCapability === KYB_SERVICE_BANKING_ACCOUNT) {
          isBankingStatusSucceeded = entityConfig?.KybStatus === 'succeeded';
        }
      });

      if (fiatPartner) {
        setFiatPartner(fiatPartner);
      }
      if (newCryptoPartner) {
        setCryptoPartner(newCryptoPartner);
      }

      if (fiatPartnerCustomerId) {
        setFiatPartnerCustomerId(fiatPartnerCustomerId);
      }

      if (isBankingStatusSucceeded) {
        setStep(steps.length);
      } else if (fiatPartner || newCryptoPartner) {
        setStep(1);
      }
    } catch (error) {
      setError(error);
    }
    setIsInitialStepLoading(false);
  };

  return (
    <Flex mt='30px' direction='column' gap={4}>
      <Heading>
        Post-Onboard{' '}
        <Text as='span' color='gray'>
          {entityName}
        </Text>
      </Heading>
      <KybDetails headings={MERCHANT_ENTITY_KYB_HEADINGS} headingsValues={headingsValues} />

      {!isInitialStepLoading && !error && (
        <PostOnboardingContext.Provider
          value={{
            merchantId,
            entityId,
            kybId,
            fiatPartner,
            cryptoPartner,
            fiatPartnerCustomerId,
            fiatPartnerWalletId,
            cryptoPartnerCustomerId,
            setFiatPartner,
            setCryptoPartner,
            setFiatPartnerCustomerId,
            setFiatPartnerWalletId,
            setCryptoPartnerCustomerId,
            activeStep,
            nextStep,
            prevStep,
          }}
        >
          <Steps activeStep={activeStep} mt={8}>
            {steps.map(({ label, content }, index) => (
              <Step label={label} key={index}>
                <Box mt={8}>{content}</Box>
              </Step>
            ))}
          </Steps>
        </PostOnboardingContext.Provider>
      )}

      {isInitialStepLoading && <Loader />}
      {error && <Box>Error fetching correct post onboarding step. Please try again later.</Box>}

      {hasCompletedAllSteps && (
        <Box mt={8}>
          <SuccessPrompt
            description='Post Onboarding completed successfully.'
            url={POST_ONBOARDING_ROUTE}
            buttonText='Post Onboard another entity'
          />
        </Box>
      )}
    </Flex>
  );
}

export default TreasuryOnboarding;
