import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { createInternalMerchantPolicy } from 'api/policy';
import { POLICIES_ROUTE } from 'constants/routes';
import AddApprovers from 'modules/policies/AddApprovers';
import AddPolicyDetails from 'modules/policies/AddPolicyDetails';
import {
  APPROVAL_POLICY_ACTIONS,
  APPROVAL_POLICY_FREQUENCIES,
  APPROVAL_POLICY_FREQUENCY_CURRENCY,
  APPROVAL_POLICY_TYPES,
  APPROVER_TYPES,
  FREQUENCY_TYPE_MAPPING,
  NEXT_OPERATOR,
  POLICY_CONFIGURATIONS_FIELDS,
} from 'modules/policies/constants/constants';
import { showError, showToast } from 'utils/notifications';

export const PolicyContext = createContext();

export default function CreatePolicy() {
  const [policyName, setPolicyName] = useState('');
  const [amount, setAmount] = useState('');
  const [frequency, setFrequency] = useState(APPROVAL_POLICY_FREQUENCIES.DAILY);

  const [approvalFlow, setApprovalFlow] = useState({
    levels: [
      {
        level: 1,
        sub_levels: [
          {
            sub_level: 1,
            quorum: POLICY_CONFIGURATIONS_FIELDS.QUORUM_ONE,
            approvers: [],
            next_operator: null,
          },
        ],
      },
    ],
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isPolicyCreationLoading, setIsPolicyCreationLoading] = useState(false);

  const history = useHistory();
  const toast = useToast();

  const updateApprover = (levelIndex, subLevelIndex, field, value) => {
    setApprovalFlow((prevFlow) => {
      const newFlow = JSON.parse(JSON.stringify(prevFlow));
      const subLevel = newFlow?.levels[levelIndex].sub_levels[subLevelIndex];

      if (field === POLICY_CONFIGURATIONS_FIELDS.QUORUM) {
        subLevel.quorum = value;
      } else if (field === POLICY_CONFIGURATIONS_FIELDS.APPROVERS) {
        subLevel.approvers = value?.map((approver) => ({
          type: approver?.type === APPROVER_TYPES.ROLE ? APPROVER_TYPES.ROLE : APPROVER_TYPES.ROMA_OPS_DASHBOARD_USER,
          id: approver?.id,
        }));
      }

      newFlow?.levels[levelIndex].sub_levels?.forEach((sl, index) => {
        sl.next_operator = index < newFlow?.levels[levelIndex].sub_levels?.length - 1 ? NEXT_OPERATOR?.OR : null;
      });

      return newFlow;
    });
  };

  const removeApproverSequence = (levelIndex, subLevelIndex) => {
    setApprovalFlow((prevFlow) => {
      const newFlow = JSON.parse(JSON.stringify(prevFlow));
      const level = newFlow?.levels[levelIndex];

      if (level?.sub_levels?.length > 1) {
        level?.sub_levels?.splice(subLevelIndex, 1);
        level?.sub_levels?.forEach((subLevel, index) => {
          subLevel.sub_level = index + 1;
          subLevel.next_operator = index < level?.sub_levels?.length - 1 ? NEXT_OPERATOR.OR : null;
        });
      }

      return newFlow;
    });
  };

  const addApproverSequence = (levelIndex) => {
    setApprovalFlow((prevFlow) => {
      const newFlow = JSON.parse(JSON.stringify(prevFlow));
      const level = newFlow?.levels[levelIndex];

      level?.sub_levels?.forEach((sl) => {
        sl.next_operator = NEXT_OPERATOR.OR;
      });

      level?.sub_levels?.push({
        sub_level: level?.sub_levels?.length + 1,
        quorum: POLICY_CONFIGURATIONS_FIELDS.QUORUM_ONE,
        approvers: [],
        next_operator: null,
      });

      return newFlow;
    });
  };

  const addStep = () => {
    setApprovalFlow((prevFlow) => {
      const newFlow = JSON.parse(JSON.stringify(prevFlow));

      newFlow?.levels?.push({
        level: newFlow?.levels?.length + 1,
        sub_levels: [
          {
            sub_level: 1,
            quorum: POLICY_CONFIGURATIONS_FIELDS.QUORUM_ONE,
            approvers: [],
            next_operator: null,
          },
        ],
      });

      return newFlow;
    });
  };

  const removeStep = (levelIndex) => {
    setApprovalFlow((prevFlow) => {
      const newFlow = JSON.parse(JSON.stringify(prevFlow));

      if (newFlow?.levels?.length > 1) {
        newFlow?.levels?.splice(levelIndex, 1);
        newFlow?.levels?.forEach((level, index) => {
          level.level = index + 1;
        });
      }

      return newFlow;
    });
  };

  const goToNextStep = () => setCurrentStep(currentStep + 1);
  const goToPreviousStep = () => setCurrentStep(currentStep - 1);

  const generatePayload = () => {
    return {
      name: policyName,
      type: APPROVAL_POLICY_TYPES.OPS_PAYOUT,
      policy_configurations: {
        limits: {
          lower_limit: amount,
          currency_code: APPROVAL_POLICY_FREQUENCY_CURRENCY.USD,
        },
        action: {
          action_type: APPROVAL_POLICY_ACTIONS.REQUEST_FOR_APPROVAL,
        },
        frequency: {
          frequency_type:
            frequency === APPROVAL_POLICY_FREQUENCIES.DAILY
              ? FREQUENCY_TYPE_MAPPING[APPROVAL_POLICY_FREQUENCIES.DAILY]
              : frequency === APPROVAL_POLICY_FREQUENCIES.QUAD_HOURLY
              ? FREQUENCY_TYPE_MAPPING[APPROVAL_POLICY_FREQUENCIES.QUAD_HOURLY]
              : FREQUENCY_TYPE_MAPPING[APPROVAL_POLICY_FREQUENCIES.PER_TRANSACTION],
        },
        approval_flow: approvalFlow,
      },
    };
  };

  const submitPolicy = async () => {
    setIsPolicyCreationLoading(true);
    const payload = generatePayload();

    try {
      await createInternalMerchantPolicy(payload);
      showToast(toast, 'Policy created successfully', '', 'success');
      history.push(POLICIES_ROUTE);
    } catch (error) {
      showError(toast, 'Error creating policy', error);
    } finally {
      setIsPolicyCreationLoading(false);
    }
  };

  return (
    <PolicyContext.Provider
      value={{
        policyName,
        setPolicyName,
        amount,
        setAmount,
        frequency,
        setFrequency,
        approvalFlow,
        updateApprover,
        removeApproverSequence,
        addApproverSequence,
        addStep,
        removeStep,
        currentStep,
        goToNextStep,
        goToPreviousStep,
        isPolicyCreationLoading,
        submitPolicy,
      }}
    >
      {currentStep === 1 ? <AddPolicyDetails /> : <AddApprovers />}
    </PolicyContext.Provider>
  );
}
