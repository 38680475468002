import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Heading, Select, Text } from '@chakra-ui/react';
import { createParticipantOnAtomic, createParticipantReview } from 'api/internaltools';
import { ATOMIC_PARTICIPANT_INDUSTRY, INDUSTRY_TYPE_KEY } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import JsonViewer from 'components/JsonViewer/JsonViewer';

const CreateParticipant = () => {
  const { industry, kybId, kyb, kybKeysValues, partnerCode, setIndustry, nextStep, showToast, showError } = useContext(
    AtomicOnboardingContext
  );
  const [craeteParticipantRequest, setCreateParticipantRequest] = useState(null);
  const [originalCreateParticipantRequest, setOriginalCreateParticipantRequest] = useState(null);
  const [isReviewParticipantLoading, setIsReviewParticipantLoading] = useState(false);
  const [isCreateParticipantLoading, setIsCreateParticipantLoading] = useState(false);

  const isIndustryKeyPresent = INDUSTRY_TYPE_KEY in kybKeysValues;

  const handleJsonViewerModify = (edit) => {
    setCreateParticipantRequest(edit.updated_src);
  };

  const handleResetJson = () => {
    setCreateParticipantRequest(originalCreateParticipantRequest);
  };

  const handleReviewParticipant = async () => {
    setIsReviewParticipantLoading(true);
    const payload = {
      kybId,
      partnerCode,
      industry,
      kyb,
    };

    try {
      const response = await createParticipantReview(payload);
      const responseData = response?.data;

      setCreateParticipantRequest(responseData?.data);
      setOriginalCreateParticipantRequest(responseData?.data);
    } catch (error) {
      showError('Error getting Create Participant Request.', error);
    }
    setIsReviewParticipantLoading(false);
  };

  const handleCreateParticipant = async () => {
    setIsCreateParticipantLoading(true);
    const payload = {
      kybId,
      partnerCode,
      createAtomicParticipantRequest: craeteParticipantRequest,
    };

    try {
      await createParticipantOnAtomic(payload);
      showToast('Successfully created participant on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error creating participant on Atomic.', error);
    } finally {
      setIsCreateParticipantLoading(false);
    }
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Create Participant
      </Heading>
      <Flex mt={4} flexWrap='wrap' gap={8}>
        <Box flex='1'>
          <Box width='250px'>
            <Text fontSize='sm' mb={2} color='gray'>
              Industry Type: {isIndustryKeyPresent ? kybKeysValues[INDUSTRY_TYPE_KEY] : '-'}
            </Text>
            <Select placeholder='Select Industry' value={industry} onChange={(e) => setIndustry(e.target.value)}>
              {ATOMIC_PARTICIPANT_INDUSTRY.map((industry, idx) => (
                <option key={idx} value={industry}>
                  {industry}
                </option>
              ))}
            </Select>
          </Box>
          <Button
            mt={4}
            onClick={handleReviewParticipant}
            isLoading={isReviewParticipantLoading}
            isDisabled={kyb == null}
          >
            Review Participant Request
          </Button>
        </Box>
        {craeteParticipantRequest && (
          <Box flex='2'>
            <JsonViewer
              jsonContent={craeteParticipantRequest}
              onModify={handleJsonViewerModify}
              onReset={handleResetJson}
              height='50vh'
            />
            <Button colorScheme='blue' mt={4} onClick={handleCreateParticipant} isLoading={isCreateParticipantLoading}>
              Create Participant
            </Button>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default CreateParticipant;
