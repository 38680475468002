export const APPROVAL_POLICY_TYPES = {
  OPS_PAYOUT: 'ops_payouts',
};

export const APPROVAL_POLICY_FREQUENCY_CURRENCY = {
  USD: 'USD',
};

export const APPROVAL_POLICY_FREQUENCIES = {
  QUAD_HOURLY: 'Within 4 hrs',
  DAILY: 'Within 24 hrs',
  PER_TRANSACTION: 'Per transaction',
};

export const FREQUENCY_TYPE_QUAD_HOURLY = 'quad_hourly';
export const FREQUENCY_TYPE_DAILY = 'daily';
export const FREQUENCY_TYPE_PER_TRANSACTION = 'per_transaction';

export const FREQUENCY_TYPE_MAPPING = {
  'Within 4 hrs': FREQUENCY_TYPE_QUAD_HOURLY,
  'Within 24 hrs': FREQUENCY_TYPE_DAILY,
  'Per transaction': FREQUENCY_TYPE_PER_TRANSACTION,
};

export const FREQUENCY_TYPE_VALUE_TO_DISPLAY_MAPPING = {
  'quad_hourly': 'Within 4 hrs',
  'daily': 'Within 24 hrs',
  'per_transaction': 'Per transaction',
};

export const APPROVAL_POLICY_ACTIONS = {
  REQUEST_FOR_APPROVAL: 'request_for_approval',
};

export const POLICY_CONFIGURATIONS_FIELDS = {
  QUORUM: 'quorum',
  QUORUM_ONE: 'ONE',
  QUORUM_ALL: 'ALL',
  APPROVERS: 'approvers',
};

export const APPROVER_TYPES = {
  ROLE: 'role',
  ROMA_OPS_DASHBOARD_USER: 'roma_ops_dashboard_user',
};

export const NEXT_OPERATOR = {
  OR: 'OR',
  AND: 'AND',
};

export const APPROVAL_POLICY_FREQUENCY_OPTIONS = [
  {
    label: APPROVAL_POLICY_FREQUENCIES.QUAD_HOURLY,
    value: APPROVAL_POLICY_FREQUENCIES.QUAD_HOURLY,
  },
  {
    label: APPROVAL_POLICY_FREQUENCIES.DAILY,
    value: APPROVAL_POLICY_FREQUENCIES.DAILY,
  },
  {
    label: APPROVAL_POLICY_FREQUENCIES.PER_TRANSACTION,
    value: APPROVAL_POLICY_FREQUENCIES.PER_TRANSACTION,
  },
];
