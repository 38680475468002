import React from 'react';
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Avatar, Box, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { APPROVER_TYPES, POLICY_CONFIGURATIONS_FIELDS } from 'modules/policies/constants/constants';

const getDisplayName = (approver) => {
  return approver?.display_name || approver?.name || 'Unknown';
};

export default function ApprovalFlow({ approvalFlow, currentPolicyEvaluationLevel }) {
  return (
    <Box>
      {approvalFlow?.levels?.map((level, index) => (
        <Box
          key={index}
          mb={6}
          p={4}
          borderWidth={1}
          borderRadius='md'
          opacity={
            currentPolicyEvaluationLevel &&
            (level?.level < currentPolicyEvaluationLevel || level?.level > currentPolicyEvaluationLevel)
              ? 0.5
              : 1
          }
        >
          <Text fontWeight='bold'>Step {level?.level}</Text>
          {level?.sub_levels?.map((subLevel, subIndex) => (
            <Box key={subIndex} mt={4}>
              <Text fontWeight='bold' mb={2}>
                {subLevel?.quorum === POLICY_CONFIGURATIONS_FIELDS?.QUORUM_ONE ? 'Any of:' : 'All of:'}
              </Text>
              <VStack align='start' pl={4} spacing={2}>
                {subLevel?.approvers?.map((approver, approverIndex) => (
                  <HStack key={approverIndex} spacing={2}>
                    {approver?.type === APPROVER_TYPES.ROMA_OPS_DASHBOARD_USER && (
                      <Avatar size='xs' name={getDisplayName(approver)} />
                    )}
                    <Text>
                      {getDisplayName(approver)}
                      {approver?.type === APPROVER_TYPES.ROLE && (
                        <Text as='span' ml={2} fontSize='sm' color='gray.500'>
                          (Role)
                        </Text>
                      )}
                    </Text>
                    {approver?.type === APPROVER_TYPES.ROMA_OPS_DASHBOARD_USER && (
                      <Text fontSize='sm' color='gray.500'>
                        ({approver?.email})
                      </Text>
                    )}
                    {approver?.approval_request_status === 'approved' && <CheckCircleIcon color='green.500' />}
                    {approver?.approval_request_status === 'declined' && <CloseIcon color='red.500' />}
                  </HStack>
                ))}
              </VStack>
              {subLevel?.next_operator && (
                <Flex align='center' my={2}>
                  <Divider flex={1} />
                  <Text mx={2} fontWeight='bold'>
                    OR
                  </Text>
                  <Divider flex={1} />
                </Flex>
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
