import React, { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { RiUserLine } from 'react-icons/ri';
import { Badge, Box, Divider, Flex, IconButton, Text } from '@chakra-ui/react';
import { ENTITY_STATUS, KYB_STATUS } from 'constants/entityDetails';
import { PermissionsV2 } from 'constants/permissions_v2';
import UploadOwnerDocumentModal from 'modules/merchantinfo/components/UploadOwnerDocumentModal';
import PermissionWrapper from 'components/hoc/PermissionWrapper';

export default function SingleEntityDetails({ merchantId, entitydetails, entityIdx, entitiesCount }) {
  const {
    entity_id,
    entity_status,
    entity_name,
    geography,
    kyb_id,
    product_kyb_details,
    company_details,
  } = entitydetails;
  const kyb_products = Object.keys(product_kyb_details);
  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] = useState(false);

  const openUploadDocumentModal = () => {
    setIsUploadDocumentModalOpen(true);
  };

  const closeUploadDocumentModal = () => {
    setIsUploadDocumentModalOpen(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Box w='100%'>
      <Flex justify='space-between' alignItems='center'>
        <Flex alignItems='center' gap={2}>
          <RiUserLine />
          <Text fontSize='xl' fontWeight='bold'>
            {entity_name}
          </Text>
        </Flex>
        <Badge
          variant='subtle'
          rounded='0.8em'
          px={3}
          colorScheme={entity_status === ENTITY_STATUS.ACTIVE ? 'green' : 'gray'}
        >
          {entity_status.toUpperCase()}
        </Badge>
      </Flex>
      <Flex direction='column' gap={4} mt={2}>
        <Box>
          <Text fontSize='sm' color='gray'>
            Entity ID: {entity_id}
          </Text>
          <Text fontSize='sm' color='gray'>
            Geography: {geography}
          </Text>
        </Box>

        <Box>
          <Text fontWeight='bold'>KYB Status</Text>
          <Text fontSize='sm' color='gray'>
            KYB ID: {kyb_id}
          </Text>
          <Flex justify='space-between' mt={2}>
            {kyb_products.map((product, idx) => (
              <Box
                key={idx}
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                gap={2}
                borderWidth='1px'
                borderRadius='4px'
                p={2}
              >
                <Text>{capitalizeFirstLetter(product)}</Text>
                <Badge
                  variant='subtle'
                  rounded='0.8em'
                  px={3}
                  colorScheme={product_kyb_details[product]['status'] === KYB_STATUS.SUCCEEDED ? 'green' : 'gray'}
                >
                  {product_kyb_details[product]['status']}
                </Badge>
              </Box>
            ))}
          </Flex>
        </Box>

        <Flex mt={2} justify='space-between' align='center'>
          <Box>
            <Text fontWeight='bold'>Company Details</Text>
            <Text>Name: {company_details.company_name ?? '-'}</Text>
            <Text fontSize='sm'>Contact: {company_details.company_phone_no ?? '-'}</Text>
            <Text fontSize='sm' color='gray'>
              Email: {company_details.company_email ?? '-'}
            </Text>
          </Box>
          <PermissionWrapper permission={PermissionsV2.MANAGE_KYB}>
            <Box align='center' p={2} style={{ borderRadius: '6px', backgroundColor: 'rgba(0,0,0,0.03)' }}>
              <Text fontSize='sm' mb={1}>
                Upload owner&apos;s document
              </Text>
              <IconButton
                isDisabled={!kyb_id}
                icon={<FiUpload />}
                borderWidth='1px'
                borderColor='blue.500'
                onClick={openUploadDocumentModal}
              >
                Upload
              </IconButton>
            </Box>
          </PermissionWrapper>
        </Flex>
        {entityIdx !== entitiesCount - 1 && <Divider mt={4} />}
      </Flex>

      {isUploadDocumentModalOpen && (
        <UploadOwnerDocumentModal
          isOpen={isUploadDocumentModalOpen}
          onClose={closeUploadDocumentModal}
          merchantId={merchantId}
          entityId={entity_id}
          entityName={entity_name}
          kybId={kyb_id}
        />
      )}
    </Box>
  );
}
