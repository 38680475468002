export const showToast = (toast, title, description, status) => {
  toast({
    title: title,
    description: description,
    variant: 'left-accent',
    status: status,
    position: 'top-right',
    duration: 4000,
    isClosable: true,
  });
};

export const showError = (toast, title, error) => {
  if (error.response) {
    const errorData = error.response.data;

    showToast(toast, title, errorData['error']['message'], 'error');
  } else {
    showToast(toast, title, error.message, 'error');
  }
};
