import React from 'react';
import { Redirect,Route } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { useAuthentication } from 'AuthContext';
import { BASE_DASHBOARD } from 'constants/routes';

function AuthRoute({ component: Component, ...rest }) {
  const { isAuthenticated, isUserAuthStatusLoading } = useAuthentication();

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserAuthStatusLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner size='xl' thickness='5px' speed='0.8s' />
          </div>
        ) : isAuthenticated ? (
          <Redirect to={BASE_DASHBOARD} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default AuthRoute;
