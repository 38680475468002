import React, { useEffect, useState } from 'react';
import { Badge, Box, Flex, GridItem, Heading, Text } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Loader from 'components/Loader/Loader';

export default function MerchantDetails({ isLoading, merchantAndEntitiesDetails, error }) {
  const { merchant, entitiesKybStatus } = merchantAndEntitiesDetails;
  const [productWiseKybStatus, setProductWiseKybStatus] = useState([]);
  const [isKybLoading, setIsKybLoading] = useState(false);
  const [err, setErr] = useState(null);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    (async () => {
      try {
        if (merchant?.merchantId) {
          setIsKybLoading(true);
          const response = await internalToolsApi.getProductWiseKybStatus(merchant?.merchantId);
          const productWiseKybStatus = Object.entries(response?.data?.data?.product_kyb_status);

          setProductWiseKybStatus(productWiseKybStatus);
        }
      } catch (err) {
        setErr(err);
      }
      setIsKybLoading(false);
    })();
  }, [merchant]);

  return (
    <GridItem w='100%'>
      <Heading as='h1' size='md' mb={3}>
        Merchant
      </Heading>
      <Card minH='300px' maxH='400px' overflow='auto' style={{ borderWidth: '2px' }}>
        <CardBody>
          {entitiesKybStatus && (
            <Flex direction='column' gap={4} w='100%'>
              <Box>
                <Flex alignItems='center'>
                  <Text fontWeight='bold' fontSize='xl'>
                    {merchant?.merchantName}
                  </Text>
                </Flex>
                <Text fontSize='sm' color='gray'>
                  ID: {merchant?.merchantId}
                </Text>
              </Box>

              <Box>
                <Text fontWeight='bold'>KYB Status</Text>
                <Text mt={2} mb={2}>
                  Entity wise:
                </Text>
                <Flex direction='column' gap={1}>
                  {entitiesKybStatus?.map((entityKybStatus, idx) => (
                    <Box
                      key={idx}
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      gap={2}
                      borderWidth='1px'
                      borderRadius='4px'
                      p={2}
                    >
                      <Text fontSize='sm' color='gray'>
                        {entityKybStatus[0]}
                      </Text>
                      <Badge
                        variant='subtle'
                        rounded='0.8em'
                        px={3}
                        colorScheme={entityKybStatus[1] === 'succeeded' ? 'green' : 'gray'}
                      >
                        {entityKybStatus[1]}
                      </Badge>
                    </Box>
                  ))}
                  {entitiesKybStatus?.length === 0 && (
                    <Text fontStyle='italic' color='gray'>
                      No entities found.
                    </Text>
                  )}
                </Flex>

                <Text mt={4} mb={2}>
                  Product wise:
                </Text>
                <Flex direction='column' gap={1}>
                  {productWiseKybStatus.map((product, idx) => (
                    <Box
                      key={idx}
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      gap={2}
                      borderWidth='1px'
                      borderRadius='4px'
                      p={2}
                    >
                      <Text fontSize='sm' color='gray'>
                        {capitalizeFirstLetter(product[0])}
                      </Text>
                      <Badge
                        variant='subtle'
                        rounded='0.8em'
                        px={3}
                        colorScheme={product[1] === 'succeeded' ? 'green' : 'gray'}
                      >
                        {product[1]}
                      </Badge>
                    </Box>
                  ))}
                </Flex>
                {isKybLoading && <Loader />}
                {err && <AlertMessage errorTitle='Error fetching KYB status.' errorMessage={err?.message} />}
              </Box>
            </Flex>
          )}

          {isLoading && <Loader />}
          {error && <AlertMessage errorTitle='Error fetching merchant details.' errorMessage={error?.message} />}
        </CardBody>
      </Card>
    </GridItem>
  );
}
