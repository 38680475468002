import { instance } from 'api/internaltools';

export const signIn = async (email) => {
  const payload = {
    email,
  };

  return instance.post('/sign-in', payload);
};

export const validateSignIn = async (token, otp) => {
  const payload = {
    token,
    otp,
  };

  return instance.post('/validate/sign-in', payload);
};

export const signUp = async (email, firstName, lastName) => {
  const payload = {
    email,
    first_name: firstName,
    last_name: lastName,
  };

  return instance.post('/sign-up', payload);
};

export const validateSignUp = async (token, otp, firstName, lastName) => {
  const payload = {
    token,
    otp,
    first_name: firstName,
    last_name: lastName,
  };

  return instance.post('/validate/sign-up', payload);
};

export const signOut = async () => {
  return instance.get('/sign-out');
};
