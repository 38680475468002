import React from 'react';
import { BiReset } from 'react-icons/bi';
import { BsFillClipboard2Fill } from 'react-icons/bs';
import ReactJson from 'react-json-view';
import { Box, Button, Flex, useClipboard } from '@chakra-ui/react';
import { JSON_VIEWER_THEME } from 'components/JsonViewer/constants';

export default function JsonViewer({ jsonContent, onModify, onReset, height }) {
  const { onCopy, hasCopied } = useClipboard(JSON.stringify(jsonContent, null, 2));

  return (
    <>
      <Flex justify='end'>
        {onReset && (
          <Button colorScheme='red' variant='ghost' size='sm' leftIcon={<BiReset />} onClick={onReset}>
            Reset
          </Button>
        )}
        <Button colorScheme='blue' variant='ghost' size='sm' leftIcon={<BsFillClipboard2Fill />} onClick={onCopy}>
          {hasCopied ? 'Copied!' : 'Copy'}
        </Button>
      </Flex>
      <Box style={{ height, overflowY: 'auto' }}>
        <ReactJson
          src={jsonContent}
          theme={JSON_VIEWER_THEME}
          name={false}
          enableClipboard={false}
          displayObjectSize={false}
          displayDataTypes={false}
          displayArrayKey={false}
          collapseStringsAfterLength={60}
          onEdit={onModify}
          onDelete={onModify}
          onAdd={onModify}
        />
      </Box>
    </>
  );
}
