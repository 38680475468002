import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { createCorporateReview, patchCorporateOnAtomic, verifyCorporateOnAtomic } from 'api/internaltools';
import { ATOMIC_PATCH_CORPORATE_DESCRIPTION } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import JsonViewer from 'components/JsonViewer/JsonViewer';
import Loader from 'components/Loader/Loader';

const PatchCorporate = () => {
  const { merchantId, entityType, kybId, kyb, partnerCode, nextStep, showToast, showError } = useContext(
    AtomicOnboardingContext
  );
  const [patchCorporateRequest, setPatchCorporateRequest] = useState(null);
  const [originalPatchCorporateRequest, setOriginalPatchCorporateRequest] = useState(null);
  const [isPatchCorporateLoading, setIsPatchCorporateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCreateCorporateReview();
  }, []);

  const handleJsonViewerModify = (edit) => {
    setPatchCorporateRequest(edit.updated_src);
  };

  const handleResetJson = () => {
    setPatchCorporateRequest(originalPatchCorporateRequest);
  };

  const getCreateCorporateReview = async () => {
    try {
      setIsLoading(true);
      const payload = {
        merchantId,
        entityType,
        kyb,
      };
      const response = await createCorporateReview(payload);
      const responseData = response?.data;

      setPatchCorporateRequest(responseData?.data);
      setOriginalPatchCorporateRequest(responseData?.data);
    } catch (error) {
      showError('Error getting Patch Corporate Request.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCorporate = async () => {
    const payload = {
      kybId,
      partnerCode,
    };

    try {
      await verifyCorporateOnAtomic(payload);
      showToast('Successfully verified corporate on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error verifying corporate on Atomic.', error);
    }
  };

  const handlePatchCorporate = async () => {
    setIsPatchCorporateLoading(true);
    const payload = {
      kybId,
      partnerCode,
      createCorporateOnAtomicRequest: patchCorporateRequest,
    };

    try {
      await patchCorporateOnAtomic(payload);
      showToast('Successfully patched corporate on Atomic.', '', 'success');
      await handleVerifyCorporate();
    } catch (error) {
      showError('Error patching corporate on Atomic.', error);
    }
    setIsPatchCorporateLoading(false);
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Patch Corporate
      </Heading>
      <Text color='gray' mt={2} fontWeight='bold'>
        {ATOMIC_PATCH_CORPORATE_DESCRIPTION}
      </Text>
      {isLoading && <Loader />}
      {patchCorporateRequest && (
        <Box mt={4}>
          <JsonViewer
            jsonContent={patchCorporateRequest}
            onModify={handleJsonViewerModify}
            onReset={handleResetJson}
            height='50vh'
          />
          <Button colorScheme='blue' mt={4} onClick={handlePatchCorporate} isLoading={isPatchCorporateLoading}>
            Patch Corporate
          </Button>
        </Box>
      )}
    </>
  );
};

export default PatchCorporate;
