import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, IconButton, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { MERCHANT } from 'constants/merchantDetails';
import { MERCHANT_TABLE_HEADINGS } from 'modules/merchants/constants/constants';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Loader from 'components/Loader/Loader';
import Search from 'components/SearchBox/Search';
import MerchantRow from 'components/Tables/MerchantRow';

const Merchants = ({ isMerchantClickable = true }) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [merchantsList, setMerchantsList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const limit = 10;

  const getAllMerchants = async () => {
    setIsLoading(true);
    try {
      const response = await internalToolsApi.getAllMerchants();

      setMerchantsList(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const filteredMerchantsList = useMemo(() => {
    if (!searchTerm) return merchantsList;

    return merchantsList.filter(
      (merchant) =>
        merchant?.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        merchant?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        merchant?.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        merchant?.country_code?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [merchantsList, searchTerm]);

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const visibleMerchantList = filteredMerchantsList.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredMerchantsList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredMerchantsList]);

  useEffect(() => {
    getAllMerchants();
  }, []);

  const totalPages = Math.ceil(filteredMerchantsList.length / limit);

  return (
    <Flex direction='column' pt='30px' gap={4}>
      <Search searchType={MERCHANT} setSearchTerm={setSearchTerm} />
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            All Merchants{' '}
            <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
              {filteredMerchantsList.length}
            </Badge>
          </Text>
        </CardHeader>
        <CardBody>
          <Table color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                {MERCHANT_TABLE_HEADINGS.map((caption, idx) => {
                  return (
                    <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {visibleMerchantList.map((row) => {
                return (
                  <MerchantRow
                    key={`${row.code}-${row.name}`}
                    merchantId={row.id}
                    name={row.name}
                    code={row.code}
                    countryCode={row.country_code}
                    isTreasuryEnabled={row.is_treasury_enabled}
                    isPayoutsEnabled={row.is_payouts_enabled}
                    isPaymentsEnabled={row.is_payments_enabled}
                    isMerchantClickable={isMerchantClickable}
                    isOtcEnabled={row.is_otc_enabled}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        {isLoading && <Loader />}
        {error && <AlertMessage errorTitle='Error fetching merchants.' errorMessage={error?.message} />}
      </Card>
      {filteredMerchantsList.length ? (
        <Flex justify='space-between' align='center'>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredMerchantsList.length}
            />
          </Box>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Merchants;
