import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  PinInput,
  PinInputField,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { signUp, validateSignUp } from 'api/authentication';
import { useAuthentication } from 'AuthContext';
import { BASE_DASHBOARD, LOGIN, ROMA_LOGO_PATH } from 'constants/routes';
import { showError } from 'utils/notifications';

function Register() {
  const { updateUserAndFetchPermissions } = useAuthentication();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [otp, setOtp] = useState('');
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const handleSignUp = async () => {
    if (!email || !firstName || !lastName) {
      showError(toast, 'All fields are required', 'Please fill in all the fields.');

      return;
    }

    setIsLoading(true);
    try {
      const response = await signUp(email, firstName, lastName);

      setAuthToken(response?.data?.data?.token);
    } catch (error) {
      showError(toast, 'Error during token generation', error);
    }
    setIsLoading(false);
  };

  const handleValidateSignUp = async () => {
    if (!otp) {
      showError(toast, 'OTP is required', 'Please enter the OTP.');

      return;
    }

    setIsLoading(true);
    try {
      const response = await validateSignUp(authToken, otp, firstName, lastName);

      updateUserAndFetchPermissions(response?.data?.data);

      // Redirect to dashboard
      return history.push(BASE_DASHBOARD);
    } catch (error) {
      showError(toast, 'Error during sign up validation', error);
    }
    setIsLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (authToken) {
        handleValidateSignUp();
      } else {
        handleSignUp();
      }
    }
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100vh' flexDir='column' gap={8}>
      <Image src={ROMA_LOGO_PATH} alt='Zamp logo' w='150px' />
      <Box p={8} minW='500px' maxW='600px'>
        <Heading as='h2' size='lg' mb={6}>
          Get Started
        </Heading>
        {authToken ? (
          <Stack spacing={6}>
            <FormControl id='otp' isRequired>
              <FormLabel fontWeight='bold'>Enter OTP</FormLabel>
              <HStack>
                <PinInput otp value={otp} onChange={(value) => setOtp(value)} size='lg'>
                  {[...Array(6)].map((_, index) => (
                    <PinInputField key={index} onKeyDown={handleKeyDown} bg='gray.100' />
                  ))}
                </PinInput>
              </HStack>
            </FormControl>
            <Button colorScheme='teal' onClick={handleValidateSignUp} isLoading={isLoading} width='min'>
              Submit
            </Button>
          </Stack>
        ) : (
          <Stack spacing={6}>
            <FormControl id='firstName'>
              <FormLabel fontWeight='bold'>First Name</FormLabel>
              <Input
                bg='gray.100'
                type='text'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                size='lg'
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl id='lastName'>
              <FormLabel fontWeight='bold'>Last Name</FormLabel>
              <Input
                bg='gray.100'
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                size='lg'
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <FormControl id='email'>
              <FormLabel fontWeight='bold'>Business email</FormLabel>
              <Input
                bg='gray.100'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size='lg'
                onKeyDown={handleKeyDown}
              />
            </FormControl>
            <Button colorScheme='teal' onClick={handleSignUp} isLoading={isLoading} width='min'>
              Continue
            </Button>
          </Stack>
        )}
        <Box mt={6}>
          <Link to={LOGIN}>
            Have an account?
            <Button variant='link' ml={2} colorScheme='teal'>
              Sign in
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Register;
