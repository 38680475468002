import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Button, Flex, Icon, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { SIDEBARE_HIDDEN_ROUTES } from 'constants/routes';
import AccountMenu from 'components/AccountMenu/AccountMenu';
import IconBox from 'components/IconBox/IconBox';

const SidebarContent = ({ logo, routes }) => {
  let location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };

  const createLinks = (routes) => {
    const activeColor = useColorModeValue('gray.700', 'white');
    const inactiveColor = useColorModeValue('gray.400', 'gray.400');

    return routes.map((prop) => {
      if (!(prop.path.includes(':') || SIDEBARE_HIDDEN_ROUTES.includes(prop.path))) {
        return (
          <NavLink to={prop.layout + prop.path} key={prop.name}>
            {activeRoute(prop.layout + prop.path) === 'active' ? (
              <Button
                boxSize='initial'
                justifyContent='flex-start'
                alignItems='center'
                mb={{
                  xl: '12px',
                }}
                mx={{
                  xl: 'auto',
                }}
                ps={{
                  sm: '10px',
                  xl: '16px',
                }}
                py='12px'
                borderRadius='10px'
                _hover='none'
                w='100%'
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <Flex>
                  {typeof prop.icon === 'string' ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox bg='black' color='white' h='30px' w='30px' me='12px'>
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={activeColor} my='auto' fontSize='sm'>
                    {prop.name}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize='initial'
                justifyContent='flex-start'
                alignItems='center'
                bg='transparent'
                mb={{
                  xl: '12px',
                }}
                mx={{
                  xl: 'auto',
                }}
                py='12px'
                ps={{
                  sm: '10px',
                  xl: '16px',
                }}
                borderRadius='10px'
                _hover='none'
                w='100%'
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <Flex>
                  {typeof prop.icon === 'string' ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox h='30px' w='30px' me='12px'>
                      {prop.icon}
                    </IconBox>
                  )}
                  <Text color={inactiveColor} my='auto' fontSize='sm'>
                    {prop.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      }
    });
  };

  const links = <>{createLinks(routes)}</>;

  return (
    <Flex direction='column' height='100vh'>
      <Box
        mt={'25px'}
        mb='15px'
        display='flex'
        lineHeight='100%'
        fontWeight='bold'
        justifyContent='center'
        alignItems='center'
        fontSize='11px'
      >
        <Image src={logo} alt='Zamp logo' w='80px' />
      </Box>

      <Stack direction='column' flex='1' overflowY='auto'>
        {links}
      </Stack>

      <Box align='center' mt='10px' mb='10px' p='2' bg={useColorModeValue('white', 'gray.800')}>
        <AccountMenu />
      </Box>
    </Flex>
  );
};

export default SidebarContent;
