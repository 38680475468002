import axios from 'axios';
import {
  CSRF_TOKEN_KEY,
  REQUEST_RESOURCE_TYPE,
  REQUEST_RESOURCE_TYPE_MERCHANT,
  REQUEST_TIMEOUT,
} from 'constants/config';
import { getCookieByName } from 'utils/utils';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_TOOLS_API_URL,
  withCredentials: true,
  timeout: REQUEST_TIMEOUT,
});

export const publicApiInstance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_DOMAIN,
  withCredentials: true,
  timeout: REQUEST_TIMEOUT,
});

instance.interceptors.request.use(
  (config) => {
    config.headers[CSRF_TOKEN_KEY] = getCookieByName(CSRF_TOKEN_KEY);
    config.headers[REQUEST_RESOURCE_TYPE] = REQUEST_RESOURCE_TYPE_MERCHANT;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

publicApiInstance.interceptors.request.use(
  (config) => {
    config.headers[CSRF_TOKEN_KEY] = getCookieByName(CSRF_TOKEN_KEY);
    config.headers[REQUEST_RESOURCE_TYPE] = REQUEST_RESOURCE_TYPE_MERCHANT;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getAllMerchants = () => {
  return instance.get('/merchants');
};

export const getMerchantCompanyDetails = (id) => {
  return instance.get(`/merchant/${id}`);
};

export const getProductWiseKybStatus = (id) => {
  return instance.get(`/merchant/kyb/${id}/status`);
};

export const getMerchantInvestmentDetails = (id) => {
  return instance.get(`/merchant/${id}/treasury-details`);
};

export const getMerchantBankingDetails = (id) => {
  return instance.get(`/merchant/${id}/banking-details`);
};

export const getMerchantAccountsDetails = (id) => {
  return instance.get(`/merchant/${id}/accounts`);
};

export const getInitiatedKybs = (isFilteringEnabled = false) => {
  return instance.get(`/kybs/initiated?isFilteringEnabled=${isFilteringEnabled}`);
};

export const getKybById = (id) => {
  return instance.get(`/kyb/${id}`);
};

export const sendKybForReview = (payload) => {
  return instance.post('/kyb/review/nium', payload);
};

export const createCorporateReview = (payload) => {
  return instance.post('/kyb/atomic/corporate/review', payload);
};

export const createCorporateOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/corporate/create', payload);
};

export const uploadDocumentOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/document/upload', payload);
};

export const verifyCorporateOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/corporate/verify', payload);
};

export const patchCorporateOnAtomic = (payload) => {
  return instance.patch('/kyb/atomic/corporate/patch', payload);
};

export const createAccountReview = (payload) => {
  return instance.post('/kyb/atomic/corporate/account/review', payload);
};

export const createAccountOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/corporate/account/create', payload);
};

export const createParticipantReview = (payload) => {
  return instance.post('/kyb/atomic/participant/review', payload);
};

export const createParticipantOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/participant/create', payload);
};

export const verifyParticipantOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/participant/verify', payload);
};

export const patchParticipantOnAtomic = (payload) => {
  return instance.patch('/kyb/atomic/participant/patch', payload);
};

export const createConsentReview = (payload) => {
  return instance.post('/kyb/atomic/create/consent/review', payload);
};

export const createConsentOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/create/consent', payload);
};

export const confirmAccountOnAtomic = (payload) => {
  return instance.post('/kyb/atomic/account/confirm', payload);
};

export const getLastExecutedStep = (kybId, partnerCode) => {
  return instance.get(`/kyb/atomic/last-executed-step?kybId=${kybId}&partnerCode=${partnerCode}`);
};

export const getMerchantCorridors = (id) => {
  return instance.get(`/merchant/${id}/corridors`);
};

export const getMerchantCorridorPricing = (id) => {
  return instance.get(`/merchant/corridor-pricing/${id}`);
};

export const toggleMerchantCorridor = (payload) => {
  return instance.patch('/merchant/corridor/toggle', payload);
};

export const updateMerchantCorridorPricing = (payload) => {
  return instance.patch('/merchant/corridor/modify-corridor-config', payload);
};

export const getAvailableCorridors = (id) => {
  return instance.get(`/merchant/${id}/available-corridors`);
};

export const createCorridor = (payload) => {
  return instance.post('/corridor/create', payload);
};

export const getInitiatedAccounts = () => {
  return instance.get(`/accounts/initiated`);
};

export const getEntitiesAndCurrencies = (id) => {
  return instance.get(`/merchant/${id}/currencies`);
};

export const createNewAccount = (payload) => {
  return instance.post('/account', payload);
};

export const createAccountOnPartner = (payload) => {
  return instance.post('/account/create', payload);
};

export const addAccountDetails = (payload) => {
  return instance.post(`/account/details`, payload);
};

export const createEntityConfigurations = (payload) => {
  return instance.post('/entity-configuration', payload);
};

export const getEntityConfigurations = (merchantId, entityId) => {
  return instance.get(`/entity-configuration/merchant/${merchantId}/entity/${entityId}`);
};

export const getSupportedCurrencies = (fiatPartner, cryptoPartner) => {
  return instance.get(`/currencies?fiatPartner=${fiatPartner}&cryptoPartner=${cryptoPartner}`);
};

export const createNewAccountOnPartner = (payload) => {
  return instance.post('/partner/account/create', payload);
};

export const createPostOnboardingConfigs = (payload) => {
  return instance.post('/post-onboarding/configs', payload);
};

export const requestTechSupportLogin = (payload) => {
  return instance.post('/tech-support', payload);
};

export const getCorridors = () => {
  return instance.get(`/corridors`);
};

export const updateCorridor = (payload) => {
  return instance.patch('/corridor', payload);
};

export const getExhaustiveKybDetailsById = (id, password) => {
  return instance.get(`/kyb/${id}/${password}`);
};

export const generateUploadSignedUrl = (payload) => {
  return instance.post('/signed-upload-url', payload);
};

export const upsertDocumentToKybDetails = (payload) => {
  return instance.post('/kyb/document', payload);
};

export const updatePayoutSessionStatusReferenceId = (payload) => {
  return instance.post('/transaction/update', payload);
};

export const getFilteredMerchants = (filter) => {
  return instance.get(`/merchants?filters=${filter}`);
};

export const getEntityInitiatedAccounts = (entityId) => {
  return instance.get(`/entity/initiated-accounts/${entityId}`);
};

export const getTransactionJourneyConfig = (transactionId) => {
  return instance.get(`/transaction/${transactionId}/journey-stage-config`);
};

export const updateTransactionJourneyStage = (payload) => {
  return instance.patch('/transaction/journey-stage/update', payload);
};

export const getUserMerchantRelation = (email) => {
  return instance.get(`/user-merchant-relation?email=${email}`);
};

export const getEntityOnOffRampConfigurations = (merchantId, entityId) => {
  return instance.get(`/entity-on-off-ramp-config/merchant/${merchantId}/entity/${entityId}`);
};

export const getMerchantActiveEntities = (merchantId) => {
  return instance.get(`/merchant/${merchantId}/entities/active`);
};

export const getMerchantThirdPartyConfigs = (merchantId) => {
  return instance.get(`/merchant/${merchantId}/third-party-configs`);
};
