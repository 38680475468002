import { useHistory } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Flex, IconButton, Td, Text, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import { KYB_SERVICE_INTENT_V2 } from 'constants/entityDetails';
import * as ROUTES from 'constants/routes';

function InitiatedKybRow(props) {
  const { merchantId, entityId, entityName, kybId, createdAt, services = [] } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();

  const truncatedMerchantId = merchantId?.length > 15 ? `${merchantId?.slice(0, 12)}...` : merchantId;
  const truncatedEntityId = entityId?.length > 15 ? `${entityId?.slice(0, 12)}...` : entityId;
  const truncatedKybId = kybId?.length > 15 ? `${kybId?.slice(0, 12)}...` : kybId;
  const formattedCreatedAt = new Date(createdAt).toLocaleString();

  const handleClick = () => {
    if (services.some((service) => KYB_SERVICE_INTENT_V2.includes(service))) {
      return history.push(ROUTES.POST_ONBOARDING_BANKING_ENTITY + entityId, {
        merchantId,
        entityId,
        entityName,
        kybId,
      });
    }
  };

  return (
    <Tr onClick={handleClick} style={{ cursor: 'pointer' }} _hover={{ background: 'gray.100' }}>
      <Td pl='0px'>
        <Tooltip label={merchantId} bg='gray.600'>
          <Text fontSize='sm' color={textColor} isTruncated>
            {truncatedMerchantId}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={entityId} bg='gray.600'>
          <Text fontSize='sm' color={textColor} isTruncated>
            {truncatedEntityId}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {entityName}
        </Text>
      </Td>
      <Td>
        <Tooltip label={kybId} bg='gray.600'>
          <Text fontSize='sm' color={textColor}>
            {truncatedKybId}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Flex align='center' gap={2} wrap='wrap'>
          {services?.map((service) => (
            <Badge colorScheme='blue' rounded='0.8em' px={3} key={service}>
              {service?.toUpperCase()}
            </Badge>
          ))}
        </Flex>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {formattedCreatedAt}
        </Text>
      </Td>
      <Td>
        <IconButton icon={<ArrowForwardIcon />} isRound={true} />
      </Td>
    </Tr>
  );
}

export default InitiatedKybRow;
