import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, IconButton, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { KYB } from 'constants/entityDetails';
import { ENTITY_TABLE_HEADINGS } from 'modules/onboarding/constants/constants';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Loader from 'components/Loader/Loader';
import Search from 'components/SearchBox/Search';
import InitiatedKybRow from 'components/Tables/InitiatedKybRow';

const Onboarding = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [initiatedKybsList, setInitiatedKybsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await internalToolsApi.getInitiatedKybs(true);

        setInitiatedKybsList(response?.data?.data ?? []);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  }, []);

  const filteredKybList = useMemo(() => {
    if (!searchTerm) return initiatedKybsList;

    return initiatedKybsList.filter(
      (kyb) =>
        kyb?.entity_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.entity_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.kyb_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        kyb?.service?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [initiatedKybsList, searchTerm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredKybList]);

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const visibleKybList = filteredKybList.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredKybList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPages = Math.ceil(filteredKybList.length / limit);

  return (
    <Flex direction='column' pt='30px' gap={4}>
      <Search searchType={KYB} setSearchTerm={setSearchTerm} />
      <Card overflowX={{ sm: 'scroll' }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Initiated KYBs{' '}
            <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
              {filteredKybList.length}
            </Badge>
          </Text>
        </CardHeader>
        <CardBody>
          <Table color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                {ENTITY_TABLE_HEADINGS.map((caption, idx) => {
                  return (
                    <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {visibleKybList.map((row, idx) => {
                return (
                  <InitiatedKybRow
                    key={idx}
                    merchantId={row.merchant_id}
                    entityId={row.entity_id}
                    entityName={row.entity_name}
                    kybId={row.kyb_id}
                    service={row.service}
                    createdAt={row.created_at}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        {isLoading && <Loader />}
        {error && <AlertMessage errorTitle='Error fetching initiated KYBs.' errorMessage={error?.message} />}
      </Card>
      {filteredKybList.length ? (
        <Flex justify='space-between' align='center'>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredKybList.length}
            />
          </Box>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Onboarding;
