import { PiUploadSimpleBold } from 'react-icons/pi';
import { useHistory } from 'react-router-dom';
import { Badge, IconButton, Td, Text, Tooltip, Tr, useColorModeValue } from '@chakra-ui/react';
import { KYB_SERVICE_INTENT } from 'constants/entityDetails';
import * as ROUTES from 'constants/routes';

function InitiatedKybRow(props) {
  const { merchantId, entityId, entityName, kybId, service, createdAt } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();

  const truncatedEntityId = entityId?.length > 15 ? `${entityId?.slice(0, 12)}...` : entityId;
  const truncatedKybId = kybId?.length > 15 ? `${kybId?.slice(0, 12)}...` : kybId;
  const formattedCreatedAt = new Date(createdAt).toLocaleString();

  const handleClick = () => {
    switch (service) {
      case KYB_SERVICE_INTENT.BANKING_ACCOUNT:
        return history.push(ROUTES.KYB_SUBMISSION_BANKING_ROUTE + entityId, { merchantId, entityId, kybId });
      case KYB_SERVICE_INTENT.TREASURY_ACCOUNT:
        return history.push(ROUTES.KYB_SUBMISSION_TREASURY_ROUTE + entityId, { merchantId, entityId, kybId });
      default:
        return;
    }
  };

  return (
    <Tr onClick={handleClick} style={{ cursor: 'pointer' }} _hover={{ background: 'gray.100' }}>
      <Td pl='0px'>
        <Tooltip label={entityId} bg='gray.600'>
          <Text fontSize='sm' color={textColor} isTruncated>
            {truncatedEntityId}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {entityName}
        </Text>
      </Td>
      <Td>
        <Tooltip label={kybId} bg='gray.600'>
          <Text fontSize='sm' color={textColor}>
            {truncatedKybId}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Badge colorScheme='blue' rounded='0.8em' px={3}>
          {service?.toUpperCase()}
        </Badge>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {formattedCreatedAt}
        </Text>
      </Td>
      <Td>
        <IconButton icon={<PiUploadSimpleBold />} isRound={true} />
      </Td>
    </Tr>
  );
}

export default InitiatedKybRow;
