import React, {useEffect, useState} from 'react';
import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { lumosDashboardRoutes,opsDashboardRoutes } from 'routes.js';
import { isOpsDashboard } from 'utils/utils';
import SidebarResponsive from 'components/Sidebar/SidebarResponsive';

export default function HeaderLinks(props) {
  const { ...rest } = props;
  const [routes, setRoutes] = useState(opsDashboardRoutes);

  useEffect(() => {
    if (!isOpsDashboard()) {
      setRoutes(lumosDashboardRoutes);
    }
  }, []);

  return (
    <Flex w={{ sm: '100%', md: 'auto' }} flexDirection='row' justifyContent='end'>
      <SidebarResponsive
        logo={props.logo}
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
