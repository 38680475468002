import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Heading, Select, Text } from '@chakra-ui/react';
import { createCorporateOnAtomic, createCorporateReview } from 'api/internaltools';
import {
  ATOMIC_CORPORATE_ENTITY_TYPES,
  ATOMIC_CORPORATE_SOURCE_OF_FUNDS,
  BUSINESS_TYPE_KEY,
  SOURCE_OF_FUNDS_KEY,
} from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';
import JsonViewer from 'components/JsonViewer/JsonViewer';

const CreateCorporate = () => {
  const {
    merchantId,
    entityId,
    sourceOfFunds,
    entityType,
    kybId,
    kyb,
    kybKeysValues,
    partnerCode,
    setSourceOfFunds,
    setEntityType,
    nextStep,
    showToast,
    showError,
  } = useContext(AtomicOnboardingContext);
  const [createCorporateRequest, setCreateCorporateRequest] = useState(null);
  const [originalCreateCorporateRequest, setOriginalCreateCorporateRequest] = useState(null);

  const [isReviewCorporateLoading, setIsReviewCorporateLoading] = useState(false);
  const [isCreateCorporateLoading, setIsCreateCorporateLoading] = useState(false);

  const isSourceOfFundsKeyPresent = SOURCE_OF_FUNDS_KEY in kybKeysValues;
  const isEntityTypeKeyPresent = BUSINESS_TYPE_KEY in kybKeysValues;

  const handleJsonViewerModify = (edit) => {
    setCreateCorporateRequest(edit.updated_src);
  };

  const handleResetJson = () => {
    setCreateCorporateRequest(originalCreateCorporateRequest);
  };

  const handleReviewCorporate = async () => {
    setIsReviewCorporateLoading(true);
    const payload = {
      merchantId,
      sourceOfFunds,
      entityType,
      kyb,
    };

    try {
      const response = await createCorporateReview(payload);
      const responseData = response?.data;

      setCreateCorporateRequest(responseData?.data);
      setOriginalCreateCorporateRequest(responseData?.data);
    } catch (error) {
      showError('Error getting Create Corporate Request.', error);
    } finally {
      setIsReviewCorporateLoading(false);
    }
  };

  const handleCreateCorporate = async () => {
    setIsCreateCorporateLoading(true);
    const payload = {
      entityId,
      kybId,
      partnerCode,
      createCorporateOnAtomicRequest: createCorporateRequest,
    };

    try {
      await createCorporateOnAtomic(payload);
      showToast('Successfully created corporate on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error creating corporate on Atomic.', error);
    }
    setIsCreateCorporateLoading(false);
  };

  return (
    <>
      <Heading as='h2' size='md'>
        Create Corporate
      </Heading>
      <Flex mt={4} flexWrap='wrap' gap={8}>
        <Box flex='1'>
          <Box width='250px'>
            <Text fontSize='sm' mb={2} color='gray'>
              Source of Funds: {isSourceOfFundsKeyPresent ? kybKeysValues[SOURCE_OF_FUNDS_KEY] : '-'}
            </Text>
            <Select
              placeholder='Select Source of Fund'
              value={sourceOfFunds}
              onChange={(e) => setSourceOfFunds(e.target.value)}
            >
              {ATOMIC_CORPORATE_SOURCE_OF_FUNDS.map((sourceOfFund, idx) => (
                <option key={idx} value={sourceOfFund}>
                  {sourceOfFund}
                </option>
              ))}
            </Select>
          </Box>

          <Box width='250px' mt={4}>
            <Text fontSize='sm' mb={2} color='gray'>
              Entity Type: {isEntityTypeKeyPresent ? kybKeysValues[BUSINESS_TYPE_KEY] : '-'}
            </Text>
            <Select placeholder='Select Entity Type' value={entityType} onChange={(e) => setEntityType(e.target.value)}>
              {ATOMIC_CORPORATE_ENTITY_TYPES.map((entityType, idx) => (
                <option key={idx} value={entityType}>
                  {entityType}
                </option>
              ))}
            </Select>
          </Box>

          <Button mt={4} onClick={handleReviewCorporate} isLoading={isReviewCorporateLoading} isDisabled={kyb == null}>
            Review Corporate Request
          </Button>
        </Box>
        {createCorporateRequest && (
          <Box flex='2'>
            <JsonViewer
              jsonContent={createCorporateRequest}
              onModify={handleJsonViewerModify}
              onReset={handleResetJson}
              height='50vh'
            />
            <Button colorScheme='blue' mt={4} onClick={handleCreateCorporate} isLoading={isCreateCorporateLoading}>
              Create Corporate
            </Button>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default CreateCorporate;
