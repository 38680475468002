import React from 'react';
import { IoMdLogOut } from 'react-icons/io';
import { MdOutlinePolicy } from "react-icons/md";
import { RiGroupLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { Avatar, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useToast } from '@chakra-ui/react';
import { signOut } from 'api/authentication';
import { useAuthentication } from 'AuthContext';
import { PermissionsV2 } from 'constants/permissions_v2';
import { LOGIN, POLICIES_ROUTE, USERS_ROUTE } from 'constants/routes';
import { showError } from 'utils/notifications';
import PermissionWrapper from 'components/hoc/PermissionWrapper';

export default function AccountMenu() {
  const history = useHistory();
  const { user, isUserAuthStatusLoading, setIsAuthenticated, setUser } = useAuthentication();
  const toast = useToast();

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsAuthenticated(false);
      setUser(null);

      history.push(LOGIN);
    } catch (error) {
      showError(toast, 'Error during sign out', error);
    }
  };

  return (
    <Menu placement='top'>
      <MenuButton as={Button} rightIcon={<ChevronUpIcon />} width='100%' py={6}>
        {isUserAuthStatusLoading ? (
          <Flex alignItems='center'>
            <Avatar size='sm' mr={2} />
            <Text>Loading...</Text>
          </Flex>
        ) : (
          <Flex alignItems='center'>
            <Avatar size='sm' name={user?.first_name + ' ' + user?.last_name} mr={2} />
            <Text fontSize='sm' isTruncated>
              {user?.first_name} {user?.last_name}
            </Text>
          </Flex>
        )}
      </MenuButton>
      <MenuList>
        <PermissionWrapper permission={PermissionsV2.MANAGE_INTERNAL_USERS}>
          <MenuItem
            icon={<RiGroupLine />}
            onClick={() => {
              history.push(USERS_ROUTE);
            }}
          >
            Users
          </MenuItem>
        </PermissionWrapper>

        <PermissionWrapper permission={PermissionsV2.MANAGE_PAYMENT_POLICIES}>
          <MenuItem
            icon={<MdOutlinePolicy />}
            onClick={() => {
              history.push(POLICIES_ROUTE);
            }}
          >
            Payment Policies
          </MenuItem>
        </PermissionWrapper>

        <MenuItem icon={<IoMdLogOut />} onClick={handleSignOut} color='red'>
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
