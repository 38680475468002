import { instance, publicApiInstance } from 'api/internaltools';

export const getInternalMerchantPolicies = async () => {
  return instance.get('/merchant/policies');
};

export const getRolesResourceFilteredByMerchant = async (permissionName, resourceType, resourceIds) => {
  return publicApiInstance.get(
    `/v1/merchants/roles-resource-filtered?permission_name=${permissionName}&resource_type=${resourceType}&resource_ids=${resourceIds}`
  );
};

export const createInternalMerchantPolicy = async (payload) => {
  return instance.post('/policy', payload);
};

export const deleteInternalMerchantPolicy = async (id) => {
  return instance.delete(`/policy/${id}`);
};
