import { useState } from 'react';
import { CheckIcon, CloseIcon,EditIcon } from '@chakra-ui/icons';
import { Badge, Flex, IconButton, Input, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { updateCorridor } from 'api/internaltools';
import { CORRIDOR_TYPE_COLOR_MAPPING } from 'constants/merchantDetails';
import { showError, showToast } from 'utils/notifications';

function CorridorRow(props) {
  const { corridorId, sourceCurrency, destCurrency, corridorType, corridorFees, fetchCorridors } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const toast = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [editedFees, setEditedFees] = useState(corridorFees);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedFees(corridorFees);
  };

  const handleUpdateClick = async () => {
    setIsLoading(true);
    const payload = {
      corridor_id: corridorId,
      corridor_fees: editedFees,
    };

    try {
      await updateCorridor(payload);
      showToast(
        toast,
        'Success',
        `${sourceCurrency} -> ${destCurrency} corridor fees successfully updated.`,
        'success'
      );
    } catch (err) {
      showError(toast, `Error updating ${sourceCurrency} -> ${destCurrency} corridor fees.`, err);
    }
    setIsLoading(false);
    setIsEditing(false);

    fetchCorridors();
  };

  return (
    <Tr>
      <Td pl='0px'>
        <Text fontSize='sm' color={textColor}>
          {corridorId}
        </Text>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {sourceCurrency}
        </Text>
      </Td>
      <Td>
        <Text fontSize='sm' color={textColor}>
          {destCurrency}
        </Text>
      </Td>
      <Td>
        <Badge colorScheme={CORRIDOR_TYPE_COLOR_MAPPING[corridorType]} rounded='0.4em' px={2}>
          {corridorType}
        </Badge>
      </Td>
      <Td>
        {isEditing ? (
          <Flex align='center' justify='right'>
            <Input type='number' size='sm' value={editedFees} onChange={(e) => setEditedFees(e.target.value)} />
            <IconButton
              aria-label='Cancel'
              variant='ghost'
              colorScheme='red'
              icon={<CloseIcon />}
              size='sm'
              onClick={handleCancelClick}
            />
            <IconButton
              aria-label='Update'
              variant='ghost'
              colorScheme='green'
              icon={<CheckIcon />}
              size='sm'
              isLoading={isLoading}
              onClick={handleUpdateClick}
            />
          </Flex>
        ) : (
          <Flex align='center' justify='right'>
            <Text fontSize='sm' color={textColor} fontWeight='bold'>
              {corridorFees}
            </Text>
            <IconButton
              aria-label='Edit'
              icon={<EditIcon />}
              variant='ghost'
              colorScheme='blue'
              size='sm'
              onClick={handleEditClick}
              ml={2}
            />
          </Flex>
        )}
      </Td>
    </Tr>
  );
}

export default CorridorRow;
