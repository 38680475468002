import React from 'react';
import { Badge, HStack, Text, VStack } from '@chakra-ui/react';

export default function CorridorTemplateGroup({ templateGroup = [] }) {
    
  return templateGroup?.map((template, index) => (
    <VStack key={index} align='start' bg='gray.50' p={2} mb={2} borderWidth='2px'>
      {template?.template?.map((step, stepIndex) => (
        <HStack key={stepIndex} spacing={2}>
          <Badge colorScheme='gray'>{step?.action}</Badge>
          <Text fontSize='sm'>
            {step?.from_asset} ({step?.from_partner}) → {step?.to_asset} ({step?.to_partner})
          </Text>
        </HStack>
      ))}
    </VStack>
  ));
}
