import React from 'react';
import { useAuthentication } from 'AuthContext';

function PermissionWrapper({ children, permission = '' }) {
  const { userPermissions } = useAuthentication();

  if (!userPermissions?.has(permission)) {
    return null;
  }

  return <>{children}</>;
}

export default PermissionWrapper;
