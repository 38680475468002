import React from 'react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react';
import { UPLOAD_FILE_ALLOWED_FORMATS } from 'constants/constants';
import { STAKEHOLDER_DOCS_DETAILS } from 'modules/onboarding/constants/constants';

function StakeholdersDocsUpload({ stakeholderDocuments, setStakeholderDocuments, numOfStakeholder }) {
  const handleAddStakeholder = () => {
    setStakeholderDocuments((prevStakeholders) => [
      ...prevStakeholders,
      {
        documentNumber: '',
        documentType: '',
        documentIssuanceCountry: '',
        documentExpiryDate: '',
        document: [
          {
            fileName: '',
            fileType: '',
            document: null,
          },
        ],
      },
    ]);
  };

  const handleDeleteStakeholder = (stakeholderIndex) => {
    const updatedStakeholders = stakeholderDocuments.filter((_, index) => index !== stakeholderIndex);

    setStakeholderDocuments(updatedStakeholders);
  };

  const handleAddDocument = (stakeholderIndex) => {
    const updatedStakeholders = [...stakeholderDocuments];

    updatedStakeholders[stakeholderIndex].document.push({
      fileName: '',
      fileType: '',
      document: null,
    });
    setStakeholderDocuments(updatedStakeholders);
  };

  const handleDeleteDocument = (stakeholderIndex, documentIndex) => {
    const updatedStakeholders = [...stakeholderDocuments];

    updatedStakeholders[stakeholderIndex].document.splice(documentIndex, 1);
    setStakeholderDocuments(updatedStakeholders);
  };

  const handleDocumentChange = (stakeholderIndex, documentIndex, value) => {
    const updatedStakeholders = [...stakeholderDocuments];

    updatedStakeholders[stakeholderIndex].document[documentIndex]['document'] = value;
    updatedStakeholders[stakeholderIndex].document[documentIndex]['fileName'] = value.name;
    updatedStakeholders[stakeholderIndex].document[documentIndex]['fileType'] = value.type;
    setStakeholderDocuments(updatedStakeholders);
  };

  const handleDetailsChange = (stakeholderIndex, field, value) => {
    const updatedStakeholders = [...stakeholderDocuments];

    updatedStakeholders[stakeholderIndex][field] = value;
    setStakeholderDocuments(updatedStakeholders);
  };

  return (
    <Box>
      <Heading as='h2' size='lg'>
        Stakeholders Documents
      </Heading>
      <Flex justify='space-between' align='center' mb={4}>
        <Text color='gray'>Upload the document details for stakeholders.</Text>
        <Text fontSize='sm'>
          Number of stakeholders: <b>{numOfStakeholder ?? '-'}</b>
        </Text>
      </Flex>
      {stakeholderDocuments.map((stakeholder, stakeholderIndex) => (
        <Accordion key={stakeholderIndex} defaultIndex={[0]} allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                Stakeholder {stakeholderIndex + 1}
              </Box>
              {stakeholderIndex !== 0 && (
                <DeleteIcon color='red' onClick={() => handleDeleteStakeholder(stakeholderIndex)} mr={2} />
              )}
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <Box width='300px'>
                {STAKEHOLDER_DOCS_DETAILS.map((detail, idx) => (
                  <Box key={idx}>
                    <label>{detail}</label>
                    <Input
                      value={stakeholder[detail]}
                      onChange={(e) => handleDetailsChange(stakeholderIndex, detail, e.target.value)}
                      mb={2}
                    />
                  </Box>
                ))}
              </Box>

              {stakeholder.document.map((_, documentIndex) => (
                <Flex key={documentIndex} justify='space-between' align='center' mt={4}>
                  <input
                    type='file'
                    accept={UPLOAD_FILE_ALLOWED_FORMATS}
                    onChange={(e) => handleDocumentChange(stakeholderIndex, documentIndex, e.target.files[0])}
                  />
                  {documentIndex !== 0 && (
                    <DeleteIcon
                      color='red'
                      cursor='pointer'
                      onClick={() => handleDeleteDocument(stakeholderIndex, documentIndex)}
                      mr={2}
                    />
                  )}
                </Flex>
              ))}
              <Button leftIcon={<AddIcon />} size='sm' onClick={() => handleAddDocument(stakeholderIndex)} mt={4}>
                Add another document
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}

      <Button leftIcon={<AddIcon />} onClick={handleAddStakeholder} mt={4}>
        Add another stakeholder
      </Button>
    </Box>
  );
}

export default StakeholdersDocsUpload;
