import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { setUserTransactionLimit, updateUserTransactionLimit } from 'api/transactions';
import { showError, showToast } from 'utils/notifications';

export default function TransactionLimitEditDrawer({ isOpen, onClose, user, txnLimit, setTxnLimit }) {
  const [limit, setLimit] = useState(txnLimit?.upper_limit);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSaveLimit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        upper_limit: parseFloat(limit),
        frequency_type: 'daily',
      };

      if (txnLimit?.frequency_type) {
        await updateUserTransactionLimit(user?.userId, payload);
        showToast(toast, 'Transaction limit updated successfully', '', 'success');
      } else {
        await setUserTransactionLimit(user?.userId, payload);
        showToast(toast, 'Transaction limit created successfully', '', 'success');
      }

      setTxnLimit(payload);
      onClose();
    } catch (error) {
      showError(toast, 'Error updating transaction limit', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLimit(txnLimit?.upper_limit);
    }
  }, [isOpen, txnLimit]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Transaction Limit</DrawerHeader>

        <DrawerBody>
          <Box mb={4} display='flex' alignItems='center'>
            <Avatar name={user?.firstName + ' ' + user?.lastName} size='sm' />
            <Box ml='3'>
              <Text fontWeight='bold'>{user?.firstName + ' ' + user?.lastName}</Text>
              <Text color='gray' fontSize='sm'>
                {user?.email}
              </Text>
            </Box>
          </Box>

          <Text mb={4} fontWeight='bold'>
            Current Limit:{' '}
            {txnLimit?.frequency_type !== '' ? `$${txnLimit?.upper_limit} / ${txnLimit?.frequency_type}` : 'not set'}
          </Text>

          <FormControl mb={4}>
            <Input
              placeholder='Enter new limit'
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              type='number'
            />
          </FormControl>

          <Text color='gray' fontSize='sm'>
            Enter approval limit in USD with frequency = daily.
          </Text>
        </DrawerBody>

        <DrawerFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleSaveLimit}
            isLoading={isLoading}
            isDisabled={
              !limit ||
              (txnLimit?.upper_limit === parseFloat(limit) && parseFloat(limit) !== 0) ||
              parseFloat(limit) < 0
            }
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
