export const MERCHANT = 'merchant';

export const MERCHANT_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const CORRIDORS = 'corridors';

export const CORRIDOR_TYPE_COLOR_MAPPING = {
  FIAT_FIAT: 'green',
  FIAT_CRYPTO: 'blue',
  CRYPTO_FIAT: 'purple',
  CRYPTO_CRYPTO: 'gray',
};

export const TRANSACTIONS = 'by transaction ID, merchant ID, beneficiary name or source amount';
