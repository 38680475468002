import React from 'react';
import { Box, Heading, Link,Text } from '@chakra-ui/react';

export default function ShowKeyAndValue({ keyName, value }) {
  const isLink = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/.test(value);

  return (
    <Box p={3} borderRadius='8px' bg='gray.100'>
      <Heading as='h2' size='md'>
        {keyName}
      </Heading>
      {isLink ? (
        <Link
          href={value}
          color='blue.500'
          overflow='hidden'
          textOverflow='ellipsis'
          display='block'
          whiteSpace='nowrap'
          isExternal
        >
          {value}
        </Link>
      ) : (
        <Text color='gray'>{value}</Text>
      )}
    </Box>
  );
}
