import React, { useEffect, useState } from 'react';
import { Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { getInternalUsers } from 'api/user';
import { INTERNAL_USERS_TABLE_HEADINGS } from 'modules/internalusers/constants/constants';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import InternalUserRow from 'components/Tables/InternalUserRow';

export default function InternalUsers() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [internalUsersList, setInternalUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await getInternalUsers();

      setInternalUsersList(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Flex direction='column' pt='30px' gap={4}>
      <Card overflowX={{ sm: 'scroll' }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Users
          </Text>
        </CardHeader>
        <CardBody>
          <LoadingErrorWrapper isLoading={isLoading} errorTitle='Error fetching internal users.' error={error}>
            <Table color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  {INTERNAL_USERS_TABLE_HEADINGS.map((caption, idx) => {
                    return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                        {caption}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {internalUsersList?.map((user) => {
                  return (
                    <InternalUserRow
                      key={user?.id}
                      userId={user?.id}
                      firstName={user?.first_name}
                      lastName={user?.last_name}
                      emailId={user?.email_id}
                      userRole={user?.roles?.length > 0 ? user?.roles[0] : {}}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </LoadingErrorWrapper>
        </CardBody>
      </Card>
    </Flex>
  );
}
