import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';

export default function AlertMessage({ errorTitle, errorMessage }) {
  return (
    <Alert status='error' mt={4}>
      <AlertIcon />
      <AlertTitle>{errorTitle}</AlertTitle>
      <AlertDescription>{errorMessage}</AlertDescription>
    </Alert>
  );
}
