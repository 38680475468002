import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { approveTransactionRequest, declineTransactionRequest, getDetailedTransactionRequest } from 'api/transactions';
import BeneficiaryDetails from 'modules/approve-transactions/BeneficiaryDetails';
import {
  POLICY_EVALUATION_STATUS,
  POLICY_EVALUATION_STATUS_TO_COLOR_MAPPING,
  SMB_TRANSACTION_TYPE_SINGLE_PAYOUT_OPS,
} from 'modules/approve-transactions-v2/constants/constants';
import { showError } from 'utils/notifications';
import ApprovalFlow from 'components/ApprovalFlow/ApprovalFlow';
import SkeletonErrorWrapper from 'components/SkeletonErrorWrapper/SkeletonErrorWrapper';

const TransactionRequestDetailsDrawer = ({ isOpen, onClose, smbTransactionRequestId, refetchPendingTransactions }) => {
  const [smbTransactionRequestDetails, setSmbTransactionRequestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  const handleApprove = async () => {
    setIsApproving(true);
    try {
      const payload = {
        transaction_type: SMB_TRANSACTION_TYPE_SINGLE_PAYOUT_OPS,
      };

      await approveTransactionRequest(smbTransactionRequestDetails?.transaction_id, payload);
    } catch (err) {
      showError(toast, 'Error approving transaction request', err);
    } finally {
      setIsApproving(false);
      refreshTransactionRequest();
    }
  };

  const handleDecline = async () => {
    setIsDeclining(true);
    try {
      const payload = {
        transaction_type: SMB_TRANSACTION_TYPE_SINGLE_PAYOUT_OPS,
      };

      await declineTransactionRequest(smbTransactionRequestDetails?.transaction_id, payload);
    } catch (err) {
      showError(toast, 'Error declining transaction request', err);
    } finally {
      setIsDeclining(false);
      refreshTransactionRequest();
    }
  };

  const fetchDetailedTransactionRequest = async () => {
    setIsLoading(true);
    try {
      const response = await getDetailedTransactionRequest(smbTransactionRequestId);

      setSmbTransactionRequestDetails(response?.data?.data || {});
    } catch (err) {
      setError(err);
    }

    setIsLoading(false);
  };

  const refreshTransactionRequest = async () => {
    try {
      const response = await getDetailedTransactionRequest(smbTransactionRequestId);

      setSmbTransactionRequestDetails(response?.data?.data || {});
    } catch (err) {
      showError(toast, 'Error refreshing transaction request', err);
    }
  };

  const refreshPendingTransactionsList = async () => {
    const terminatingApprovalStatuses = [POLICY_EVALUATION_STATUS.APPROVED, POLICY_EVALUATION_STATUS.DECLINED];

    try {
      if (terminatingApprovalStatuses.includes(smbTransactionRequestDetails?.policy_evaluation_status)) {
        await refetchPendingTransactions();
      }
    } catch (err) {
      showError(toast, 'Error refreshing pending transactions', err);
    }
  };

  useEffect(() => {
    refreshPendingTransactionsList();
  }, [smbTransactionRequestDetails]);

  useEffect(() => {
    if (smbTransactionRequestId) {
      fetchDetailedTransactionRequest();
    }

    return () => {
      setSmbTransactionRequestDetails(null);
      setError(null);
    };
  }, [smbTransactionRequestId]);

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='xl'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Payment details</DrawerHeader>
        <DrawerBody>
          <SkeletonErrorWrapper isLoading={isLoading} error={error} errorTitle='Error loading transaction details'>
            {smbTransactionRequestDetails && (
              <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                <GridItem>
                  <VStack spacing={4} align='stretch'>
                    <HStack justifyContent='space-between'>
                      <Box>
                        <Text fontSize='2xl' fontWeight='bold'>
                          {smbTransactionRequestDetails?.source_currency_code}{' '}
                          {smbTransactionRequestDetails?.source_amount}
                        </Text>
                        <Text fontSize='sm' color='gray.500'>
                          {new Date(smbTransactionRequestDetails?.payout_session_created_at).toLocaleString()}
                        </Text>
                      </Box>
                      <Badge
                        colorScheme={
                          POLICY_EVALUATION_STATUS_TO_COLOR_MAPPING[
                            smbTransactionRequestDetails?.policy_evaluation_status
                          ]
                        }
                        fontSize='md'
                      >
                        {smbTransactionRequestDetails?.policy_evaluation_status}
                      </Badge>
                    </HStack>

                    <Heading fontSize='md'>Transaction Details</Heading>

                    <VStack align='left'>
                      <Text fontSize='sm'>Transaction ID: {smbTransactionRequestDetails?.transaction_id}</Text>

                      <Text fontSize='sm' backgroundColor='gray.100' py={2} borderRadius={6}>
                        Receiving amount: {smbTransactionRequestDetails?.dest_currency_code}{' '}
                        {smbTransactionRequestDetails?.receiving_amount}
                      </Text>

                      <Text fontSize='sm'>
                        Exchange Rate: {smbTransactionRequestDetails?.source_currency_code} 1 ={' '}
                        {smbTransactionRequestDetails?.dest_currency_code} {smbTransactionRequestDetails?.exchange_rate}
                      </Text>

                      <Text fontSize='sm' backgroundColor='gray.100' py={2} borderRadius={6}>
                        Total fees: {smbTransactionRequestDetails?.source_currency_code}{' '}
                        {smbTransactionRequestDetails?.total_cost}
                      </Text>

                      <Text fontSize='sm'>Recipient Name: {smbTransactionRequestDetails?.beneficiary_name}</Text>

                      <Text fontSize='sm' backgroundColor='gray.100' py={2} borderRadius={6}>
                        Recipient Account Number: {smbTransactionRequestDetails?.beneficiary_account_number}
                      </Text>
                    </VStack>
                  </VStack>
                </GridItem>

                <GridItem>
                  <VStack spacing={4} align='stretch'>
                    {smbTransactionRequestDetails?.current_user_approval_status === 'pending' && (
                      <HStack spacing={4} justifyContent='flex-end'>
                        <Button
                          colorScheme='red'
                          onClick={handleDecline}
                          width='150px'
                          variant='outline'
                          isLoading={isDeclining}
                        >
                          Decline
                        </Button>
                        <Button colorScheme='green' onClick={handleApprove} width='150px' isLoading={isApproving}>
                          Approve
                        </Button>
                      </HStack>
                    )}
                    <ApprovalFlow
                      approvalFlow={smbTransactionRequestDetails?.approval_flow}
                      currentPolicyEvaluationLevel={smbTransactionRequestDetails?.current_policy_evaluation_level}
                    />
                  </VStack>
                </GridItem>
              </Grid>
            )}
          </SkeletonErrorWrapper>

          {smbTransactionRequestDetails?.quote_version == 'V2' && (
            <>
              <Heading size='md' mb={4} mt={4}>
                Beneficiary Details
              </Heading>
              <BeneficiaryDetails transactionId={smbTransactionRequestDetails?.transaction_id} />
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TransactionRequestDetailsDrawer;
