import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { getTransactionsForApproval } from 'api/transactions';
import { TRANSACTION } from 'constants/transactionDetails';
import TransactionDetailsDrawer from 'modules/approve-transactions/TransactionDetailsDrawer';
import { TRANSACTION_FOR_APPROVAL_TABLE_HEADINGS, TRANSACTION_LIMIT } from 'modules/merchantinfo/constants/constants';
import { formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Loader from 'components/Loader/Loader';
import Search from 'components/SearchBox/Search';

export default function ApproveTransactionsList() {
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransactionId, setSelectedTransactionId] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const filteredTransactionsList = useMemo(() => {
    if (!searchTerm) return transactions;

    return transactions.filter(
      (transaction) =>
        transaction?.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction?.amount?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction?.currency_code?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [transactions, searchTerm]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredTransactionsList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * TRANSACTION_LIMIT;
  const endIndex = startIndex + TRANSACTION_LIMIT;
  const visibleTransactionsList = filteredTransactionsList.slice(startIndex, endIndex);

  const fetchTransactionsForApproval = async () => {
    try {
      setIsLoading(true);
      const response = await getTransactionsForApproval();

      setTransactions(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredTransactionsList]);

  useEffect(() => {
    fetchTransactionsForApproval();
  }, []);

  const totalPages = Math.ceil(filteredTransactionsList.length / TRANSACTION_LIMIT);

  const handleTxnClick = (transactionId) => {
    setSelectedTransactionId(transactionId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedTransactionId('');
  };

  return (
    <Box mt={6} overflowX='auto' maxWidth='100%'>
      <Heading as='h1' size='md' mb={3}>
        Approve Transactions
        <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
          {filteredTransactionsList?.length}
        </Badge>
      </Heading>

      <Search searchType={TRANSACTION} setSearchTerm={setSearchTerm} />

      <Alert status='info' mt={4}>
        <AlertIcon />
        Please review transactions &gt; $25,000. Approval is required to execute them automatically.
      </Alert>

      <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }} mt={4}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {TRANSACTION_FOR_APPROVAL_TABLE_HEADINGS.map((heading, idx) => (
                <Th key={idx}>{heading}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {visibleTransactionsList.map((transaction) => (
              <Tr
                key={transaction?.id}
                fontSize='sm'
                onClick={() => handleTxnClick(transaction?.id)}
                style={{ cursor: 'pointer' }}
                _hover={{ background: 'gray.100' }}
              >
                <Td>{formatTransactionTime(transaction?.created_at)}</Td>
                <Td color='gray'>{transaction?.id ?? '-'}</Td>
                <Td>{transaction?.title ?? '-'}</Td>
                <Td fontWeight='bold'>
                  {transaction?.transfer_type === 'debit' ? '-' + transaction?.amount : transaction?.amount}
                </Td>
                <Td color='gray'>{transaction?.currency_code ?? '-'}</Td>
                <Td fontWeight='bold'>{transaction?.transfer_type ?? '-'}</Td>
                <Td color='green' fontWeight='bold'>
                  Approve <ArrowForwardIcon />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {!transactions.length && !isLoading && !error && (
        <Center>
          <Text color='gray' mt={4}>
            No transactions found.
          </Text>
        </Center>
      )}

      {isLoading && <Loader />}
      {error && <AlertMessage errorTitle='Error fetching transactions.' errorMessage={error?.message} />}

      {filteredTransactionsList.length ? (
        <Flex justify='space-between' align='center' mt={4}>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredTransactionsList.length}
            />
          </Box>
        </Flex>
      ) : null}

      <TransactionDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        transactionId={selectedTransactionId}
        onApproveSuccess={() => fetchTransactionsForApproval()}
      />
    </Box>
  );
}
