import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import * as internalToolsApi from 'api/submittonium';
import JsonViewer from 'components/JsonViewer/JsonViewer';

export default function NiumResponseModal({ isOpen, setIsNiumModalOpen, niumResponse, kybId, partnerCode }) {
  const [isSandboxLoading, setIsSandboxLoading] = useState(false);
  const [isCorporateLoading, setIsCorporateLoading] = useState(false);
  const [niumModalResponse, setNiumModalResponse] = useState(null);
  const [originalNiumModalResponse, setOriginalNiumModalResponse] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (niumResponse) {
      setNiumModalResponse(niumResponse);
      setOriginalNiumModalResponse(niumResponse);
    }
  }, [niumResponse]);

  const showToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      variant: 'left-accent',
      status: status,
      position: 'top',
      duration: 20000,
      isClosable: true,
    });
  };

  const handleJsonViewerModify = (edit) => {
    setNiumModalResponse(edit.updated_src);
  };

  const handleResetJson = () => {
    setNiumModalResponse(originalNiumModalResponse);
  };

  const handleCloseClick = () => {
    const shouldClose = window.confirm('Any manual edits will be lost. Are you sure you want to go back?');

    if (shouldClose) {
      setIsNiumModalOpen(false);
    }
  };

  const submitKYBOnNiumSandbox = async () => {
    setIsSandboxLoading(true);

    const payload = {
      kybId,
      partnerCode,
      niumJson: niumModalResponse,
    };

    try {
      const response = await internalToolsApi.submitKYBOnNiumSandbox(payload);

      showToast('KYB successfully submitted on Sandbox.', JSON.stringify(response?.data?.data), 'success');
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;

        showToast('Error while submitting KYB on Sandbox.', errorData['error']['message'], 'error');
      } else {
        showToast('Error while submitting KYB on Sandbox.', error.message, 'error');
      }
    }

    setIsSandboxLoading(false);
  };

  const submitKYBOnNiumCorporate = async () => {
    setIsCorporateLoading(true);

    const payload = {
      kybId,
      partnerCode,
      niumJson: niumModalResponse,
    };

    try {
      const response = await internalToolsApi.submitKYBOnNiumCorporate(payload);

      showToast('KYB successfully submitted on Corporate.', JSON.stringify(response?.data?.data), 'success');
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;

        showToast('Error while submitting KYB on Corporate.', errorData['error']['message'], 'error');
      } else {
        showToast('Error while submitting KYB on Corporate.', error.message, 'error');
      }
    }

    setIsCorporateLoading(false);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={() => setIsNiumModalOpen(false)}
      isCentered
      size='full'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Review NIUM JSON</ModalHeader>
        <ModalBody>
          {niumModalResponse && (
            <JsonViewer
              jsonContent={niumModalResponse}
              onModify={handleJsonViewerModify}
              onReset={handleResetJson}
              height='65vh'
            />
          )}

          <Button onClick={handleCloseClick} colorScheme='blue' size='sm' mt={2}>
            Upload more docs
          </Button>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup isAttached>
            <Button onClick={submitKYBOnNiumSandbox} isLoading={isSandboxLoading}>
              Submit KYB on Sandbox
            </Button>
            <Button onClick={submitKYBOnNiumCorporate} isLoading={isCorporateLoading} colorScheme='blue'>
              Submit KYB on Corporate
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
