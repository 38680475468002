import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { SIDEBARE_HIDDEN_ROUTES } from 'constants/routes';
import AccountMenu from 'components/AccountMenu/AccountMenu';
import IconBox from 'components/IconBox/IconBox';

const state = {};

function SidebarResponsive(props) {
  let location = useLocation();
  const mainPanel = React.useRef();
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };

  const createLinks = (routes) => {
    const inactiveBg = useColorModeValue('white', 'gray.700');
    const activeColor = useColorModeValue('gray.700', 'white');
    const inactiveColor = useColorModeValue('gray.400', 'gray.400');

    return routes.map((prop) => {
      if (prop.redirect || prop.path.includes(':') || SIDEBARE_HIDDEN_ROUTES.includes(prop.path)) {
        return null;
      }
      if (prop.category) {
        var st = {};

        st[prop['state']] = !state[prop.state];

        return (
          <div key={prop.name}>
            <Text
              color={activeColor}
              fontWeight='bold'
              mb={{
                xl: '12px',
              }}
              mx='auto'
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py='12px'
            >
              {document.documentElement.dir === 'rtl' ? prop.rtlName : prop.name}
            </Text>
            {createLinks(prop.views)}
          </div>
        );
      }

      return (
        <NavLink to={prop.layout + prop.path} key={prop.name}>
          {activeRoute(prop.layout + prop.path) === 'active' ? (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              mb={{
                xl: '12px',
              }}
              mx={{
                xl: 'auto',
              }}
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py='12px'
              borderRadius='10px'
              _hover='none'
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox bg='black' color='white' h='30px' w='30px' me='12px'>
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my='auto' fontSize='sm'>
                  {document.documentElement.dir === 'rtl' ? prop.rtlName : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              bg='transparent'
              mb={{
                xl: '12px',
              }}
              mx={{
                xl: 'auto',
              }}
              py='12px'
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              borderRadius='15px'
              _hover='none'
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox bg={inactiveBg} h='30px' w='30px' me='12px'>
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my='auto' fontSize='sm'>
                  {document.documentElement.dir === 'rtl' ? prop.rtlName : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };

  const { logo, routes } = props;

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let hamburgerColor = useColorModeValue('gray.500', 'gray.200');

  if (props.secondary === true) {
    hamburgerColor = 'white';
  }
  var brand = (
    <Box pt={'35px'} mb='8px'>
      <Link
        href={`${process.env.PUBLIC_URL}/dashboard`}
        target='_blank'
        display='flex'
        lineHeight='100%'
        fontWeight='bold'
        justifyContent='center'
        alignItems='center'
        fontSize='11px'
        mb={4}
      >
        <Image src={logo} alt='Zamp logo' w='80px' />
      </Link>
    </Box>
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} ref={mainPanel} alignItems='center'>
      <HamburgerIcon cursor='pointer' color={hamburgerColor} w='20px' h='20px' ref={btnRef} onClick={onOpen} />
      <Drawer isOpen={isOpen} onClose={onClose} placement='left' finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent w='280px' maxW='280px' borderRadius='0px 10px 10px 0px'>
          <DrawerCloseButton _focus={{ boxShadow: 'none' }} _hover={{ boxShadow: 'none' }} />
          <DrawerBody maxW='280px' px='1rem'>
            <Flex direction='column' height='100vh'>
              <Box>{brand}</Box>
              <Stack direction='column' flex='1'>
                <Box>{links}</Box>
              </Stack>

              <Box align='center' mt='10px' mb='10px' p='2'>
                <AccountMenu />
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default SidebarResponsive;
