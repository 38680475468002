import { useState } from 'react';
import { GrUpdate } from 'react-icons/gr';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import * as internalToolsTxnApi from 'api/transactions';
import { formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import { showError, showToast } from 'utils/notifications';

export default function TxnStep({ stepLevelProgress, getPayoutSessionStepLevelProgress, transactionId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const toast = useToast();

  const retriggerPayoutSessionStepEvent = async () => {
    setIsLoading(true);
    try {
      await internalToolsTxnApi.retriggerPayoutSessionStepEvent({
        is_event_processed: stepLevelProgress?.is_processed,
        event_id: stepLevelProgress?.event_id,
      });
      toggleConfirmModal();
      showToast(
        toast,
        'Success',
        `Successfully retriggered event - ${stepLevelProgress?.type} for transaction - ${transactionId}.`,
        'success'
      );
    } catch (error) {
      showError(toast, 'Error retriggering event.', error);
    } finally {
      setIsLoading(false);
      getPayoutSessionStepLevelProgress();
    }
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen((prev) => !prev);
  };

  const timestamp = new Date(stepLevelProgress?.created_at);
  const time = timestamp?.toLocaleTimeString();

  return (
    <>
      <Tr py='2' my='2' borderY='1px solid'>
        <Td color='gray'>{formatTransactionTime(stepLevelProgress?.created_at)}</Td>
        <Td color='gray'>{time}</Td>
        <Td textTransform='uppercase'>{stepLevelProgress?.type}</Td>
        <Td>{stepLevelProgress?.category_id}</Td>
        <Td>{stepLevelProgress?.source_partner_code}</Td>
        <Td>{stepLevelProgress?.dest_partner_code}</Td>
        <Td>{Math.abs(stepLevelProgress?.source_amount)}</Td>
        <Td>{stepLevelProgress?.source_currency}</Td>
        <Td>{stepLevelProgress?.dest_currency}</Td>
        <Td>{stepLevelProgress?.txn_status ?? 'INITIATED'}</Td>
        <Td>
          {stepLevelProgress?.can_retry && (
            <Tooltip label='Retrigger'>
              <Button
                size='sm'
                width='fit-content'
                onClick={() =>
                  stepLevelProgress?.is_processed ? toggleConfirmModal() : retriggerPayoutSessionStepEvent()
                }
                leftIcon={<GrUpdate />}
                isLoading={isLoading}
              ></Button>
            </Tooltip>
          )}
        </Td>
      </Tr>
      <Modal isOpen={isConfirmModalOpen} onClose={toggleConfirmModal} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Retrigger already success event</Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button
                isLoading={isLoading}
                onClick={retriggerPayoutSessionStepEvent}
                size='sm'
                colorScheme='blue'
                variant='solid'
              >
                Yes
              </Button>
              <Button variant='outline' mr={3} onClick={toggleConfirmModal} size='sm'>
                No
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
