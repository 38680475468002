import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { ACCOUNT } from 'constants/entityDetails';
import {
  ERROR_FETCHING_INITIATED_ACCOUNTS,
  INITIATED_ACCOUNTS_TABLE_HEADINGS,
} from 'modules/accounts/constants/constants';
import CreateNewAccountModal from 'modules/accounts/CreateNewAccountModal';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';
import InitiatedAccountRow from 'components/Tables/InitiatedAccountRow';

const Accounts = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [initiatedAccountsList, setInitiatedAccountsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewAccountModalOpen, setIsNewAccountModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const fetchInitiatedAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await internalToolsApi.getInitiatedAccounts();

      setInitiatedAccountsList(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const filteredAccountList = useMemo(() => {
    if (!searchTerm) return initiatedAccountsList;

    return initiatedAccountsList.filter(
      (account) =>
        account?.merchant_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account?.entity_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account?.nick_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account?.currency_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account?.currency_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account?.partner_code?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [initiatedAccountsList, searchTerm]);

  useEffect(() => {
    if (!isNewAccountModalOpen) {
      fetchInitiatedAccounts();
    }
  }, [isNewAccountModalOpen]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredAccountList]);

  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const visibleAccountList = filteredAccountList.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredAccountList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPages = Math.ceil(filteredAccountList.length / limit);

  const handleOpenNewAccountModal = () => setIsNewAccountModalOpen(true);
  const handleCloseNewAccountModal = () => setIsNewAccountModalOpen(false);

  return (
    <Flex direction='column' pt='30px' gap={4}>
      <Search searchType={ACCOUNT} setSearchTerm={setSearchTerm} />
      <Card overflowX={{ sm: 'scroll' }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex align='center' justify='space-between'>
            <Text fontSize='xl' color={textColor} fontWeight='bold'>
              Requested Accounts{' '}
              <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
                {filteredAccountList.length}
              </Badge>
            </Text>
            <Button
              colorScheme='blue'
              variant='outline'
              ml={4}
              leftIcon={<AiOutlinePlus />}
              onClick={handleOpenNewAccountModal}
            >
              New Account
            </Button>
            {isNewAccountModalOpen && (
              <CreateNewAccountModal isOpen={isNewAccountModalOpen} onClose={handleCloseNewAccountModal} />
            )}
          </Flex>
        </CardHeader>
        <LoadingErrorWrapper isLoading={isLoading} errorTitle={ERROR_FETCHING_INITIATED_ACCOUNTS} error={error}>
          <CardBody>
            <Table color={textColor}>
              <Thead>
                <Tr my='.8rem' pl='0px' color='gray.400'>
                  {INITIATED_ACCOUNTS_TABLE_HEADINGS.map((caption, idx) => {
                    return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? '0px' : null}>
                        {caption}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {visibleAccountList.map((row) => {
                  return (
                    <InitiatedAccountRow
                      key={row?.id}
                      accountId={row?.id}
                      merchantId={row?.merchant_id}
                      entityId={row?.entity_id}
                      vaultId={row?.vault_id}
                      name={row?.name}
                      nickName={row?.nick_name}
                      currencyCode={row?.currency_code}
                      currencyType={row?.currency_type}
                      partnerCode={row?.partner_code}
                      status={row?.meta_data}
                      createdAt={row?.created_at}
                      partnerId={row?.partner_id}
                      metadata={row?.meta_data}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </LoadingErrorWrapper>
      </Card>
      {filteredAccountList.length ? (
        <Flex justify='space-between' align='center'>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredAccountList.length}
            />
          </Box>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default Accounts;
