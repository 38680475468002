import React, { useEffect, useState } from 'react';
import { Box, Divider, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import { ENTITY_OWNER_DOCUMENT_TYPES } from 'modules/merchantinfo/constants/constants';
import { calculateNumofAdditionalBusinessDocs, calculateNumOfCompanyOwners, formatString } from 'utils/utils';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import ShowKeyAndValue from 'components/ShowKeyAndValue/ShowKeyAndValue';

export default function ShowKybDetails({ entity }) {
  const [kyb, setKyb] = useState(null);
  const [numOfCompanyOwners, setNumOfCompanyOwners] = useState(0);
  const [additionalBusinessDocsCount, setAdditionalBusinessDocsCount] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getEntityKybDetails = async () => {
    setIsLoading(true);
    try {
      const response = await internalToolsApi.getExhaustiveKybDetailsById(
        entity?.kyb_id,
        process.env.REACT_APP_KYB_VIEW_PASSWORD
      );
      const kybDetails = response?.data?.data?.details;

      const kybMap = {};

      kybDetails?.forEach((detail) => {
        kybMap[`${detail.key}_${detail.parent}`] = detail;
      });

      const numOfCompanyOwners = calculateNumOfCompanyOwners(kybMap);
      const numOfAdditionalBusinessDocs = calculateNumofAdditionalBusinessDocs(kybMap);

      setKyb(kybMap);
      setNumOfCompanyOwners(numOfCompanyOwners);
      setAdditionalBusinessDocsCount(numOfAdditionalBusinessDocs);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const renderCompanyOwners = () => {
    const companyOwnersUI = [];

    for (let idx = 0; idx < numOfCompanyOwners; ++idx) {
      companyOwnersUI.push(
        <Flex key={`companyOwner - ${idx}`} direction='column' gap={3}>
          <Heading as='h1' size='md' mt={3} color='gray'>
            Company owner {idx + 1}
          </Heading>
          <ShowKeyAndValue
            keyName='First name'
            value={kyb[`first_name_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Last name'
            value={kyb[`last_name_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Phone number'
            value={kyb[`phone_number_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Email address'
            value={kyb[`email_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Date of Birth'
            value={kyb[`date_of_birth_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Relationship to company'
            value={kyb[`owner_profile_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Other relationship to company'
            value={kyb[`owner_profile_other_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue
            keyName='Ownership percentage'
            value={kyb[`ownership_percentage_company_owners[${idx}]`]?.value ?? 'Not available'}
          />

          <Heading as='h1' size='md' mt={4}>
            Home Address
          </Heading>
          <ShowKeyAndValue keyName='Country' value={kyb[`country_company_owners[${idx}]`]?.value ?? 'Not available'} />
          <ShowKeyAndValue
            keyName='Street address'
            value={kyb[`address1_company_owners[${idx}]`]?.value ?? 'Not available'}
          />
          <ShowKeyAndValue keyName='City' value={kyb[`city_company_owners[${idx}]`]?.value ?? 'Not available'} />
          <ShowKeyAndValue keyName='State' value={kyb[`state_company_owners[${idx}]`]?.value ?? 'Not available'} />
          <ShowKeyAndValue
            keyName='ZIP or postal code'
            value={kyb[`zip_code_company_owners[${idx}]`]?.value ?? 'Not available'}
          />

          <Heading as='h1' size='md' mt={4}>
            Owner&apos;s documents
          </Heading>

          {ENTITY_OWNER_DOCUMENT_TYPES.map((documentType) => {
            if (Object.prototype.hasOwnProperty.call(kyb, `${documentType?.value}_company_owners[${idx}]`)) {
              return (
                <ShowKeyAndValue
                  key={documentType?.value}
                  keyName={documentType?.label}
                  value={kyb[`${documentType?.value}_company_owners[${idx}]`]?.value ?? 'Not available'}
                />
              );
            }
          })}
        </Flex>
      );
    }

    if (companyOwnersUI.length === 0) {
      companyOwnersUI.push(
        <Text key='noCompanyOwners' color='gray'>
          No company owners information available.
        </Text>
      );
    }

    return companyOwnersUI;
  };

  const renderAdditionalBusinessDocuments = () => {
    const additionalBusinessDocumentsUI = [];

    for (let idx = 0; idx < additionalBusinessDocsCount; ++idx) {
      additionalBusinessDocumentsUI.push(
        <ShowKeyAndValue
          key={idx}
          keyName={
            formatString(kyb[`document[${idx}]_additional_business_document`]?.message, '_') ??
            `Additional Document ${idx + 1}`
          }
          value={kyb[`document[${idx}]_additional_business_document`]?.value ?? 'Not available'}
        />
      );
    }

    ENTITY_OWNER_DOCUMENT_TYPES.forEach((documentType) => {
      if (Object.prototype.hasOwnProperty.call(kyb, `${documentType?.value}_business_document`)) {
        additionalBusinessDocumentsUI.push(
          <ShowKeyAndValue
            key={documentType?.value}
            keyName={documentType?.label}
            value={kyb[`${documentType?.value}_business_document`]?.value ?? 'Not available'}
          />
        );
      }
    });

    return additionalBusinessDocumentsUI;
  };

  useEffect(() => {
    getEntityKybDetails();
  }, []);

  return (
    <Box>
      <LoadingErrorWrapper isLoading={isLoading} error={error} errorTitle='Error fetching KYB details.'>
        {kyb && (
          <VStack align='left' gap={4}>
            <Box>
              <Heading as='h1' size='lg' mt={4} mb={3}>
                Company details
              </Heading>
              <Flex direction='column' gap={3}>
                <ShowKeyAndValue
                  keyName='Legal Business Name'
                  value={kyb['legal_business_name_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company name (Doing business as)'
                  value={kyb['company_name_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Date of Incorporation'
                  value={kyb['date_of_formation_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company type'
                  value={kyb['business_type_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Source of Funds'
                  value={kyb['source_of_funds_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company website (Optional)'
                  value={kyb['website_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company Tax Identification Number'
                  value={kyb['ein_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company phone number'
                  value={kyb['phone_number_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company email address'
                  value={kyb['email_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Company industry type'
                  value={kyb['industry_type_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Description of business'
                  value={kyb['business_description_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Countries of Operation'
                  value={kyb['country_of_operations_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Total Number of Employees'
                  value={kyb['total_empoyees_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Annual Turn Over'
                  value={kyb['annual_turnover_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Country of Incorporation'
                  value={kyb['country_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='State of Incorporation'
                  value={kyb['state_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Monthly Virtual Asset Deposit'
                  value={kyb['monthly_virtual_asset_deposit_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Monthly Virtual Asset Withdrawal'
                  value={kyb['monthly_virtual_asset_withdrawl_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Monthly Fiat Deposit'
                  value={kyb['monthly_fiat_deposit_company_details']?.value ?? 'Not available'}
                />
                <ShowKeyAndValue
                  keyName='Monthly Fiat Withdrawal'
                  value={kyb['monthly_virtual_asset_withdrawl_company_details']?.value ?? 'Not available'}
                />
              </Flex>
            </Box>

            <Divider variant='dashed' />

            <Box>
              <Heading as='h1' size='lg' mb={3}>
                Company address
              </Heading>
              <Heading as='h2' size='md' mb={3}>
                Registered address
              </Heading>
              <Flex direction='column' gap={3} mb={3}>
                <ShowKeyAndValue
                  keyName='Country'
                  value={
                    kyb['country_company_address']?.value ??
                    kyb['registered_address.country_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='Street address'
                  value={
                    kyb['address1_company_address']?.value ??
                    kyb['registered_address.address1_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='City'
                  value={
                    kyb['city_company_address']?.value ??
                    kyb['registered_address.city_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='State'
                  value={
                    kyb['state_company_address']?.value ??
                    kyb['registered_address.state_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='ZIP or postal code'
                  value={
                    kyb['zip_code_company_address']?.value ??
                    kyb['registered_address.zip_code_company_address']?.value ??
                    'Not available'
                  }
                />
              </Flex>
              <Heading as='h2' size='md' mb={3}>
                Current address
              </Heading>
              <Flex direction='column' gap={3}>
                <ShowKeyAndValue
                  keyName='Country'
                  value={
                    kyb['country_company_address']?.value ??
                    kyb['current_address.country_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='Street address'
                  value={
                    kyb['address1_company_address']?.value ??
                    kyb['current_address.address1_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='City'
                  value={
                    kyb['city_company_address']?.value ??
                    kyb['current_address.city_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='State'
                  value={
                    kyb['state_company_address']?.value ??
                    kyb['current_address.state_company_address']?.value ??
                    'Not available'
                  }
                />
                <ShowKeyAndValue
                  keyName='ZIP or postal code'
                  value={
                    kyb['zip_code_company_address']?.value ??
                    kyb['current_address.zip_code_company_address']?.value ??
                    'Not available'
                  }
                />
              </Flex>
            </Box>

            <Divider variant='dashed' />

            <Box>
              <Heading as='h1' size='lg' mb={3}>
                Company owner
              </Heading>
              <Flex direction='column' gap={3}>
                {renderCompanyOwners()}
              </Flex>
            </Box>

            <Divider variant='dashed' />

            <Box>
              <Heading as='h1' size='lg' mb={3}>
                Company document
              </Heading>
              <Flex direction='column' gap={3}>
                <ShowKeyAndValue
                  keyName={kyb['document_type_business_document']?.value ?? 'Document'}
                  value={kyb['document_business_document']?.value ?? 'Not available'}
                />

                {renderAdditionalBusinessDocuments()}
              </Flex>
            </Box>
          </VStack>
        )}
      </LoadingErrorWrapper>
    </Box>
  );
}
