import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import * as internalToolsApi from 'api/internaltools';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Loader from 'components/Loader/Loader';

export default function MerchantInvestmentDetails({ merchantId }) {
  const [investmentDetails, setInvestmentDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isTreasuryDisabled = !investmentDetails && !isLoading && !error;
  const isTreasuryStatusSucceeded = investmentDetails && !investmentDetails?.status;
  const backgroundColor = isTreasuryDisabled ? '#DCDCDC' : '';

  useEffect(() => {
    const getMerchantInvestmentDetails = async () => {
      try {
        setIsLoading(true);
        const response = await internalToolsApi.getMerchantInvestmentDetails(merchantId);

        setInvestmentDetails(response?.data?.data);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    getMerchantInvestmentDetails();
  }, []);

  return (
    <Box w='100%' style={{ display: 'flex', flexDirection: 'column' }} mt={6}>
      <Heading as='h1' size='md' mb={3}>
        Treasury
      </Heading>
      <Card
        minH='150px'
        maxH='300px'
        overflow='auto'
        style={{ borderWidth: '2px', flex: 1, backgroundColor: `${backgroundColor}` }}
      >
        <CardBody display='flex' flexDirection='column' justifyContent='space-between' style={{ height: '100%' }}>
          {!isTreasuryDisabled && isTreasuryStatusSucceeded && (
            <>
              <Flex justify='space-between'>
                <Box>
                  <Text color='gray.500'>{investmentDetails?.treasury_summary?.total_balance_header_text}</Text>
                  <Heading as='h1' size='lg'>
                    {investmentDetails?.treasury_summary?.total_balance}
                  </Heading>
                  <Text mt={4}>{investmentDetails?.treasury_summary?.total_interest_earned_prompt}</Text>
                  <Text>{investmentDetails?.treasury_summary?.transfer_in_progress_text}</Text>
                </Box>
                <Box>
                  <Text>{investmentDetails?.treasury_summary?.cash_balance_text}</Text>
                  <Text>{investmentDetails?.treasury_summary?.securities_balance_text}</Text>
                </Box>
                <Box align='right'>
                  <Text>{investmentDetails?.treasury_summary?.cash_balance}</Text>
                  <Text>{investmentDetails?.treasury_summary?.securities_balance}</Text>
                </Box>
              </Flex>
            </>
          )}

          {!isLoading && !isTreasuryDisabled && !isTreasuryStatusSucceeded && (
            <Text color='gray'>
              Treasury KYB Status: <b>{investmentDetails?.status}</b>.
            </Text>
          )}

          {isTreasuryDisabled && <Text color='gray'>Investments is not enabled for this merchant.</Text>}

          {isLoading && <Loader />}
          {error && (
            <AlertMessage
              errorTitle='Error fetching investment details for this merchant.'
              errorMessage={error?.response?.data?.error?.message}
            />
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
