import React from 'react';
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FREQUENCY_TYPE_VALUE_TO_DISPLAY_MAPPING } from 'modules/policies/constants/constants';
import ApprovalFlow from 'components/ApprovalFlow/ApprovalFlow';

export default function PolicyDetailsDrawer({ isOpen, onClose, selectedPolicy }) {
  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Policy Details</DrawerHeader>
        <DrawerBody>
          {selectedPolicy && (
            <VStack align='start' spacing={6} width='100%'>
              <Box width='100%'>
                <Heading size='md' mb={2}>
                  {selectedPolicy?.name}
                </Heading>
                <Divider />
              </Box>

              <HStack justify='space-between' width='100%'>
                <VStack align='start'>
                  <Text color='gray.500'>Frequency</Text>
                  <Text fontWeight='bold'>
                    {
                      FREQUENCY_TYPE_VALUE_TO_DISPLAY_MAPPING[
                        selectedPolicy?.policy_configurations?.frequency?.frequency_type
                      ]
                    }
                  </Text>
                </VStack>
                <VStack align='start'>
                  <Text color='gray.500'>Amount Threshold</Text>
                  <Text fontWeight='bold'>
                    USD {Number(selectedPolicy?.policy_configurations?.limits?.lower_limit).toLocaleString()} and above
                  </Text>
                </VStack>
              </HStack>

              <Box width='100%'>
                <Heading size='sm' mb={4}>
                  Approval Flow
                </Heading>
                <ApprovalFlow approvalFlow={selectedPolicy?.policy_configurations?.approval_flow} />
              </Box>
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
