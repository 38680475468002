import React from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { IconButton, Input, InputGroup, InputLeftElement, useColorModeValue } from '@chakra-ui/react';
import _debounce from 'lodash/debounce';

export default function Search({ searchType, setSearchTerm, searchOnChangeEvent = true, width="250px" }) {
  let mainTeal = useColorModeValue('teal.300', 'teal.300');
  let inputBg = useColorModeValue('white', 'gray.800');
  let searchIcon = useColorModeValue('gray.700', 'gray.200');
  let mainText = useColorModeValue('gray.700', 'gray.200');

  const handleSearch = _debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      setSearchTerm(e.target.value);
    }
  };

  return (
    <InputGroup
      cursor='pointer'
      bg={inputBg}
      borderRadius='12px'
      w={width}
      me={{ sm: 'auto', md: '20px' }}
      _focus={{
        borderColor: { mainTeal },
      }}
      _active={{
        borderColor: { mainTeal },
      }}
    >
      <InputLeftElement>
        <IconButton
          bg='inherit'
          borderRadius='inherit'
          _hover='none'
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent',
          }}
          _focus={{
            boxShadow: 'none',
          }}
          icon={<SearchIcon color={searchIcon} w='15px' h='15px' />}
        />
      </InputLeftElement>
      <Input
        color={mainText}
        placeholder={`Search ${searchType}.`}
        borderRadius='inherit'
        onChange={(e) => searchOnChangeEvent && handleSearch(e.target.value)}
        onKeyDown={onEnter}
      />
    </InputGroup>
  );
}
