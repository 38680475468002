import React from 'react';
import { GridItem, Heading, Text } from '@chakra-ui/react';
import SingleEntityDetails from 'modules/merchantinfo/components/SingleEntityDetails';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Loader from 'components/Loader/Loader';

export default function EntitiesDetails({ isLoading, merchantId, entitiesDetails, error }) {
  const entitiesCount = entitiesDetails?.length;

  return (
    <>
      <GridItem w='100%'>
        <Heading as='h1' size='md' mb={3}>
          Entities
        </Heading>
        <Card minH='300px' maxH='400px' overflow='auto' style={{ borderWidth: '2px' }}>
          <CardBody display='flex' flexDirection='column' gap={4}>
            {entitiesDetails?.map((entitydetails, idx) => (
              <SingleEntityDetails
                key={entitydetails?.entity_id}
                merchantId={merchantId}
                entitydetails={entitydetails}
                entityIdx={idx}
                entitiesCount={entitiesCount}
              />
            ))}
            {entitiesDetails?.length === 0 && (
              <Text fontStyle='italic' color='gray'>
                No entities found.
              </Text>
            )}
            {isLoading && <Loader />}
            {error && <AlertMessage errorTitle='Error fetching entities details.' errorMessage={error?.message} />}
          </CardBody>
        </Card>
      </GridItem>
    </>
  );
}
