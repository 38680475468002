import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getMerchantRoles, updateInternalUserRole } from 'api/user';
import { showError, showToast } from 'utils/notifications';

export default function RoleEditDrawer({ isOpen, onClose, user, setCurrentRole }) {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const fetchRoles = async () => {
    try {
      const response = await getMerchantRoles();
      const filteredRoles = response?.data?.data?.filter((role) => role?.id !== user?.currentRole?.id);

      setRoles(filteredRoles ?? []);
    } catch (error) {
      showError(toast, 'Error fetching roles', error);
    }
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption?.value);
  };

  const handleSaveRole = async () => {
    if (selectedRole && selectedRole !== user?.currentRole?.id) {
      setIsLoading(true);
      try {
        const payload = {
          current_role: user?.currentRole?.id,
          new_role: selectedRole?.id,
        };

        await updateInternalUserRole(user?.userId, payload);
        showToast(toast, 'User role updated successfully', '', 'success');
        setCurrentRole(selectedRole);
      } catch (error) {
        showError(toast, 'Error updating user role', error);
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  };

  const roleOptions = roles?.map((role) => ({
    value: { id: role?.id, name: role?.name },
    label: (
      <div>
        <Text>{role?.name}</Text>
        {role?.description && (
          <Text fontSize='sm' color='gray'>
            {role?.description}
          </Text>
        )}
      </div>
    ),
  }));

  useEffect(() => {
    if (isOpen) {
      fetchRoles();
      setSelectedRole(user?.currentRole);
    }
  }, [isOpen]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Role</DrawerHeader>

        <DrawerBody>
          <Box mb={4} display='flex' alignItems='center'>
            <Avatar name={user?.firstName + ' ' + user?.lastName} size='sm' />
            <Box ml='3'>
              <Text fontWeight='bold'>{user?.firstName + ' ' + user?.lastName}</Text>
              <Text color='gray' fontSize='sm'>
                {user?.email}
              </Text>
            </Box>
          </Box>

          <Text fontWeight='bold' mb={4}>
            Current Role: {user?.currentRole?.name}
          </Text>

          <FormControl>
            <Select
              options={roleOptions}
              onChange={handleRoleChange}
              value={roleOptions?.find((option) => option.value === selectedRole)}
              placeholder='Select new role'
            />
          </FormControl>
        </DrawerBody>

        <DrawerFooter>
          <Button variant='outline' mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleSaveRole}
            isLoading={isLoading}
            isDisabled={!selectedRole || selectedRole === user?.currentRole}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
