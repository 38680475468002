import React from 'react';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import Loader from 'components/Loader/Loader';

const LoadingErrorWrapper = ({ isLoading, errorTitle, error, children }) => {
  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return (
      <AlertMessage
        errorTitle={errorTitle}
        errorMessage={error?.response?.data?.error?.message ?? error?.message}
      />
    );
  }

  return children;
};

export default LoadingErrorWrapper;
