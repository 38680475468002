import React, { useContext, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { PostOnboardingContext } from 'modules/postonboarding/BankingPostOnboarding';
import CreateCorridorModal from 'components/CreateCorridors/CreateCorridorModal';

export default function AddCorridors() {
  const { merchantId, entityId, prevStep, nextStep } = useContext(PostOnboardingContext);

  const [isCreateCorridorModalOpen, setIsCreateCorridorModalOpen] = useState(false);

  const openCreateCorridorModal = () => {
    setIsCreateCorridorModalOpen(true);
  };

  const closeCreateCorridorModal = () => {
    setIsCreateCorridorModalOpen(false);
  };

  return (
    <>
      <Heading>Add Corridors</Heading>
      <Text color='gray' mb={4}>
        Add corridors for the accounts you just created.
      </Text>
      <Button onClick={openCreateCorridorModal}>Add Corridors</Button>
      <Flex gap={4} mt={8}>
        <Button leftIcon={<AiOutlineArrowLeft />} onClick={prevStep}>
          Add more accounts
        </Button>

        <Button colorScheme='blue' onClick={nextStep} rightIcon={<AiOutlineArrowRight />}>
          Next step
        </Button>
      </Flex>

      {isCreateCorridorModalOpen && (
        <CreateCorridorModal
          isOpen={isCreateCorridorModalOpen}
          onClose={closeCreateCorridorModal}
          merchantId={merchantId}
          isCreateCorridorDuringOnboarding={true}
          entityId={entityId}
        />
      )}
    </>
  );
}
