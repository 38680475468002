import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

function DepositInstructionsAlert({ isOpen, onClose, onConfirm, currencyCode }) {
  const cancelRef = React.useRef();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Deposit Instructions
          </AlertDialogHeader>

          <AlertDialogBody>Will the deposit instructions be present for this {currencyCode} account?</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => onConfirm(true)} size="sm">
              No
            </Button>
            <Button colorScheme='blue' onClick={() => onConfirm(false)} ml={3} size="sm">
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DepositInstructionsAlert;
