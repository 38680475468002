import { mode } from '@chakra-ui/theme-tools';

const fontFamilies = "'Outfit', sans-serif";

export const globalStyles = {
  colors: {
    gray: {
      700: '#1f2733',
    },
  },
  fonts: {
    heading: fontFamilies,
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.50', 'gray.800')(props),
        fontFamily: fontFamilies,
      },
      h1: {
        fontFamily: fontFamilies,
      },
      h2: {
        fontFamily: fontFamilies,
      },
      h3: {
        fontFamily: fontFamilies,
      },
      html: {
        fontFamily: fontFamilies,
      },
    }),
  },
};
