export const INITIATED_KYBS_TABLE_HEADINGS = [
  'MerchantId',
  'Entity ID',
  'Entity Name',
  'KYB ID',
  'Service',
  'Created At',
  '',
];
export const MERCHANT_ENTITY_KYB_HEADINGS = ['Merchant ID', 'Entity ID'];

export const ERROR_FETCHING_INITIATED_KYBS = 'Error fetching initiated KYBs.';
