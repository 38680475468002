export const SMB_TRANSACTION_STATUS_PENDING = 'pending';
export const SMB_TRANSACTION_TYPE_SINGLE_PAYOUT_OPS = 'single_payout_ops';

export const POLICY_EVALUATION_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
};

export const POLICY_EVALUATION_STATUS_TO_COLOR_MAPPING = {
  [POLICY_EVALUATION_STATUS.PENDING]: 'gray',
  [POLICY_EVALUATION_STATUS.APPROVED]: 'green',
  [POLICY_EVALUATION_STATUS.DECLINED]: 'red',
};
