import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

function RegionSelectionModal({ isOpen, handleRegionSelect }) {
  const [partnerCode, setPartnerCode] = useState(null);

  const handleSelectRegion = (code) => {
    setPartnerCode(code);
  };

  const handleConfirm = () => {
    handleRegionSelect(partnerCode);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setPartnerCode(null)} closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Region</ModalHeader>
        <ModalBody>
          <ButtonGroup>
            <Button
              onClick={() => handleSelectRegion('NIUM_USA')}
              colorScheme={partnerCode === 'NIUM_USA' ? 'blue' : 'gray'}
              size='sm'
            >
              United States (NIUM_USA)
            </Button>
            <Button
              onClick={() => handleSelectRegion('NIUM_SG')}
              colorScheme={partnerCode === 'NIUM_SG' ? 'blue' : 'gray'}
              size='sm'
            >
              Singapore (NIUM_SG)
            </Button>
          </ButtonGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleConfirm} isDisabled={!partnerCode} colorScheme='blue'>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RegionSelectionModal;
