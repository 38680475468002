import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import { getKybById, sendKybForReview } from 'api/internaltools';
import { cloneDeep } from 'lodash';
import ApplicantDocsUpload from 'modules/onboarding/components/nium/ApplicantDocsUpload';
import BusinessDocsUpload from 'modules/onboarding/components/nium/BusinessDocsUpload';
import NiumResponseModal from 'modules/onboarding/components/nium/NiumResponseModal';
import RegionSelectionModal from 'modules/onboarding/components/nium/RegionSelectionModal';
import StakeholdersDocsUpload from 'modules/onboarding/components/nium/StakeholdersDocsUpload';
import {
  DEFAULT_APPLICANT_DOCUMENTS,
  DEFAULT_BUSINESS_DOCUMENTS,
  DEFAULT_STAKEHOLDER_DOCUMENTS,
  MERCHANT_ENTITY_KYB_HEADINGS,
} from 'modules/onboarding/constants/constants';
import { convertFileToBase64Wrapper, findNumofStakeholders } from 'modules/onboarding/utils/utils';
import KybDetails from 'components/KybDetails/KybDetails';

function BankingOnboarding() {
  const location = useLocation();
  const toast = useToast();
  const { merchantId, entityId, kybId } = location.state || {};
  const headingsValues = [merchantId, entityId, kybId];

  const [kyb, setkyb] = useState(null);

  const [businessDocuments, setBusinessDocuments] = useState(cloneDeep(DEFAULT_BUSINESS_DOCUMENTS));

  const [applicantDocuments, setApplicantDocuments] = useState(cloneDeep(DEFAULT_APPLICANT_DOCUMENTS));

  const [stakeholderDocuments, setStakeholderDocuments] = useState(() => {
    const stakeholderDocuments = [];

    stakeholderDocuments.push(cloneDeep(DEFAULT_STAKEHOLDER_DOCUMENTS));

    return stakeholderDocuments;
  });

  const [isRegionModalOpen, setIsRegionModalOpen] = useState(true);
  const [partnerCode, setPartnerCode] = useState(null);
  const [niumResponse, setNiumResponse] = useState(null);
  const [isNiumModalOpen, setIsNiumModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showToast = (description, status) => {
    toast({
      description: description,
      variant: 'left-accent',
      status: status,
      position: 'top',
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getKybById(kybId);
        const kyb = response?.data;

        kyb['data']['num_of_stakeholder'] = findNumofStakeholders(kyb?.data?.details);
        setkyb(kyb);
      } catch (error) {
        showToast(`Error in fetching KYB. ${error}`, 'error');
      }
    })();
  }, []);

  const handleRegionSelect = (code) => {
    setPartnerCode(code);
    setIsRegionModalOpen(false);
  };

  const handleUploadAndReview = async () => {
    setIsLoading(true);

    const payload = {
      kybRegion: partnerCode,
      kyb,
      businessDocuments: {
        documentNumber: businessDocuments.documentNumber,
        documentType: businessDocuments.documentType,
        documentIssuanceCountry: businessDocuments.documentIssuanceCountry,
        documentExpiryDate: businessDocuments.documentExpiryDate,
        document: [],
      },
      applicantDocuments: {
        documentNumber: applicantDocuments.documentNumber,
        documentType: applicantDocuments.documentType,
        documentIssuanceCountry: applicantDocuments.documentIssuanceCountry,
        documentExpiryDate: applicantDocuments.documentExpiryDate,
        document: [],
      },
      stakeholderDocuments: [],
    };

    // Convert all documents to base64 encoding
    try {
      await convertFileToBase64Wrapper(payload.businessDocuments.document, businessDocuments.document);
      await convertFileToBase64Wrapper(payload.applicantDocuments.document, applicantDocuments.document);

      for (const stakeholderDocument of stakeholderDocuments) {
        const stakeholderDocumentPayload = {
          documentNumber: stakeholderDocument.documentNumber,
          documentType: stakeholderDocument.documentType,
          documentIssuanceCountry: stakeholderDocument.documentIssuanceCountry,
          documentExpiryDate: stakeholderDocument.documentExpiryDate,
          document: [],
        };

        await convertFileToBase64Wrapper(stakeholderDocumentPayload.document, stakeholderDocument.document);
        payload.stakeholderDocuments.push(stakeholderDocumentPayload);
      }
    } catch (error) {
      showToast(`Error in converting documents to base64 encoding. ${error}`, 'error');

      return;
    }

    try {
      const response = await sendKybForReview(payload);
      const responseData = response?.data;

      setNiumResponse(responseData?.data);
      setIsNiumModalOpen(true);
    } catch (error) {
      showToast(`Error during upload and review. ${error}`, 'error');
    }

    setIsLoading(false);
  };

  return (
    <Flex mt='30px' direction='column' gap={4}>
      <Heading as='h2'>Onboard on NIUM</Heading>
      <KybDetails headings={MERCHANT_ENTITY_KYB_HEADINGS} headingsValues={headingsValues} />

      <RegionSelectionModal isOpen={isRegionModalOpen} handleRegionSelect={handleRegionSelect} />

      {partnerCode && (
        <Tabs isFitted variant='enclosed-colored' isLazy>
          <TabList mt={6}>
            <Tab>Business Documents</Tab>
            <Tab>Applicant Documents</Tab>
            <Tab>Stakeholders Documents</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <BusinessDocsUpload businessDocuments={businessDocuments} setBusinessDocuments={setBusinessDocuments} />
            </TabPanel>
            <TabPanel>
              <ApplicantDocsUpload
                applicantDocuments={applicantDocuments}
                setApplicantDocuments={setApplicantDocuments}
              />
            </TabPanel>
            <TabPanel>
              <StakeholdersDocsUpload
                stakeholderDocuments={stakeholderDocuments}
                setStakeholderDocuments={setStakeholderDocuments}
                numOfStakeholder={kyb?.data?.num_of_stakeholder}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {partnerCode && (
        <Flex justify='center'>
          <Button colorScheme='blue' onClick={handleUploadAndReview} isLoading={isLoading} isDisabled={kyb == null}>
            Upload and Review
          </Button>
        </Flex>
      )}

      <NiumResponseModal
        isOpen={isNiumModalOpen}
        setIsNiumModalOpen={setIsNiumModalOpen}
        niumResponse={niumResponse}
        kybId={kybId}
        partnerCode={partnerCode}
      />
    </Flex>
  );
}

export default BankingOnboarding;
