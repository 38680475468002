import React, { useContext, useState } from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import { verifyParticipantOnAtomic } from 'api/internaltools';
import PatchParticipant from 'modules/onboarding/components/atomic/PatchParticipant';
import { ATOMIC_VERIFY_PARTICIPANT_DESCRIPTION } from 'modules/onboarding/constants/constants';
import { AtomicOnboardingContext } from 'modules/onboarding/TreasuryOnboarding';

const VerifyParticipant = () => {
  const { kybId, kyb, partnerCode, nextStep, showToast, showError } = useContext(AtomicOnboardingContext);

  const [isVerifyParticipantLoading, setIsVerifyParticipantLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleVerifyParticipant = async () => {
    setIsVerifyParticipantLoading(true);
    const payload = {
      kybId,
      partnerCode,
    };

    try {
      await verifyParticipantOnAtomic(payload);
      showToast('Successfully verified participant on Atomic.', '', 'success');
      nextStep();
    } catch (error) {
      showError('Error verifying participant on Atomic.', error);
      setError(error);
    } finally {
      setIsVerifyParticipantLoading(false);
    }
  };

  return (
    <>
      {!error && (
        <>
          <Heading as='h2' size='md'>
            Verify Participant
          </Heading>
          <Text color='gray' mt={2}>
            {ATOMIC_VERIFY_PARTICIPANT_DESCRIPTION}
          </Text>
          <Button
            colorScheme='blue'
            mt={4}
            onClick={handleVerifyParticipant}
            isLoading={isVerifyParticipantLoading}
            isDisabled={kyb == null}
          >
            Verify Participant
          </Button>
        </>
      )}

      {error && <PatchParticipant />}
    </>
  );
};

export default VerifyParticipant;
