import React, { useEffect,useState } from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { getUserMerchantRelation } from 'api/internaltools';
import { SMB } from 'constants/constants';
import { showError } from 'utils/notifications';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

export default function RequestTechSupport({ onRequestTechSupportBtnClick, service, email }) {
  const [userMerchantRelation, setUserMerchantRelation] = useState(null);

  const toast = useToast();

  const fetchUserMerchantRelationByEmail = async () => {
    try {
      let userEmail = encodeURIComponent(email);
      const response = await getUserMerchantRelation(userEmail);

      setUserMerchantRelation(response?.data?.data);
    } catch (error) {
      showError(toast, `${email}`, error);
    }
  };

  useEffect(() => {
    fetchUserMerchantRelationByEmail();
  }, []);

  return (
    <Card style={{ borderWidth: '2px' }}>
      <CardBody w='100%'>
        <Flex direction='column' w='100%' align='center' p={8}>
          <Box align='center' mb={4}>
            <Text fontSize='2xl' fontWeight='bold'>
              Request Tech Support
            </Text>
            <Text color='gray.400'>{service === SMB ? 'for Roma.' : 'for CashOps.'}</Text>
          </Box>
          <Button onClick={() => onRequestTechSupportBtnClick(service)}>Click here</Button>
          {userMerchantRelation && (
            <Box mt={8} align='center'>
              <Text>🔒 Tech Support Lock:</Text>
              <Flex>
                <Text color='gray.400'>{userMerchantRelation?.merchant_id} - </Text>
                <Text ml={1}>{userMerchantRelation?.merchant_name}</Text>
              </Flex>
            </Box>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
}
