import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route,Switch } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';
import { AuthenticationProvider } from 'AuthContext';
import { BASE_DASHBOARD, DASHBOARD_HOME,LOGIN, REGISTER } from 'constants/routes';
import DashboardLayout from 'layouts/Dashboard';
import Login from 'pages/login';
import Register from 'pages/register';
import theme from 'theme/theme';
import AuthRoute from 'components/hoc/AuthRoute';
import 'focus-visible/dist/focus-visible';
import 'index.css';

const GlobalStyles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false}>
    <Global styles={GlobalStyles} />
    <AuthenticationProvider>
      <BrowserRouter>
        <Switch>
          <AuthRoute path={REGISTER} component={Register} />
          <AuthRoute path={LOGIN} component={Login} />
          <Route path={BASE_DASHBOARD} component={DashboardLayout} />
          <Redirect from={`/`} to={DASHBOARD_HOME} />
        </Switch>
      </BrowserRouter>
    </AuthenticationProvider>
  </ChakraProvider>,
  document.getElementById('root')
);
