export const ENTITY_TABLE_HEADINGS = ['Entity ID', 'Entity Name', 'KYB ID', 'Service', 'Created At', ''];
export const MERCHANT_ENTITY_KYB_HEADINGS = ['Merchant ID', 'Entity ID', 'KYB ID'];

export const BUSINESS_DOCS_DETAILS = [
  'documentNumber',
  'documentType',
  'documentIssuanceCountry',
  'documentExpiryDate',
];

export const APPLICANT_DOCS_DETAILS = [
  'documentNumber',
  'documentType',
  'documentIssuanceCountry',
  'documentExpiryDate',
];

export const STAKEHOLDER_DOCS_DETAILS = [
  'documentNumber',
  'documentType',
  'documentIssuanceCountry',
  'documentExpiryDate',
];

export const DEFAULT_BUSINESS_DOCUMENTS = {
  documentNumber: '',
  documentType: '',
  documentIssuanceCountry: '',
  documentExpiryDate: '',
  document: [
    {
      fileName: '',
      fileType: '',
      document: null,
    },
  ],
};

export const DEFAULT_APPLICANT_DOCUMENTS = {
  documentNumber: '',
  documentType: '',
  documentIssuanceCountry: '',
  documentExpiryDate: '',
  document: [
    {
      fileName: '',
      fileType: '',
      document: null,
    },
  ],
};

export const DEFAULT_STAKEHOLDER_DOCUMENTS = {
  documentNumber: '',
  documentType: '',
  documentIssuanceCountry: '',
  documentExpiryDate: '',
  document: [
    {
      fileName: '',
      fileType: '',
      document: null,
    },
  ],
};

export const ATOMIC = 'ATOMIC';
export const SOURCE_OF_FUNDS_KEY = 'source_of_funds';
export const BUSINESS_TYPE_KEY = 'business_type';
export const INDUSTRY_TYPE_KEY = 'industry_type';
export const ATOMIC_CORPORATE_SOURCE_OF_FUNDS = [
  'alimony',
  'business_revenue',
  'savings',
  'salary',
  'gaming_proceeds',
  'gift',
  'inheritance',
  'investment_proceeds',
  'insurance_proceeds',
  'legal_settlement',
  'rental_income',
  'rollover',
  'sale_of_business',
  'sale_of_real_estate',
];
export const ATOMIC_CORPORATE_ENTITY_TYPES = [
  'c_corp',
  's_corp',
  'foreign_corp',
  'llc_c_corp',
  'llc_s_corp',
  'llc_partnership',
  'llc_single_member',
  'non_profit',
  'limited_partnership',
  'general_partnership',
  'trust_irrevocable',
  'trust_revocable',
];
export const DOCUMENT_TYPE_ARTICLE_OF_INCORPORATION = 'articles_of_incorporation';
export const ATOMIC_PARTICIPANT_INDUSTRY = [
  'ACCOUNTING',
  'ADVERTISING__MARKETING',
  'AEROSPACE__DEFENSE',
  'AGRICULTURE__FORESTRY',
  'AMUSEMENT_AND_RECREATION',
  'ANIMAL_SERVICES_AND_VETERINARY',
  'ARCHITECTURE__DESIGN',
  'ARTS__ANTIQUES',
  'ATHLETICS__FITNESS',
  'AUTOMOTIVE',
  'AVIATION',
  'CHILDCARE',
  'CLEANING__JANITORIAL__HOUSEKEEPING',
  'COMMUNICATIONS__TELECOMMUNICATIONS',
  'CONSTRUCTION__CARPENTRY__LANDSCAPING',
  'CONVENIENCE_STORE__LIQUOR_STORE__GAS_STATION',
  'CUSTOMER_SERVICE_AND_SUPPORT',
  'EDUCATION',
  'EMBASSY__CONSULATE',
  'ENERGY',
  'ENGINEERING',
  'FASHION__CLOTHING',
  'FINANCIAL_SERVICES',
  'FIREARMS_AND_EXPLOSIVES',
  'GAMING__CASINO__CARD_CLUB',
  'GOVERNMENT__PUBLIC_ADMINISTRATION',
  'GROCERY__SUPERMARKET',
  'HEALTHCARE__MEDICAL_SERVICES',
  'HOTEL__HOSPITALITY',
  'IMPORT__EXPORT',
  'INFORMATION_TECHNOLOGY',
  'INSURANCE',
  'JEWELRY_GEMS_AND_PRECIOUS_METALS',
  'LEGAL_SERVICES__PUBLIC_SAFETY',
  'LOGISTICS__SUPPLY_CHAIN',
  'MANUFACTURING',
  'MARITIME',
  'MEDIA__ENTERTAINMENT',
  'MINING_OIL_AND_GAS',
  'MONEY_SERVICES_BUSINESSES',
  'NON_PROFIT__NGO__CHARITY',
  'PARKING_AND_CAR_WASHES',
  'PAWN_SHOPS__BROKERS',
  'PERSONAL_CARE__HYGIENE',
  'PHARMACEUTICALS',
  'PRINTING__PUBLISHING',
  'PROFESSIONAL__CIVIC_ORGANIZATIONS',
  'REAL_ESTATE',
  'RELIGIOUS_ORGANIZATION',
  'REPAIR_SERVICES',
  'RESTAURANT__FOOD_SERVICE',
  'RETAIL_SALES__RETAIL_TRADE',
  'RETIREMENT',
  'SCIENCE_AND_BIOTECHNOLOGY',
  'SECURITY',
  'TRANSPORTATION',
  'TRAVEL',
  'UTILITIES',
  'WHOLESALE_SALES__TRADE',
  'BAR__NIGHTCLUB__ADULT_ENTERTAINMENT_CLUB',
  'INDUSTRY__NOT__APPLICABLE__UNEMPLOYED',
  'OTHER_INDUSTRY',
];

export const ATOMIC_VERIFY_CORPORATE_DESCRIPTION =
  "Verification indicates to Atomic that the corporate's information is complete.";
export const ATOMIC_PATCH_CORPORATE_DESCRIPTION = 'Please remove *custom_id* field before submitting the Patch.';
export const ATOMIC_VERIFY_PARTICIPANT_DESCRIPTION =
  "Verification indicates to Atomic that the participant's information is complete.";
export const ATOMIC_PATCH_PARTICIPANT_DESCRIPTION =
  'Please remove *account_id* and *corporate_id* fields before submitting the Patch.';
export const ATOMIC_CONFIRM_ACCOUNT_DESCRIPTION =
  'The final step of the process for onboarding a corporate entity is to confirm the account. This requires the corporate to be created and verified, all participants to be linked to the corporate and verified, and all agreements to be signed.';

export const ATOMIC_ONBOARDING_STEPS = {
  CREATE_CORPORATE: 0,
  UPLOAD_DOCUMENT: 1,
  VERIFY_CORPORATE: 2,
  CREATE_ACCOUNT: 3,
  CREATE_PARTICIPANT: 4,
  VERIFY_PARTICIPANT: 5,
  CREATE_CONSENT: 6,
  CONFIRM_ACCOUNT: 7,
};
