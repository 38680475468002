import React, { createContext, useContext, useEffect, useState } from 'react';
import { getInternalUser, getUserPermissions } from 'api/user';
import { PermissionMappingsV2 } from 'constants/permissions_v2';

const AuthContext = createContext();

export function AuthenticationProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserAuthStatusLoading, setIsUserAuthStatusLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState(new Map());

  const handleSetUserPermissions = (permissions) => {
    var userPermissionsMap = new Map();

    for (let i = 0; i < permissions?.length; i++) {
      if (permissions[i]?.name && PermissionMappingsV2[permissions[i]?.name]) {
        userPermissionsMap.set(PermissionMappingsV2[permissions[i]?.name], permissions[i]);
      }
    }

    setUserPermissions(userPermissionsMap);
  };

  const getInternalUserDetails = async () => {
    setIsUserAuthStatusLoading(true);
    try {
      const userResponse = await getInternalUser();

      const userPermissionsResponse = await getUserPermissions();

      setIsAuthenticated(true);
      setUser(userResponse?.data?.data);

      handleSetUserPermissions(userPermissionsResponse?.data?.data?.permissions);
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);
      setUserPermissions(new Map());
    } finally {
      setIsUserAuthStatusLoading(false);
    }
  };

  const updateUserAndFetchPermissions = async () => {
    await getInternalUserDetails();
  };

  useEffect(() => {
    getInternalUserDetails();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        userPermissions,
        isUserAuthStatusLoading,
        updateUserAndFetchPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthentication() {
  return useContext(AuthContext);
}
