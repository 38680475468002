import React, { useEffect, useState } from 'react';
import { AiOutlineCheck, AiOutlinePlus } from 'react-icons/ai';
import {
  Badge,
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { addAccountDetails, createAccountOnPartner } from 'api/internaltools';
import { PARTNER_CODE_FIREBLOCKS, PARTNER_CODE_LAYER2 } from 'constants/constants';
import { ACCOUNT_STATUS_ACTIVE, ACCOUNT_STATUS_INITIALIZED } from 'modules/accounts/constants/constants';
import { showError, showToast } from 'utils/notifications';
import AddAccountInNewVaultModal from 'components/AddAccountInNewVaultModal/AddAccountInNewVaultModal';
import DepositInstructionsAlert from 'components/DepositInstructionsAlert/DepositInstructionsAlert';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

function InitiatedAccountRow(props) {
  const toast = useToast();
  const {
    accountId,
    merchantId,
    entityId,
    nickName,
    currencyCode,
    currencyType,
    partnerCode,
    createdAt,
    partnerId,
    metadata,
  } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const [isAccountCreationPending, setIsAccountCreationPending] = useState(false);
  const [isAccountCreationSuccess, setIsAccountCreationSuccess] = useState(false);
  const [isCheckAccountStatusPopupOpen, setIsCheckAccountStatusPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountStatusLoading, setIsAccountStatusLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAddAccountInNewVaultModalOpen, setIsAddAccountInNewVaultModalOpen] = useState(false);

  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const truncatedMerchantId = merchantId?.length > 15 ? `${merchantId?.slice(0, 10)}...` : merchantId;
  const truncatedEntityId = entityId?.length > 15 ? `${entityId?.slice(0, 10)}...` : entityId;
  const formattedCreatedAt = new Date(createdAt).toLocaleString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const badgeColors = {
    LAYER2: 'purple',
    FIREBLOCKS: 'blue',
  };

  const badgeColor = badgeColors[partnerCode] || 'gray';

  const handleOpenAddAccountInNewVaultModal = () => {
    setIsAddAccountInNewVaultModalOpen(true);
  };

  const handleCreateAccountonPartner = async (
    currencyCode,
    payinMarkup = null,
    isCreateAccountInNewVault = false,
    isCreateWithoutDepositInstructions = false
  ) => {
    setIsLoading(true);

    const payload = {
      account_id: accountId,
    };

    if (payinMarkup) {
      payload.payin_markup = payinMarkup;
    }

    if (partnerCode === PARTNER_CODE_FIREBLOCKS) {
      payload.is_create_account_in_new_vault = isCreateAccountInNewVault;
    }

    if (isCreateWithoutDepositInstructions) {
      payload.is_create_without_deposit_instructions = isCreateWithoutDepositInstructions;
    }

    try {
      const response = await createAccountOnPartner(payload);

      if (
        response?.data?.data?.status === ACCOUNT_STATUS_INITIALIZED ||
        response?.data?.data?.is_deposit_instructions_pending
      ) {
        showToast(toast, 'Pending', `Account currently pending activation on ${partnerCode}.`, 'info');
        setIsAccountCreationPending(true);
      } else {
        showToast(toast, 'Success', `Successfully created account on ${partnerCode}.`, 'success');
        setIsAccountCreationSuccess(true);
      }
    } catch (error) {
      showError(toast, `Error creating account on ${partnerCode}.`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckAccountStatus = async () => {
    if (isAccountCreationSuccess) {
      return;
    }

    setIsAccountStatusLoading(true);

    try {
      const response = await addAccountDetails({
        account_id: accountId,
      });

      if (response?.data?.data?.status === ACCOUNT_STATUS_ACTIVE) {
        setIsAccountCreationSuccess(true);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsAccountStatusLoading(false);
    }
  };

  const handleCreateAccountButtonClick = () => {
    if (partnerCode === PARTNER_CODE_FIREBLOCKS) {
      handleOpenAddAccountInNewVaultModal();
    } else if (partnerCode === PARTNER_CODE_LAYER2 && currencyCode === 'USD') {
      setIsAlertDialogOpen(true);
    } else {
      handleCreateAccountonPartner(currencyCode, null, false, false);
    }
  };

  const handleAlertDialogConfirm = (isDepositInstEmpty) => {
    setIsAlertDialogOpen(false);
    handleCreateAccountonPartner(currencyCode, null, false, isDepositInstEmpty);
  };

  useEffect(() => {
    if (metadata?.trim().startsWith('{')) {
      try {
        const statusObj = JSON.parse(metadata);

        if (statusObj?.accountStatus === ACCOUNT_STATUS_INITIALIZED) {
          setIsAccountCreationPending(true);
        }
      } catch (error) {
        // Handle error
      }
    }

    if (partnerCode == PARTNER_CODE_LAYER2 && partnerId) {
      setIsAccountCreationPending(true);
    }
  }, [metadata]);

  useEffect(() => {
    setError(null);
    if (isCheckAccountStatusPopupOpen) {
      handleCheckAccountStatus();
    }
  }, [isCheckAccountStatusPopupOpen]);

  const isCreateAccountBtnDisabled = isAccountCreationPending || isAccountCreationSuccess;

  return (
    <>
      <Tr>
        <Td pl='0px'>
          <IconButton
            icon={isAccountCreationSuccess ? <AiOutlineCheck /> : <AiOutlinePlus />}
            color={isAccountCreationSuccess ? 'white' : undefined}
            bg={isAccountCreationSuccess ? 'green.500' : undefined}
            isRound={true}
            isLoading={isLoading}
            onClick={handleCreateAccountButtonClick}
            isDisabled={isCreateAccountBtnDisabled}
          />

          {isAccountCreationPending && (
            <Popover
              isOpen={isCheckAccountStatusPopupOpen}
              onOpen={() => setIsCheckAccountStatusPopupOpen(true)}
              onClose={() => setIsCheckAccountStatusPopupOpen(false)}
            >
              <PopoverTrigger>
                <Button mt={2} colorScheme='blue' variant='link' size='xs'>
                  Check Status
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <LoadingErrorWrapper isLoading={isAccountStatusLoading} errorTitle='' error={error}>
                  <PopoverContent p={2}>
                    <Text>
                      Status: {''}
                      <Text as='span' fontWeight='bold' color={isAccountCreationSuccess ? 'green' : 'red'}>
                        {isAccountCreationSuccess ? 'Active' : 'Pending activation'}
                      </Text>
                    </Text>
                  </PopoverContent>
                </LoadingErrorWrapper>
              </PopoverContent>
            </Popover>
          )}
        </Td>

        <Td>
          <Tooltip label={merchantId} bg='gray.600'>
            <Text fontSize='sm' color={textColor} isTruncated>
              {truncatedMerchantId}
            </Text>
          </Tooltip>
        </Td>

        <Td>
          <Tooltip label={entityId} bg='gray.600'>
            <Text fontSize='sm' color={textColor} isTruncated>
              {truncatedEntityId}
            </Text>
          </Tooltip>
        </Td>

        <Td>
          <Text fontSize='sm' color={textColor}>
            {nickName}
          </Text>
        </Td>

        <Td>
          <Text fontSize='sm' fontWeight='bold' color={textColor}>
            {currencyCode}
          </Text>
        </Td>

        <Td>
          <Text fontSize='sm' fontWeight='bold' color={textColor}>
            {currencyType}
          </Text>
        </Td>

        <Td>
          <Badge colorScheme={badgeColor} rounded='0.8em' px={3}>
            {partnerCode?.toUpperCase()}
          </Badge>
        </Td>

        <Td>
          <Text fontSize='sm' color={textColor}>
            {formattedCreatedAt}
          </Text>
        </Td>
      </Tr>

      {isAddAccountInNewVaultModalOpen && (
        <AddAccountInNewVaultModal
          currencyCode={currencyCode}
          onClose={() => setIsAddAccountInNewVaultModalOpen(false)}
          onClick={handleCreateAccountonPartner}
        />
      )}

      {isAlertDialogOpen && (
        <DepositInstructionsAlert
          isOpen={isAlertDialogOpen}
          onClose={() => setIsAlertDialogOpen(false)}
          onConfirm={handleAlertDialogConfirm}
          currencyCode={currencyCode}
        />
      )}
    </>
  );
}

export default InitiatedAccountRow;
